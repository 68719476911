import { FC } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Logo from "../../assets/images/logo.png";
import InstagramLogo from "../../assets/images/instagramIcon.png";
import facebook from "../../assets/images/facebookIcon.png";
import twitter from "../../assets/images/twitterIcon.png";
import ROUTES from "../../constant/routes";
import "./Footer.scss";

const Footer: FC = () => {
  const { t: translation } = useTranslation();
  return (
    // <footer className="footer fixed-bottom" >
    <footer className="footer" >
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="logo">
              <img src={Logo} alt="logo" />
            </div>
          </div>

          <div className="col-md-4">
            <div className="footer-links">
              <h5>{translation("links")}</h5>
              <ul className="footer-list">
                <li>
                  <Link to={ROUTES.PRIVACY_POLICY} >
                    {translation("privacy_policy")}
                  </Link>
                </li>

                <li>
                  <Link to={ROUTES.TERM_CONDITION} >{translation("terms_and_condition")}</Link>
                </li>

                <li>
                  <Link to={ROUTES.CONTACT_US}>{translation("contact_us")}</Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-md-4">
            <div className="footer-links">
              <h5>{translation("social_links")}</h5>
              <ul className="social-list-footer">
                <li>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.instagram.com/Rentersbk"
                  >
                    <img src={InstagramLogo} alt="logo" />
                  </a>
                </li>

                <li>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://twitter.com/rentersbk"
                  >
                    <img src={twitter} alt="logo" />
                  </a>
                </li>

                <li>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.facebook.com/profile.php?id=100077748420983"
                  >
                    <img src={facebook} alt="logo" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="footer-sub">
          <p>
            Ⓒ Copyright &nbsp;<span>Made with ❤️ by Biz4Group</span>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
