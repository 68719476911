import React, { FC, lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import About from "../containers/about/About";
import PrivacyPolicy from "../containers/privacyPolicy/PrivacyPolicy";
import ResetPassword from "../containers/userAuth/ResetPassword";
import Verification from "../containers/userAuth/Verification";
import PageNotFound from "../containers/pageNotFound/PageNotFound";
import ProtectedRoutes from "./ProtectedRoutes";
import { RootState } from "../redux/reducers/Index";
import { IAuthData } from "../interfaces/commonInterfaces";
import AddProperty from "../containers/addProperty/addProperty";
import SubscriptionDetails from "../containers/subscription/SubscriptionDetails";
import Checkout from "../containers/checkout/Checkout";
import ROUTES from "../constant/routes";
import TermsAndConditions from "../containers/termCondition/TermCondition";

// landing page routing ------------
const LandingPage = lazy(() => import("../containers/landingPage/LandingPage"));
// auth page routing -----------
const Login = lazy(() => import("../containers/userAuth/Login"));
const Signup = lazy(() => import("../containers/userAuth/Signup"));

// single page routing -----------
const ForgotPassword = lazy(
  () => import("../containers/userAuth/ForgotPassword")
);

// single page routing -----------
const AddLandlord = lazy(() => import("../containers/addLandlord/AddLandlord"));
const AddTenant = lazy(() => import("../containers/addTenant/AddTenant"));
const FindReview = lazy(() => import("../containers/Rating/FindReview"));
const RateLandlord = lazy(() => import("../containers/Rating/RateLandlord"));
const RateTenant = lazy(() => import("../containers/Rating/RateTenant"));
const ContactUs = lazy(() => import("../containers/contactUs/ContactUs"));
const Profile = lazy(() => import("../containers/profile/Profile"));

interface Props {
  component: React.ComponentType;
  // path?: string;
}

export const WithHeaderProtected: React.FC<Props> = ({ component }) => {
  return (
    <>
      <Header />
      <div className="main-box">
        <ProtectedRoutes component={component} />
        <Footer />
      </div>
    </>
  );
};

export const WithHeaderFooterNonProtected: React.FC<Props> = ({
  component: RouteComponent,
}) => {
  return (
    <>
      <Header />
      <div className="main-box">
        <RouteComponent />
        <Footer />
      </div>
    </>
  );
};

export const WithHeaderNonProtected: React.FC<Props> = ({
  component: RouteComponent,
}) => {
  return (
    <>
      <Header />
      <div className="main-box">
        <RouteComponent />
      </div>
    </>
  );
};

const PublicRoutes: FC = () => {
  const authData: IAuthData = useSelector(
    (state: RootState) => state?.AuthReducer?.authData
  );

  return (
    <div className="main-box">
      <Suspense fallback={<p>Loading....</p>}>
        <Routes>
          <Route
            path={ROUTES.HOME}
            element={<WithHeaderNonProtected component={LandingPage} />}
          />
          <Route
            path={ROUTES.ABOUT}
            element={<WithHeaderFooterNonProtected component={About} />}
          />
          <Route
            path={ROUTES.RATE_LANDLORD}
            element={<WithHeaderProtected component={RateLandlord} />}
          />
          <Route
            path={ROUTES.RATE_TENANT}
            element={<WithHeaderProtected component={RateTenant} />}
          />
          <Route
            path={ROUTES.ADD_TENANT}
            element={<WithHeaderProtected component={AddTenant} />}
          />
          <Route
            path={ROUTES.ADD_LANDLORD}
            element={<WithHeaderProtected component={AddLandlord} />}
          />
          <Route
            path={ROUTES.FIND_REVIEW}
            element={<WithHeaderProtected component={FindReview} />}
          />
          <Route
            path={ROUTES.PRIVACY_POLICY}
            element={<WithHeaderFooterNonProtected component={PrivacyPolicy} />}
          />
          <Route
            path={ROUTES.TERM_CONDITION}
            element={<WithHeaderFooterNonProtected component={TermsAndConditions} />}
          />
          <Route
            path={ROUTES.CONTACT_US}
            element={<WithHeaderFooterNonProtected component={ContactUs} />}
          />
          <Route
            path={ROUTES.PROFILE}
            element={<WithHeaderProtected component={Profile} />}
          />
          <Route
            path={ROUTES.SUSBCRIPTION_DETAILS}
            element={<WithHeaderProtected component={SubscriptionDetails} />}
          />
          <Route
            path={ROUTES.CHECKOUT}
            element={<WithHeaderProtected component={Checkout} />}
          />

          <Route
            path={ROUTES.FORGOT_PASSWORD}
            element={
              authData?.id ? <Navigate to={ROUTES.HOME} /> : <ForgotPassword />
            }
          />

          <Route
            path={ROUTES.SIGN_UP}
            element={authData?.id ? <Navigate to={ROUTES.HOME} /> : <Signup />}
          />

          <Route
            path={ROUTES.LOGIN}
            element={authData?.id ? <Navigate to={ROUTES.HOME} /> : <Login />}
          />

          <Route
            path={ROUTES.RESET_PASSWORD}
            element={
              authData?.id ? <Navigate to={ROUTES.HOME} /> : <ResetPassword />
            }
          />

          <Route
            path={ROUTES.VERIFY}
            element={
              authData?.id ? <Navigate to={ROUTES.HOME} /> : <Verification />
            }
          />

          <Route
            path={ROUTES.ADD_PROPERTY}
            element={<WithHeaderProtected component={AddProperty} />}
          />

          <Route path={ROUTES.PAGE_NOT_FOUND} element={<PageNotFound />} />
        </Routes>
      </Suspense>
    </div>
  );
};

export default PublicRoutes;
