import { http } from "../utils/http";
import endpoint from "../constant/endpoints";
import ApiResponse from "../resources/entity/IApiResponse";
// import ILocation from "../utils/locationScema";
import { IAddress } from "../interfaces/authInterface";

/**
 * Fetching google place detail
 * @returns axios service
 */
export const getPredictions = (searchText: string): Promise<ApiResponse> =>
  http.get(`${endpoint.google.SEARCH_PREDICTION}/${searchText}`);

/**
 * Fetching google place detail
 * @returns axios service
 */
export const getPlacesDetail = (placeId: string): Promise<ApiResponse> =>
  http.get(`${endpoint.google.PLACES_DETAIL}/${placeId}`);

/**
 * Fetching location name detail
 * @returns axios service
 */
export const getLocationName = async (): // location: ILocation
Promise<IAddress> => {
  const response = await http.get(`${endpoint.google.PLACE_DETAILS}`);

  let address = {};
  if (response.data && response.data?.success) {
    address = { ...response.data.data };
  }
  return address;
};
