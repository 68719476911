import moment from "moment";

export const ALPHANUMERIC_REGEX = /^[a-z\d\-_\s]+$/i;
export const ALPHABATE_WITH_SPACE_REGEX = /^[a-zA-Z ]*$/;
export const PHONE_REGEX = /\b\d{10}\b/g;
export const PASSWORD_REGEX =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,16}$/;
export const NAME_REGEX = /^[a-z ,.'-]+$/i;
// export const NAME_REGEX = /^[ a-zA-Z0-9\-’]+$/;
export const NUMBER_REGEX = /^[0-9]*$/;
export const NUMBER_REGEX_WITH_SPACE = /^[0-9 ]*$/;
export const NEW_LINE_REGEX = /\r?\n/g;
export const ALPHABATE_REGEX = /\b[A-Za-z]/g;
export const CITY_REGEX = /^(?=.*[a-zA-Z ])( )([a-zA-Z0-9]+)$/;
export const DATE_FORMAT = "MM-DD-YYYY";
export const TIME_FORMAT = "hh:mm A";
export const ADDRESS_REGEX = /^[a-zA-Z0-9\s,._'"/\\-]*$/;
export const EMAIL_REGEX =
  /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-]+)(\.[a-zA-Z]{2,5}){1,2}$/; // eslint-disable-line no-useless-escape

export const SPECIAL_CHAR_REGEX=/[^a-zA-Z0-9 ]/g;
export const NUM_REGEX=/[0-9]/g;
export const currentTimeStamp = Number(moment(new Date().getTime()));
export const ACCOUNT_TYPE = [
  { label: "Landlord", value: "0" },
  { label: "Tenant", value: "1" },
  // { label: "Both", value: "2" },
];

export const LIVING_STATUS = [
  { label: "Former ", value: "former" },
  { label: "Current", value: "current" },
];

export const PROPERTY_TYPE = [
  // { key: "", value: "Select Property Type" },
  { key: "house", value: "House" },
  { key: "apartment", value: "Apartment" },
  { key: "condo", value: "Condo" },
  { key: "commercial_property", value: "Commercial Property" },
];

export const Review = [
  { key: "excellent", value: "Excellent" },
  { key: "very_good", value: "Very good" },
  { key: "good", value: "Good" },
  { key: "fair", value: "Fair" },
  { key: "poor", value: "Poor" },
];

export const PROPERTY_RATING = [
  { key: "security_and_safety", value: "Security & Safety" },
  { key: "cleanliness", value: "Cleanliness" },
  { key: "lighting", value: "Lighting" },
  { key: "heating_and_cooling", value: "Heating & Cooling" },
  { key: "snow_removal", value: "Snow removal" },
  { key: "pets", value: "Pests" },
  {
    key: "quality_of_amenities",
    value: "Quality of amenities? (Laundry room, gym, etc.)",
  },
];

export const Slug = [
  { key: "select", value: "" },
  { key: "address", value: "address" },
  { key: "landlord", value: "landlord" },
  { key: "tenant", value: "Tenant" },
];

export const LANDLORD_RATING = [
  { key: "management", value: "Management" },
  { key: "maintenance_staff", value: "Maintenance staff" },
  { key: "timely_repairs", value: "Timely repairs" },
  // { key: "rent", value: "Rent" },
  {
    key: "recomend_to_other",
    value: "Would you recommend to a family or friend?",
  },
  { key: "increasing_rent", value: " Frequent or excessive rent increase" },
  {
    key: "incepection_of_property",
    value: "Conducted frequent or scheduled inspections of property",
  },
  { key: "value_for_money", value: " Value for money" },
  { key: "live_again", value: "Would you live here again?" },
];

export const TENANT_RATING = [
  { key: "paid_rent_on_time", value: "Pays / Paid rent on time" },
  { key: "noise", value: "Excessive noise" },
  { key: "cleanliness", value: "Cleanliness" },
  {
    key: "followed_rules_and_regulations",
    value: "Followed rules and regulations",
  },
  {
    key: "issues_with_children",
    value: "Issues with children",
  },
  { key: "issue_with_pets", value: "Issues with pets" },
  {
    key: "utility_use",
    value: "Utility use",
  },
  {
    key: "would_you_rent_to_this_tenant_again",
    value: "Would you rent to this tenant again?",
  },
  {
    key: "problems_with_tenant_exiting_unit",
    value: "Problems with tenant exiting unit",
  },
  {
    key: "destruction_to_property",
    value: "Destruction to property",
  },
  {
    key: "frequent_or_permanent_guests",
    value: "Frequent or permanent guests",
  },
  {
    key: "will_you_give_a_favorable_reference",
    value: "Will you give a favorable reference",
  },
];
export const FILTERBY = [
  { key: "address", value: "Address" },
  { key: "landlord", value: "Landlord" },
  { key: "tenant", value: "Tenant" },
];


export const videoType = "video";
export const imageType = "image";
export const ImageSize = 5000000;

export const HomePageVideo =
  "https://renters-door.s3.amazonaws.com/landing-video.mp4";

export const limitedReviewCount = 1;
export const ExistProperty = "property_already_exist";
export const PROFILE_PAGE = "profilePage";
export const ADD_PROPERTY = "addProperty";
export const ADD_TENANT = "addTenant";
export const SUBSDETAILS = "subscriptionDetails";
export const ADD_LANDLORD = "addLandlord";
export const SHOW_PLAN_MODAL = "showPlanModal";
export const LOADING_MODAL = "loadingModal";
export const LANDING_PAGE_MODAL = "landingPageModal";
export const REPORTED_REVIEW = "reportReview";
export const FIND_REVIEW = "findReview";
export const RATE_LANDLORD = "rateLandLord";
export const RATE_TENANT = "rateTenant";
export const UserBlockByAdmin = "user_blocked_by_admin";
export const TokenExpired = "token-expired";
export const FindReviewTermCondtion = "findReviewTermCondtion";
export const FIND_REVIEW_LOADING_MODAL="findReviewLoadingModal";

export const oneTimePlan = "one_time";
export const oneMonthPlan = "one_month";
export const oneYearPlan = "one_year";
export const oneMonthRecurrence = "month";
export const oneYearRecurrence = "year";
// export const oneDayRecurrence = "day";
export const oneDayRecurrence = "oneTime";
export const planPopupType = "planPopupType";

// export const PAYMENT_METHODS={
//   PAYPAL:"paypal",
//   STRIPE:"stripe"
// }


export const PAYMENT_METHODS={
  PAYPAL:"paypal",
  STRIPE:"credit_card"
}

export const SUBSCRIPTION_STATUS = {
  ACTIVE: "active",
  CANCELED: "canceled",
  EXPIRED: "expired",
  DELETED: "deleted",
  INCOMPLETED_EXPIRED: "incomplete_expired",
};

export const USER_TYPE={
  LANDLORD:"landlord",
  TENANT:"tenant",
};
export const CUSTOM_REVIEW_STATUS = {
  NO_REVIEW: "0",
  HAVE_REVIEW: "1",
  APPROVED_REVIEW: "2",
  REJECTED_REVIEW: "3",
};

export const REVIEW_SEARCH_BY = {
  LANDLORD: "landlord",
  TENANT: "tenant",
  ADDRESS: "address",
};

export const ACTIVE_INACTIVE_STATUS={
  ACTIVE: "1",
  INACTIVE: "0",
}


export const RATING_CATEGORY={
  [USER_TYPE.LANDLORD]:LANDLORD_RATING,
  [USER_TYPE.TENANT]:LANDLORD_RATING,
  "address":PROPERTY_RATING,
}