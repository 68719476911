import React, {
  ChangeEvent,
  InputHTMLAttributes,
  MouseEventHandler,
} from "react";
import {
  Controller,
  Control,
  FieldError,
  Merge,
  FieldErrorsImpl,
} from "react-hook-form";
import CommonErrorText from "./CommonErrorText";

interface IProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  control?: Control;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  onClick?: MouseEventHandler | undefined;
  placeHolder?: string;
  rows?: number;
  cols?: number;
  label?: string;
  name: string;
  error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
  maxLength?: number;
}

const CommonTextArea: React.FC<IProps> = (props) => {
  const {
    className,
    placeHolder,
    name,
    control,
    error,
    value,
    disabled = false,
    readOnly = false,
    rows = 4,
    cols,
    label,
    onChange,
    onClick,
    maxLength,
  } = props;

  return (
    <div className="form-group">
      <label htmlFor="floatingInput">{label}</label>
      <Controller
        render={({ field }) => (
          <textarea
            {...field}
            className={`form-control ${className}`}
            placeholder={placeHolder}
            rows={rows}
            cols={cols}
            disabled={disabled}
            readOnly={readOnly}
            maxLength={maxLength}
            onClick={onClick}
            onChange={(e) => {
              field.onChange(e);
              if (onChange) {
                onChange(e);
              }
            }}
          />
        )}
        defaultValue={value}
        name={name}
        control={control}
      />

      <CommonErrorText errors={error} />
    </div>
  );
};

export default React.memo(CommonTextArea);
