import { IUser } from "../../interfaces/commonInterfaces";
import ActionType from "../../resources/enums/index";
import storage from "../../utils/storage";

export interface IRootState {
  isRegistered: string;
  isLoggedIn: boolean;
  authData?: IUser;
  // isUpdated: boolean;
}

const initialState: IRootState = {
  isRegistered: "",
  isLoggedIn: false,
  authData: storage.get("__SFA__")
    ? JSON.parse(storage.get("__SFA__") as string)
    : {},
  // isUpdated: false,
};

const AuthReducer = (state = initialState, action?: any) => {
  switch (action.type) {
    case ActionType.LOGIN: {
      return {
        ...state,
        isLoggedIn: action?.payload?.success,
        authData: { ...state.authData, ...action?.payload },
      };
    }
    case ActionType.UPDATEAUTHDATA: {
      return {
        ...state,
        isLoggedIn: action?.payload?.success,
        updatedAuthData: { ...state.authData, ...action?.payload },
      };
    }
    case ActionType.FORGOTPASSWORD:
      return {
        ...state,
        isUpdated: action.payload.data.isUpdated || true,
        authData: action.payload.data,
      };

    case ActionType.CHANGE_PASSWORD:
      return {
        ...state,
        isUpdated: action.payload.data.isUpdated,
      };
    case ActionType.SOCIAL_lOGIN:
      return {
        ...state,
      };
    case ActionType.LOGOUT:
      localStorage.clear();
      return {
        ...state,
        isRegistered: "",
        isLoggedIn: false,
        authData: {},
        isUpdated: false,
      };

    default:
      return state;
  }
};

export default AuthReducer;
