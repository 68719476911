import { FC, SetStateAction, useState, FormEvent, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ToastContainer, toast } from "react-toastify";
import OtpInput from "react-otp-input";
import { IOtpProps, IVerifyOtpParams } from "../../interfaces/authInterface";
import { getUserService, verifyOtpService } from "../../service/auth";
import toastMesage from "../../components/commonFormGroup/CommonToastMessage";
import CommonSVGFiles from "../../components/commonFormGroup/CommonSVGFiles";
import ROUTES from "../../constant/routes";
import AuthBanner from "../../assets/images/auth-bg.png";

const Verification: FC = () => {
  const { t: translation } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [enterOtp, setEnterOtp] = useState("");
  const [otpError, setOtpError] = useState("");
  const [userData,setUserData]=useState<IVerifyOtpParams>({} as IVerifyOtpParams);

  const navigate = useNavigate();
  const location = useLocation();
  const params=useParams();
  const otpInfo = location.state as IOtpProps;



  const OnSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (!enterOtp || enterOtp.length < 6) {
        setOtpError(translation("otp_error"));
        return;
      }
      setLoading(true);
      if(otpInfo && otpInfo.encOtp && otpInfo.email){
        userData.encOtp = otpInfo.encOtp
        userData.email = otpInfo.email
      };

      const {data} = await verifyOtpService({...userData,otp:enterOtp});
      toast.dismiss();
      if (data && data.success) {
        toastMesage(translation(data?.message),"success");
        setTimeout(() => {
            if(otpInfo && otpInfo.pathName && otpInfo.pathName===ROUTES.FORGOT_PASSWORD){
              navigate(ROUTES.RESET_PASSWORD, {
                state: { email: userData?.email},
              });
            }else{
              navigate(ROUTES.LOGIN);
            }
          }, 2000);
      } else {
        toastMesage(translation(data?.message),"error");
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };
  
  const getUser=(id:string)=>{
    getUserService(id).then(({data})=>{
      if(data && data.success && data.data){
        setUserData(data.data);
      }
    });
  };

  useEffect(()=>{
    if(params  && params.id ){
      if(otpInfo && otpInfo.pathName===ROUTES.LOGIN||
        otpInfo && otpInfo.pathName===ROUTES.FORGOT_PASSWORD){
          getUser(window.atob(params.id));
        }
    }
  },[]);

  return (
    <div className="container-fluid p-0">
      <div className="row m-0">
        <div className="col-md-6 p-0">
          <div className="userauth-form">
            <h5 className="text-center mb-5 ">
              {translation("verification_code_has_been_sent_to")}{" "}
              {params && params.id && (
                <span className="text-primary"> ({`${window.atob(params.id)}`}) </span>
              )}

              {translation("please_Check")}
            </h5>
            <h4 className="text-center my-3">{translation("verify_otp")}</h4>
            <form
              onSubmit={(e) => {
                OnSubmit(e);
              }}
            >
              <div className="form-group">
                <OtpInput
                  className="form-control otp-input"
                  isInputNum
                  value={enterOtp}
                  onChange={(otp: SetStateAction<string>) => {
                    setOtpError("");
                    setEnterOtp(otp);
                  }}
                  numInputs={6}
                />
                <div className="auth-msg error text-center">
                  <p>{otpError} </p>
                </div>
              </div>

              <div className="text-center mt-5 ">
                <button
                  className="main-button mainprimary-btn border-radius mx-4"
                  type="button"
                  onClick={() => navigate(-1)}
                >
                  {translation("back")}
                </button>
                <button
                  className="main-button mainprimary-btn border-radius"
                  type="submit"
                >
                  {translation("submit")}
                  {loading ? (
                    <span className="spinner-border spinner-border-sm me-2 ms-2" />
                  ) : (
                    <CommonSVGFiles svgName="arrowForward" />
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="col-md-6 p-0">
          <img src={AuthBanner} alt="banner" className="banner-auth" />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Verification;
