import { Action, AnyAction, Dispatch } from "redux";
import { IUser } from "../../interfaces/commonInterfaces";
import ActionType from "../../resources/enums";
import Storage from "../../utils/storage";

export const loginAction =
  (data: IUser) => async (dispatch: Dispatch<AnyAction>) => {
    dispatch({
      type: ActionType.LOGIN,
      payload: data,
    });
  };

export const updateAuth =
  (data: IUser) => async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_AUTH,
      payload: data,
    });
  };

export const logout = () => async (dispatch: Dispatch<Action>) => {
  Storage.removeAll();
  dispatch({
    type: ActionType.LOGOUT,
  });
};
