import * as http from "../utils/http";
import endpoint from "../constant/endpoints";
import ApiResponse from "../resources/entity/IApiResponse";


export const  createPaypalSingleCharge = (data:unknown): Promise<ApiResponse> => 
     http.post(endpoint.paypal.SINGLE_CHARGE, data);



export const  createPaypalSusbcription = (data:unknown): Promise<ApiResponse> => 
     http.post(endpoint.paypal.CREATE_SUSBCRIPTION, data);



export const  cancelPaypalSusbcription = (data:unknown): Promise<ApiResponse> => 
   http.post(endpoint.paypal.CANCEL_SUSBCRIPTION, data);


export const  getCustomeReviewAmount = (): Promise<ApiResponse> => 
   http.get(endpoint.paypal.GET_CUSTOME_REVIEW_PLAN);


export const  getPaypalInvoice = (subsId:string): Promise<ApiResponse> => 
   http.get(endpoint.paypal.GET_PAYPAL_INVOICE.replace(":id",subsId));




