import { FC, useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast, ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import moment from "moment";
import { RootState } from "../../redux/reducers/Index";

import {
  ADD_PROPERTY,
  currentTimeStamp,
  ExistProperty,
  PROPERTY_TYPE,
} from "../../constant/commonConstant";
import { addPropertyValidation } from "../../validationSchema/addLandlordAndTenant";

import {
  IAddLandlordAndTenant,
  IAuthData,
  IExistProperty,
  IGoogleAddress,
  ILocation,
  ILocationPredictions,
  IUser,
} from "../../interfaces/commonInterfaces";

import { addUserProperty, getVisitedService } from "../../service/user";
import { getPlacesDetail, getPredictions } from "../../service/google";
import toastMesage from "../../components/commonFormGroup/CommonToastMessage";

import CommonSVGFiles from "../../components/commonFormGroup/CommonSVGFiles";
import CommonSelect from "../../components/commonFormGroup/CommonSelect";
import CommonInput from "../../components/commonFormGroup/CommonInput";
import CommonPopup from "../../components/commonPopup/CommonPopup";
import ROUTES from "../../constant/routes";

const AddProperty: FC = () => {
  const navigate = useNavigate();
  const { t: translation } = useTranslation();
  const location: ILocation = useLocation();
  const [loading, setLoading] = useState(false);
  const [showLocationPrediction, setShowLocationPrediction] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [locationPredictions, setLocationPredictions] = useState([]);
  const [addressData, setAddressData] = useState<IGoogleAddress>();
  const [currentUserData, setCurrentUserData] = useState<IUser>();
  const [existProperty, setExistProperty] = useState<IExistProperty>();

  const authData: IAuthData = useSelector(
    (state: RootState) => state?.AuthReducer?.authData
  );

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(addPropertyValidation(translation)),
  });

  const onSubmit = async (data: IAddLandlordAndTenant) => {
    try {
      setLoading(true);
      data.userId = location.state.id;
      data.address_id = addressData?.addressId;
      data.address =
        getValues("apartmentNumber")?.trim().length > 0
          ? `${getValues(`address`)}${" #"}${getValues(`apartmentNumber`)}`
          : getValues("address");

      toast.dismiss();
      const response = await addUserProperty(data);
      // console.log("response1234", response?.data?.data);
      if (response && response?.data && response?.data?.success) {
        toastMesage(translation(response?.data?.message),"success");
        reset({ address: "", propertyType: "", apartmentNumber: "" });
        setTimeout(() => {
          navigate(ROUTES.RATE_LANDLORD, {
            state: {
              addPropertyData: response?.data?.data,
              landlordId: location?.state?.id,
              landlordName: location?.state?.landlordName,
            },
          });
        }, 2000);
      }
      setLoading(false);
      if (response && response?.data && !response?.data?.success) {
        if (response?.data?.message === ExistProperty) {
          setExistProperty(response.data.data);
          setShowModal(true);
        }
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const addressPredictionChnage = debounce(async (event) => {
    try {
      setShowLocationPrediction(true);
      setLocationPredictions([]);
      const response = await getPredictions(event.target.value);
      if (response.data && response?.data?.data?.predictions) {
        setLocationPredictions(response.data.data.predictions);
      }
    } catch (error) {
      console.log("Error", Error);
    }
  }, 500);

  const handlePlaceSelection = async (placeId: string) => {
    const response = await getPlacesDetail(placeId);
    if (response.data && response.data.success) {
      setValue("address", response.data.data.addressLine1);
      setShowLocationPrediction(false);
      response.data.data.addressId = placeId;
      setAddressData(response.data.data);
    }
  };
  const getCurrentVisitedCount =  () => {
      if (authData.id) {
        getVisitedService(authData.id).then(({data})=>{
          if (data &&data.success && data.data) {
            setCurrentUserData(data.data);
          }
        });
     }
  };

  useEffect(() => {
    if (location?.state?.propertyType) {
      setValue(
        "propertyType",
        `${location?.state?.propertyType ? location?.state?.propertyType : ""}`
      );
    }
    getCurrentVisitedCount();
  }, []);

  if (currentUserData && currentUserData.subscription_id === null) {
    return <Navigate to={ROUTES.SUSBCRIPTION_DETAILS} />;
  }
  if (
    currentUserData &&
    currentUserData.end_at &&
    currentUserData.subscription_id !== null &&
    moment.unix(currentUserData.end_at).isBefore(currentTimeStamp)
  ) {
    return <Navigate to={ROUTES.SUSBCRIPTION_DETAILS} />;
  }

  return (
    <div className="container">
      <form
        className="add-form"
        onSubmit={handleSubmit((data) =>
          onSubmit(data as IAddLandlordAndTenant)
        )}
      >
        <h4>{translation("add_property")}</h4>
        <div className="row">
          <div className="col-md-3">
            <div className="form-group">
              <CommonInput
                control={control}
                label={translation("apt_suite_unit")}
                name="apartmentNumber"
                type="text"
                className="form-control"
                placeHolder={translation("apt_suite_unit")}
                // error={errors.address}
              />
            </div>
          </div>

          <div className="col-md-9">
            <div className="list-relative">
              <CommonInput
                control={control}
                label={translation("address*")}
                name="address"
                type="text"
                className="form-control"
                placeHolder={translation("address")}
                error={errors.address}
                onChange={(event) => addressPredictionChnage(event)}
              />
              <div className="common-list">
                {showLocationPrediction && locationPredictions?.length ? (
                  <ul>
                    {locationPredictions?.length
                      ? locationPredictions.map(
                          (value: ILocationPredictions) => (
                            <li
                              className="cursorPointer"
                              key={value?.place_id}
                              onClick={() =>
                                handlePlaceSelection(value?.place_id)
                              }
                            >
                              {value.description}
                            </li>
                          )
                        )
                      : null}
                  </ul>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="form-group">
              <CommonSelect
                control={control}
                firstOption={translation("select")}
                className="form-group"
                label={translation("select_property_type*")}
                disabled={location?.state?.propertyType ? true : false}
                option={PROPERTY_TYPE}
                errors={errors.propertyType}
                name="propertyType"
              />
            </div>
          </div>
        </div>
        <div className="text-center my-4">
          <button
            className="main-button mainprimary-btn"
            type="submit"
            disabled={loading}
          >
            {translation("submit")}{" "}
            {loading ? (
              <span className="spinner-border spinner-border-sm me-2 ms-2" />
            ) : (
              <CommonSVGFiles svgName="arrowForward" />
            )}
          </button>
        </div>
      </form>
      <ToastContainer />
      {showModal && (
        <CommonPopup
          from={ADD_PROPERTY}
          headerMessage={translation("this_property_is_already_registered")}
          existPropertyData={existProperty}
          clickedButton={() => {
            setShowModal(false);
          }}
        />
      )}
    </div>
  );
};
export default AddProperty;
