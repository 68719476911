import React, {
  ChangeEvent,
  KeyboardEvent,
  LegacyRef,
  MouseEventHandler,
  useState,
} from "react";
import { Control, Controller, FieldErrors, FieldValues } from "react-hook-form";
import CommonErrorText from "./CommonErrorText";

import VisibleIcon from "../../assets/images/visibility.png";
import HideIcon from "../../assets/images/hide.png";

interface IProps {
  label?: string;
  type: string;
  className?: string;
  placeHolder?: string;
  name: string;
  value?: string | number;
  control: Control;
  error?: FieldErrors<FieldValues>;
  disabled?: boolean;
  readOnly?: boolean;
  required?: boolean;
  maxLength?: number;
  ref?: LegacyRef<HTMLInputElement>;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  onClick?: MouseEventHandler | undefined;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  hasIcon?: boolean;
  iconClass?: string;
  bottomLabel?: string;
  min?: number;
  max?: number;
  from?: string;
}

const CommonInput: React.FC<IProps> = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  const {
    label,
    type,
    className = "form-control",
    placeHolder,
    name,
    control,
    error,
    disabled = false,
    readOnly = false,
    onClick,
    hasIcon,
    onChange,
    value,
    from,
    // min,
    // max,
    // required,
    // onKeyDown,
    // maxLength,
    // ref,
  } = props;

  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      {hasIcon ? (
        <div className="icon-input">
          <Controller
            render={({ field }) => (
              <input
                {...field}
                // type={type}
                type={showPassword ? "text" : "password"}
                disabled={disabled}
                readOnly={readOnly}
                className={className}
                placeholder={placeHolder}
                onClick={onClick}
                value={value}
                autoComplete="off"
              />
            )}
            name={name}
            control={control}
          />
          {showPassword ? (
            <button
              className="icon-btn"
              type="button"
              onClick={() => setShowPassword(!showPassword)}
            >
              <img src={VisibleIcon} alt="icon" />
            </button>
          ) : (
            <button
              type="button"
              className="icon-btn"
              onClick={() => setShowPassword(!showPassword)}
            >
              <img src={HideIcon} alt="icon" />
            </button>
          )}
        </div>
      ) : from === "profile" ? (
        <Controller
          render={({ field }) => (
            <input
              {...field}
              type={type}
              disabled={disabled}
              readOnly={readOnly}
              className={className}
              placeholder={placeHolder}
              onClick={onClick}
              autoComplete="off"
              value={value}
            />
          )}
          name={name}
          control={control}
          defaultValue={value || ""}
        />
      ) : (
        <Controller
          render={({ field }) => (
            <input
              {...field}
              type={type}
              disabled={disabled}
              readOnly={readOnly}
              className={className}
              placeholder={placeHolder}
              onClick={onClick}
              autoComplete="off"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                field.onChange(e);
                if (onChange) {
                  onChange(e);
                }
              }}
            />
          )}
          name={name}
          control={control}
        />
      )}

      <CommonErrorText errors={error} />
    </div>
  );
};

export default CommonInput;
