import React from "react";
import { useTranslation } from "react-i18next";
import { ICommonPopup } from "../../interfaces/commonInterfaces";
import StripeLogo from "../../assets/images/stripe-logo.png";
import PaypalLogo from "../../assets/images/paypal.svg";

const PaymentMethod: React.FC<Pick<ICommonPopup, "getMethod">> = ({
  getMethod,
}) => {
  const { t: translation } = useTranslation();
  return (
    <div className="payment-method-choose">
      <div>
        <button
          type="button"
          className="main-button mainprimary-btn "
          onClick={() => getMethod && getMethod("paypal")}
        >
          {translation("paypal")}
        </button>
        <img src={PaypalLogo} alt="PayPal Logo" />
      </div>

      <div>
        <button
          type="button"
          className="main-button mainprimary-btn"
          onClick={() => getMethod && getMethod("stripe")}
        >
          {translation("credit_card")}
        </button>
        <img src={StripeLogo} alt="stripe_logo" />
      </div>
    </div>
  );
};

export default PaymentMethod;
