import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import {
  createCustomReviewPayment,
  createSubscription,
} from "../../service/susbcription";
import { RootState } from "../../redux/reducers/Index";
import {
  ADD_LANDLORD,
  ADD_PROPERTY,
  ADD_TENANT,
  LOADING_MODAL,
  PROFILE_PAGE,
  RATE_LANDLORD,
  RATE_TENANT,
  SUBSDETAILS,
} from "../../constant/commonConstant";
import toastMesage from "../../components/commonFormGroup/CommonToastMessage";
import { ICustomReviewLocationData, IPlanList } from "../../interfaces/commonInterfaces";
import CommonPopup from "../../components/commonPopup/CommonPopup";
import ROUTES from "../../constant/routes";
import { getCustomReviewPlan } from "../../service/user";

const CheckoutForm: React.FC = () => {
  const { t: translation } = useTranslation();
  const location = useLocation().state as ICustomReviewLocationData;
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [customReviewData,setCustomReviewData]=useState<IPlanList[]>([]);

  const [errorMsg, setErrorMsg] = useState({
    cardNumber: "",
    expiryDate: "",
    cvcNumber: "",
  });
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  const authData = useSelector(
    (state: RootState) => state?.AuthReducer?.authData
  );

  const onSubmit = async (
    e: React.BaseSyntheticEvent<object, unknown, unknown> | undefined
  ) => {
    // setCardError("");
    setErrorMsg({
      cardNumber: "",
      cvcNumber: "",
      expiryDate: "",
    });
    e?.preventDefault();
    try {
      if (elements && stripe) {
        setLoading(true);
        setShowModal(true);
        const cardElement = elements.getElement(CardNumberElement);
        const expiryElement = elements.getElement(CardExpiryElement);
        const cvcElement = elements.getElement(CardCvcElement);
        if (cardElement) {
          const token = await stripe.createToken(cardElement);
          if (token && token.error && token.error.message) {
            switch (token.error.code) {
              case "incomplete_number":
                setErrorMsg({
                  cvcNumber: "",
                  expiryDate: "",
                  cardNumber: token.error.message,
                });
                break;
              case "invalid_number":
                setErrorMsg({
                  cvcNumber: "",
                  expiryDate: "",
                  cardNumber: token.error.message,
                });
                break;
              case "incomplete_expiry":
                setErrorMsg({
                  cardNumber: "",
                  cvcNumber: "",
                  expiryDate: token.error.message,
                });
                break;
              case "invalid_expiry_year_past":
                setErrorMsg({
                  cardNumber: "",
                  cvcNumber: "",
                  expiryDate: token.error.message,
                });
                break;
              case "incomplete_cvc":
                setErrorMsg({
                  cardNumber: "",
                  expiryDate: "",
                  cvcNumber: token.error.message,
                });
                break;
              default:
                break;
            }
          }
          const paymentMethod = await stripe.createPaymentMethod({
            type: "card",
            card: cardElement,
            billing_details: {
              email: authData.email,
              name: authData.full_name,
            },
          });
          if (paymentMethod && !paymentMethod.error) {
            setErrorMsg({
              cardNumber: "",
              cvcNumber: "",
              expiryDate: "",
            });
            const payload = {
              id: authData.id,
              paymentMethodId: paymentMethod.paymentMethod.id,
              planName: location.plan,
            };
            cardElement?.clear();
            expiryElement?.clear();
            cvcElement?.clear();

            let response;
            switch (location.rateData?.prevPath) {
              case RATE_TENANT:
                response = await createCustomReviewPayment(
                  payload,
                  location.rateData
                );
                if (response && response.data && response.data.success) {
                  toastMesage(translation(response?.data?.message),"success");
                  setTimeout(() => {
                    navigate(ROUTES.HOME);
                  }, 2000);
                } else {
                  toastMesage(translation(response?.data?.message));
                  setTimeout(() => {
                    navigate(ROUTES.RATE_TENANT);
                  }, 2000);
                }
                break;
              case RATE_LANDLORD:
                response = await createCustomReviewPayment(
                  payload,
                  location.rateData
                );
                if (response && response.data && response.data.success) {
                  toastMesage(translation(response?.data?.message),"success");
                  setTimeout(() => {
                    navigate(ROUTES.HOME);
                  }, 2000);
                } else {
                  toastMesage(translation(response?.data?.message));
                  setTimeout(() => {
                    navigate(ROUTES.RATE_LANDLORD);
                  }, 2000);
                }
                break;
              default:
                response = await createSubscription(payload);
                if (response && response.data && response.data.success) {
                  toastMesage(translation(response?.data?.message),"success");
                  setTimeout(() => {
                    switch (location.prevData.prevPath) {
                      case RATE_LANDLORD:
                        navigate(ROUTES.RATE_LANDLORD, {
                          state: {
                            prevData: location.prevData,
                          },
                        });
                        break;
                      case RATE_TENANT:
                        navigate(ROUTES.RATE_TENANT, {
                          state: {
                            prevData: location.prevData,
                          },
                        });
                        break;
                      case PROFILE_PAGE:
                        navigate(ROUTES.PROFILE);
                        break;
                      case ADD_PROPERTY:
                        navigate(ROUTES.ADD_PROPERTY);
                        break;
                      case ADD_TENANT:
                        navigate(ROUTES.ADD_TENANT);
                        break;
                      case ADD_LANDLORD:
                        navigate(ROUTES.ADD_LANDLORD);
                        break;
                      case SUBSDETAILS:
                        navigate(ROUTES.SUSBCRIPTION_DETAILS);
                        break;
                      default:
                        break;
                    }
                  }, 2000);
                } else {
                  cardElement?.clear();
                  expiryElement?.clear();
                  cvcElement?.clear();
                  toastMesage(translation(response?.data?.message));
                }
                break;
            }
          }else{
            toastMesage(`${paymentMethod?.error?.message}`);
          }
        }
      } else return;
      setLoading(false);
      setShowModal(false);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
    setShowModal(false);
  };

  const gettingCustomeReviewPlan=()=>{
    getCustomReviewPlan().then(({data})=>{
      if (data &&  data.success && data.data) {
        setCustomReviewData(data.data);
      }
    });
  };

 useEffect(()=>{
if(location && location.prevData 
  &&location.prevData.prevPath===RATE_LANDLORD||
  location && location.prevData && 
  location.prevData.prevPath===RATE_TENANT){
  gettingCustomeReviewPlan();
};
},[]);


  return (
    <div className="checkout-form-page">
      <div className="container">
        <div className="row">
          <div className="col-md-5 offset-md-3">
            <div className="checkout-card">
              <div className="card-top">
                <h2> {translation("checkout_form")} </h2>
              </div>
              <div className="card-mid">
                <form onSubmit={(e) => onSubmit(e)}>
                  <div className="mb-3">
                    <label htmlFor="CardNumberElement" />
                    {translation("card_number*")}
                    <CardNumberElement className="form-control" />
                  </div>

                  {errorMsg && errorMsg.cardNumber!=="" && (
                    <p className="text-danger">{errorMsg.cardNumber}</p>
                  )}

                  <div className="mb-3">
                    <label htmlFor="CardExpiryElement" />
                    {translation("expiry_date*")}
                    <CardExpiryElement className="form-control" />
                  </div>
                  {errorMsg && errorMsg.expiryDate!=="" && (
                    <p className="text-danger">{errorMsg.expiryDate}</p>
                  )}

                  <div className="mb-3">
                    <label htmlFor="CardCvcElement" />
                    <h6> {translation("cvc_number*")}</h6>
                    <CardCvcElement className="form-control" />
                  </div>

                  {errorMsg && errorMsg.cvcNumber!=="" && (
                    <p className="text-danger">{errorMsg.cvcNumber}</p>
                  )}
                  <div className="action-btn">
                    <button
                      className="main-button button-radius col-8 "
                      type="submit"
                      disabled={!stripe || !elements}
                    >
                      {location && location.plan &&location.amount && 
                      `${translation("pay_now")} ${location.amount} $`
                      }

                      {customReviewData &&customReviewData.length>0 &&
                      `${translation("pay_now")} ${customReviewData[0].amount} $`
                      }  

                      {loading && (
                        <span className="spinner-border spinner-border-sm me-2 ms-2" />
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      {showModal && loading && (
        <CommonPopup
          from={LOADING_MODAL}
          clickedButton={() => setShowModal(false)}
          yesLoader={loading}
        />
      )}
 
    </div>
  );
};

export default CheckoutForm;
