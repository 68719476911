export const ROUTES = {
  HOME: "/",
  SIGN_UP: "/signup",
  LOGIN: "/login",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  VERIFY: "/otp-verification/:id",
  ABOUT: "/about",
  TERM_CONDITION: "/term-conditions",
  CONTACT_US: "/contact-us",
  FIND_REVIEW: "/find-review",
  RATE_LANDLORD: "/rate-landlord",
  RATE_TENANT: "/rate-tenant",
  ADD_LANDLORD: "/add-landlord",
  ADD_TENANT: "/add-tenant",
  PRIVACY_POLICY: "/privacy-policy",
  PROFILE: "/user-profile",
  ADD_PROPERTY: "/add-property",
  CHECKOUT: "/checkout/:method",
  SUSBCRIPTION_DETAILS: "/subscription-details",
  PAGE_NOT_FOUND: "*",
};

export const DONT_SHOW_AFTER_LOGIN_ROUTES = [
  ROUTES.LOGIN,
  ROUTES.FORGOT_PASSWORD,
  ROUTES.VERIFY,
  ROUTES.RESET_PASSWORD,
  ROUTES.SIGN_UP,
];

export const USER_ROUTES = [
  ROUTES.ABOUT,
  ROUTES.CONTACT_US,
  ROUTES.FIND_REVIEW,
  ROUTES.RATE_LANDLORD,
  ROUTES.ADD_LANDLORD,
];

export const SHOW_AFTER_LOGIN_ROUTES = [
  ROUTES.ABOUT,
  ROUTES.CONTACT_US,
  ROUTES.FIND_REVIEW,
  ROUTES.RATE_LANDLORD,
  ROUTES.ADD_LANDLORD,
  ROUTES.ADD_PROPERTY,
  ROUTES.PROFILE,
  ROUTES.ADD_TENANT,
];

export const COMMON_ROUTES = [
  { path: ROUTES.HOME },
  { path: ROUTES.PRIVACY_POLICY },
  // { path: ROUTES.TERMS_CONDITION },
];

export const LANDLORD_ROUTES = [
  { path: ROUTES.ADD_LANDLORD },
  { path: ROUTES.RATE_TENANT },
];

export const TENANT_ROUTES = [
  { path: ROUTES.ADD_TENANT },
  { path: ROUTES.RATE_LANDLORD },
];

export default ROUTES;
