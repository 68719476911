import * as yup from "yup";
import { TFunction } from "react-i18next";

import {
  ALPHABATE_WITH_SPACE_REGEX,
  NAME_REGEX,
  NUMBER_REGEX,
  NUMBER_REGEX_WITH_SPACE,
  PASSWORD_REGEX,
  ALPHANUMERIC_REGEX,
  ADDRESS_REGEX,
  EMAIL_REGEX,
} from "../constant/commonConstant";

/**
 * Handle validation for email field
 * @param translate i18n translate hook instance
 * @returns
 */
export const email = (translate: TFunction) =>
  yup
    .string()
    .trim()
    .required(
      translate("required_field_error", { field_name: translate("email") })
    )
    .email(translate("invalid_field_error", { field_name: translate("email") }))
    .matches(
      EMAIL_REGEX,
      translate("invalid_field_error", { field_name: translate("email") })
    );

/**
 * Handle validation for email field
 * @param translate i18n translate hook instance
 * @returns
 */
export const optionalEmail = (translate: TFunction) =>
  yup
    .string()
    .trim()
    // .required(
    //   translate("required_field_error", { field_name: translate("email") })
    // )
    .email(
      translate("invalid_field_error", { field_name: translate("email") })
    );

/**
 * Common validation schema
 * @param translate i18n translate hook instance
 * @returns
 */
export const zip = (translate: TFunction) =>
  yup
    .string()
    .trim()
    .required(
      translate("required_field_error", {
        field_name: translate("zip"),
      })
    )
    .matches(
      NUMBER_REGEX,
      translate("invalid_field_error", {
        field_name: translate("zip"),
      })
    )
    .min(5, translate("min_length", { length: 5, length_type: "digits" }))
    .max(5, translate("max_length", { length: 5, length_type: "digits" }))
    .test(
      "len",
      translate("invalid_field_error", {
        field_name: translate("zip"),
      }),
      (val?: string) => (val ? val.length === 5 : false)
    );

export const name = (translate: TFunction) =>
  yup
    .string()
    .trim()
    .required(
      translate("required_field_error", { field_name: translate("name") })
    )
    .matches(NAME_REGEX, translate("contains_only_alphanumeric"))
    .max(25, translate("max_len_25"));

/**
 * Validate any string field for only alphabates
 * @param fieldName field name
 */
export const alphabates = (translate: TFunction, fieldName: string) =>
  yup
    .string()
    .trim()
    .required(
      translate("required_field_error", { field_name: translate(fieldName) })
    )
    .matches(
      ALPHABATE_WITH_SPACE_REGEX,
      translate("only_contain", {
        field_name: translate(fieldName),
        type: translate("alphabates"),
      })
    );

/**
 * Validate any string field for only alphaNumeric values
 * @param fieldName field name
 */
export const alphaNumeric = (translate: TFunction, fieldName: string) =>
  yup
    .string()
    .required(
      translate("required_field_error", { field_name: translate(fieldName) })
    )
    .matches(
      ALPHANUMERIC_REGEX,
      translate("invalid_field_error", {
        field_name: translate(fieldName),
      })
    );

/**
 * Handle validation for city field
 * @param translate i18n translate hook instance
 * @returns
 */
export const city = (translate: TFunction) =>
  yup
    .string()
    .required(
      translate("required_field_error", { field_name: translate("city") })
    )
    .test(
      "isNumberSpace",
      translate("contains_only_alphanumeric"),
      (value) => !NUMBER_REGEX_WITH_SPACE.test(value as string)
    )
    .max(25, translate("max_len_25"));

/**
 * Handle validation for state field
 * @param translate i18n translate hook instance
 * @returns
 */
export const state = (translate: TFunction) =>
  yup
    .string()
    .required(
      translate("required_field_error", { field_name: translate("state") })
    )
    .matches(ALPHABATE_WITH_SPACE_REGEX, translate("contains_only_alphabets"))
    .max(50, translate("max_len_50"));

/**
 * Handle validation for password field
 * @param translate i18n translate hook instance
 * @returns
 */
export const password = (translate: TFunction) =>
  yup
    .string()
    .required(
      translate("required_field_error", {
        field_name: translate("password"),
      })
    )
    .matches(PASSWORD_REGEX, translate("pass_reg_ms"));

export const oldPassword = (translate: TFunction) =>
  yup.string().required(
    translate("required_field_error", {
      field_name: translate("password"),
    })
  );
// .matches(PASSWORD_REGEX, translate("pass_reg_ms"));

/**
 * Handle validation for terms and conditions field
 * @param translate i18n translate hook instance
 * @returns
 */
export const termsConditions = (translate: TFunction) =>
  yup
    .boolean()
    .default(false)
    .oneOf(
      [true],
      translate("required_field_error", {
        field_name: translate("terms_condition"),
      })
    );
/**
 * Handle validation for address field
 * @param translate i18n translate hook instance
 * @returns
 */
export const addressLine1 = (translate: TFunction) =>
  yup
    .string()
    .trim()
    .required(
      translate("required_field_error", {
        field_name: translate("address_line", { no: 1 }),
      })
    );
// .matches(
//   ADDRESS_REGEX,
//   `${translate("contains_only_alphanumeric")} ${translate(
//     "including"
//     // eslint-disable-next-line no-useless-escape
//   )} , . _ ' - / \\ " `
// );

/**
 * Handle validation for address field
 * @param translate i18n translate hook instance
 * @returns
 */
export const addressLine2 = (translate: TFunction) =>
  yup
    .string()
    .trim()
    .matches(
      ADDRESS_REGEX,
      `${translate("contains_only_alphanumeric")} ${translate(
        "including"
        // eslint-disable-next-line no-useless-escape
      )} , . _ ' - / \\ " `
    )
    .max(50, translate("max_len_50"));

/**
 * Validate any string field for only numeric values
 * @param fieldName field name
 */
export const numeric = (translate: TFunction, fieldName: string) =>
  yup
    .string()
    .trim()
    .required(
      translate("required_field_error", { field_name: translate(fieldName) })
    )
    .matches(
      NUMBER_REGEX,
      translate("invalid_field_error", {
        field_name: translate(fieldName),
      })
    );

/**
 * Validate any string field for only non empty alphaNumeric values
 * @param fieldName field name
 */
export const nonEmptyAlphaNumeric = (translate: TFunction, fieldName: string) =>
  yup
    .string()
    .trim()
    .matches(
      ALPHANUMERIC_REGEX,
      translate("invalid_field_error", {
        field_name: translate(fieldName),
      })
    );

export const propertyType = (translate: TFunction, fieldName: string) =>
  yup.string().required(
    translate("required_field_error", {
      field_name: translate(fieldName),
    })
  );

export const livingStatus = (translate: TFunction, fieldName: string) =>
  yup.string().required(
    translate("required_field_error", {
      field_name: translate(fieldName),
    })
  );

/**
 * Validate any string field for empty
 * @param fieldName
 */
export const emptyField = (translate: TFunction, fieldName: string) =>
  yup
    .string()
    .trim()
    .required(
      translate("required_field_error", { field_name: translate(fieldName) })
    );

/**
 * Handle validation for phone field
 * @param translate i18n translate hook instance
 * @returns
 */
export const phone = (translate: TFunction) =>
  yup
    .string()
    .required(translate("phone_req"))
    .min(10, translate("invalid_phone"))
    .max(10, translate("invalid_phone"))
    .matches(NUMBER_REGEX, translate("invalid_phone"));

/**
 * Handle validation for phone field
 * @param translate i18n translate hook instance
 * @returns
 */
export const ssn = (translate: TFunction) =>
  yup
    .string()
    .required(translate("ssn_req"))
    .min(9, translate("invalid_ssn"))
    .max(9, translate("invalid_ssn"))
    .matches(NUMBER_REGEX, translate("invalid_ssn"));

export const firstName = (translate: TFunction) =>
  yup
    .string()
    .trim()
    .required(
      translate("required_field_error", {
        field_name: translate("first_name"),
      })
    )
    .matches(
      NAME_REGEX,
      translate("contains_only_alphanumeric", {
        field_name: translate("first_name"),
      })
    )
    .max(50, translate("max_len_50"));

export const lastName = (translate: TFunction) =>
  yup
    .string()
    .trim()
    .required(
      translate("required_field_error", {
        field_name: translate("last_name"),
      })
    )
    .matches(
      NAME_REGEX,
      translate("contains_only_alphanumeric", {
        field_name: translate("last_name"),
      })
    )
    .max(50, translate("max_len_50"));

export const fullName = (translate: TFunction) =>
  yup
    .string()
    .trim()
    .required(
      translate("required_field_error", {
        field_name: translate("full_name"),
      })
    )
    .matches(
      NAME_REGEX,
      translate("contains_only_alphanumeric", {
        field_name: translate("full_name"),
      })
    )
    .max(25, translate("max_len_25"));

export const accountType = (translate: TFunction) =>
  yup
    .string()
    .trim()
    .required(
      translate("required_field_error", {
        field_name: translate("account_type"),
      })
    );

export const review = (translate: TFunction) =>
  yup
    .string()
    .trim()
    .required(
      translate("required_field_error", {
        field_name: translate("first_name"),
      })
    );

export const reviewRating = (translate: TFunction) =>
  yup.string().required(translate("reviews_are_required"));

export const comment = (translate: TFunction) =>
  yup
    .string()
    .trim()
    .required(translate("comment_is_required"))
    .max(100, translate("max_len_100"));
