import React from "react";
import { useTranslation } from "react-i18next";

interface IProps {
  svgName: string;
}

const CommonSVGFiles: React.FC<IProps> = ({ svgName }) => {
  const { t: translation } = useTranslation();
  switch (svgName) {
    case "arrowForward":
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <title>{translation("arrow_forward")}</title>
          <path
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="48"
            d="M268 112l144 144-144 144M392 256H100"
          />
        </svg>
      );
    case "cross":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="33.182"
          height="33.182"
          viewBox="0 0 33.182 33.182"
        >
          <path
            id="Path_21415"
            data-name="Path 21415"
            d="M19.322,6.529a15.89,15.89,0,0,0,6.4-1.306A17,17,0,0,0,31.02,1.628a16.906,16.906,0,0,0,3.586-5.285,15.961,15.961,0,0,0,1.3-6.412,15.89,15.89,0,0,0-1.306-6.4A17.1,17.1,0,0,0,31-21.776a16.688,16.688,0,0,0-5.294-3.586,16.071,16.071,0,0,0-6.4-1.289,16.01,16.01,0,0,0-6.4,1.289,16.816,16.816,0,0,0-5.294,3.586,16.909,16.909,0,0,0-3.595,5.3,15.98,15.98,0,0,0-1.3,6.4A15.871,15.871,0,0,0,4.03-3.657,17.027,17.027,0,0,0,7.624,1.628,17.027,17.027,0,0,0,12.91,5.223,15.871,15.871,0,0,0,19.322,6.529Zm0-3.04a13.293,13.293,0,0,1-5.277-1.05A13.608,13.608,0,0,1,9.725-.472a13.49,13.49,0,0,1-2.9-4.32A13.387,13.387,0,0,1,5.78-10.07a13.387,13.387,0,0,1,1.042-5.277,13.49,13.49,0,0,1,2.9-4.32,13.516,13.516,0,0,1,4.312-2.9A13.325,13.325,0,0,1,19.3-23.612,13.449,13.449,0,0,1,24.59-22.57a13.465,13.465,0,0,1,4.329,2.9,13.49,13.49,0,0,1,2.9,4.32,13.387,13.387,0,0,1,1.042,5.277,13.387,13.387,0,0,1-1.042,5.277,13.49,13.49,0,0,1-2.9,4.32A13.608,13.608,0,0,1,24.6,2.439,13.293,13.293,0,0,1,19.322,3.49ZM13.909-3.256a1.255,1.255,0,0,0,.99-.427L19.3-8.089l4.44,4.406a1.323,1.323,0,0,0,.99.427,1.33,1.33,0,0,0,.973-.41,1.33,1.33,0,0,0,.41-.973,1.359,1.359,0,0,0-.427-.99l-4.423-4.423,4.44-4.457a1.233,1.233,0,0,0,.427-.939,1.314,1.314,0,0,0-.41-.99,1.359,1.359,0,0,0-.973-.393,1.367,1.367,0,0,0-.973.41L19.3-11.982l-4.44-4.423a1.24,1.24,0,0,0-.956-.41,1.35,1.35,0,0,0-.982.393,1.307,1.307,0,0,0-.4.973,1.251,1.251,0,0,0,.427.956l4.423,4.44-4.423,4.44a1.269,1.269,0,0,0-.427.973,1.344,1.344,0,0,0,.4.973A1.321,1.321,0,0,0,13.909-3.256Z"
            transform="translate(-2.723 26.652)"
          />
        </svg>
      );
    case "uploadFile":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="57.39"
          height="41.759"
          viewBox="0 0 57.39 41.759"
        >
          <path
            id="Path_21650"
            data-name="Path 21650"
            d="M44.843,108.409H32.53V95.967H36.6a1.273,1.273,0,0,0,1.032-2.017L29.715,83a1.261,1.261,0,0,0-2.052,0L19.748,93.95a1.27,1.27,0,0,0,1.032,2.017h4.069v12.442H11.058C4.9,108.069,0,102.311,0,96.072A11.682,11.682,0,0,1,5.793,85.987a7.832,7.832,0,0,1-.481-2.732A7.942,7.942,0,0,1,13.263,75.3a7.815,7.815,0,0,1,2.721.481,15.917,15.917,0,0,1,30.243,5.254,13.847,13.847,0,0,1-1.384,27.37Z"
            transform="translate(0 -66.65)"
            fill="#0050b3"
          />
        </svg>
      );

    case "playIcon":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          version="1.1"
          id="Capa_1"
          x="0px"
          y="0px"
          viewBox="0 0 512 512"
          xmlSpace="preserve"
        >
          <path d="M256,0C114.617,0,0,114.615,0,256s114.617,256,256,256s256-114.615,256-256S397.383,0,256,0z M344.48,269.57l-128,80  c-2.59,1.617-5.535,2.43-8.48,2.43c-2.668,0-5.34-0.664-7.758-2.008C195.156,347.172,192,341.82,192,336V176  c0-5.82,3.156-11.172,8.242-13.992c5.086-2.836,11.305-2.664,16.238,0.422l128,80c4.676,2.93,7.52,8.055,7.52,13.57  S349.156,266.641,344.48,269.57z" />
        </svg>
      );
    case "stripeIcon":
      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400">
          <path
            style={{
              fillRule: "evenodd",
              clipRule: "evenodd",
              fill: "#635bff",
            }}
            d="..."
          />
  
        </svg>
      );

    default:
      break;
  }
  return null;
};

export default CommonSVGFiles;
