import { FC } from "react";

const PrivacyPolicy: FC = () => {
  return (
    <div className="privacy-policy">
      <div className="container">
        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <strong>
                <span style={{ fontSize: "19.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "black" }}>PRIVACY NOTICE</span>
                  </span>
                </span>
              </strong>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <strong>
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: " #7f7f7f" }}>
                      Last updated&nbsp;August 29, 2022
                    </span>
                  </span>
                </span>
              </strong>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "#595959" }}>
                    This privacy notice for&nbsp;Renters Book, LLC&nbsp;(&quot;
                    <strong>Company</strong>,&quot; &quot;<strong>we</strong>
                    ,&quot; &quot;<strong>us</strong>,&quot; or &quot;
                    <strong>our</strong>&quot;), describes how and why we might
                    collect, store, use, and/or share (&quot;
                    <strong>process</strong>&quot;) your information when you
                    use our services (&quot;<strong>Services</strong>&quot;),
                    such as when you:
                  </span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <ul style={{ listStyleType: "square" }}>
          <li>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ color: "black" }}>
                <span style={{ fontFamily: " Calibri, sans-serif" }}>
                  <span style={{ fontSize: "11.5pt" }}>
                    <span style={{ fontFamily: "Times New Roman, serif" }}>
                      <span style={{ color: "#595959" }}>
                        Visit our website&nbsp;at&nbsp;
                      </span>
                    </span>
                  </span>
                  <a
                    href="https://www.rentersbook.com"
                    style={{ color: "blue", textDecoration: "underline" }}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <span style={{ fontSize: "11.5pt" }}>
                      <span style={{ fontFamily: "Times New Roman, serif" }}>
                        https://www.rentersbook.com
                      </span>
                    </span>
                  </a>
                  <span style={{ fontSize: "11.5pt" }}>
                    <span style={{ fontFamily: "Times New Roman, serif" }}>
                      <span style={{ color: "#595959" }}>
                        , or any website of ours that links to this privacy
                        notice
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>

        <ul style={{ listStyleType: "square" }}>
          <li>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ color: "black" }}>
                <span style={{ fontFamily: " Calibri, sans-serif" }}>
                  <span style={{ fontSize: "11.5pt" }}>
                    <span style={{ fontFamily: "Times New Roman, serif" }}>
                      <span style={{ color: "#595959" }}>
                        Engage with us in other related ways, including any
                        sales, marketing, or events
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <strong>
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: " #7f7f7f" }}>
                      Questions or concerns?&nbsp;
                    </span>
                  </span>
                </span>
              </strong>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: " #7f7f7f" }}>
                    Reading this privacy notice will help you understand your
                    privacy rights and choices. If you do not agree with our
                    policies and practices, please do not use our Services. If
                    you still have any questions or concerns, please contact us
                    at&nbsp;info@rentersbook.com.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <strong>
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "black" }}>
                      SUMMARY OF KEY POINTS
                    </span>
                  </span>
                </span>
              </strong>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <strong>
                <em>
                  <span style={{ fontSize: "11.5pt" }}>
                    <span style={{ fontFamily: "Times New Roman, serif" }}>
                      <span style={{ color: "black" }}>
                        This summary provides key points from our privacy
                        notice, but you can find out more details about any of
                        these topics by clicking the link following each key
                        point or by using our table of contents below to find
                        the section you are looking for. You can also
                        click&nbsp;
                      </span>
                    </span>
                  </span>
                </em>
              </strong>
              <a
                href="file:///C:/Users/Biz4Group/Desktop/testoday1.html#toc"
                style={{ color: "blue", textDecoration: "underline" }}
              >
                <strong>
                  <em>
                    <span style={{ fontSize: "11.5pt" }}>
                      <span style={{ fontFamily: "Times New Roman, serif" }}>
                        here
                      </span>
                    </span>
                  </em>
                </strong>
              </a>
              <strong>
                <em>
                  <span style={{ fontSize: "11.5pt" }}>
                    <span style={{ fontFamily: "Times New Roman, serif" }}>
                      <span style={{ color: "black" }}>
                        &nbsp;to go directly to our table of contents.
                      </span>
                    </span>
                  </span>
                </em>
              </strong>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <strong>
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "black" }}>
                      What personal information do we process?
                    </span>
                  </span>
                </span>
              </strong>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "black" }}>
                    &nbsp;When you visit, use, or navigate our Services, we may
                    process personal information depending on how you interact
                    with&nbsp;Renters Book, LLC&nbsp;and the Services, the
                    choices you make, and the products and features you use.
                    Click&nbsp;
                  </span>
                </span>
              </span>
              <a
                href="file:///C:/Users/Biz4Group/Desktop/testoday1.html#personalinfo"
                style={{ color: "blue", textDecoration: "underline" }}
              >
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    here
                  </span>
                </span>
              </a>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "black" }}>&nbsp;to learn more.</span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <strong>
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "black" }}>
                      Do we process any sensitive personal information?
                    </span>
                  </span>
                </span>
              </strong>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "black" }}>
                    &nbsp;We do not process sensitive personal information.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <strong>
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "black" }}>
                      Do we receive any information from third parties?
                    </span>
                  </span>
                </span>
              </strong>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "black" }}>
                    &nbsp;We do not receive any information from third parties.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <strong>
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "black" }}>
                      How do we process your information?
                    </span>
                  </span>
                </span>
              </strong>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "black" }}>
                    &nbsp;We process your information to provide, improve, and
                    administer our Services, communicate with you, for security
                    and fraud prevention, and comply with the law. We may also
                    process your information for other purposes with your
                    consent. We process your information only when we have a
                    valid legal reason to do so. Click&nbsp;
                  </span>
                </span>
              </span>
              <a
                href="file:///C:/Users/Biz4Group/Desktop/testoday1.html#infouse"
                style={{ color: "blue", textDecoration: "underline" }}
              >
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    here
                  </span>
                </span>
              </a>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "black" }}>&nbsp;to learn more.</span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <strong>
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "black" }}>
                      In what situations and with which&nbsp;parties do we share
                      personal information?
                    </span>
                  </span>
                </span>
              </strong>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "black" }}>
                    &nbsp;We may share information in specific situations and
                    with specific&nbsp;third parties. Click&nbsp;
                  </span>
                </span>
              </span>
              <a
                href="file:///C:/Users/Biz4Group/Desktop/testoday1.html#whoshare"
                style={{ color: "blue", textDecoration: "underline" }}
              >
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    here
                  </span>
                </span>
              </a>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "black" }}>&nbsp;to learn more.</span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <strong>
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "black" }}>
                      How do we keep your information safe?
                    </span>
                  </span>
                </span>
              </strong>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "black" }}>
                    &nbsp;We have&nbsp;organizational&nbsp;and technical
                    processes and procedures in place to protect your personal
                    information. However, no electronic transmission over the
                    internet or information storage technology can be guaranteed
                    to be 100% secure, so we cannot promise or guarantee that
                    hackers, cybercriminals, or
                    other&nbsp;unauthorized&nbsp;third parties will not be able
                    to defeat our security and improperly collect, access,
                    steal, or modify your information. Click&nbsp;
                  </span>
                </span>
              </span>
              <a
                href="file:///C:/Users/Biz4Group/Desktop/testoday1.html#infosafe"
                style={{ color: "blue", textDecoration: "underline" }}
              >
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    here
                  </span>
                </span>
              </a>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "black" }}>&nbsp;to learn more.</span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <strong>
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "black" }}>
                      What are your rights?
                    </span>
                  </span>
                </span>
              </strong>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "black" }}>
                    &nbsp;Depending on where you are located geographically, the
                    applicable privacy law may mean you have certain rights
                    regarding your personal information. Click&nbsp;
                  </span>
                </span>
              </span>
              <a
                href="file:///C:/Users/Biz4Group/Desktop/testoday1.html#privacyrights"
                style={{ color: "blue", textDecoration: "underline" }}
              >
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    here
                  </span>
                </span>
              </a>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "black" }}>&nbsp;to learn more.</span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <strong>
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "black" }}>
                      How do you exercise your rights?
                    </span>
                  </span>
                </span>
              </strong>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "black" }}>
                    &nbsp;The easiest way to exercise your rights is by filling
                    out our data subject request form available&nbsp;here:&nbsp;
                  </span>
                </span>
              </span>
              <a
                href="http://www.rentersbook.com"
                style={{ color: "blue", textDecoration: "underline" }}
                rel="noreferrer"
                target="_blank"
              >
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    http://www.rentersbook.com
                  </span>
                </span>
              </a>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "black" }}>
                    , or by contacting us. We will consider and act upon any
                    request in accordance with applicable data protection laws.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "black" }}>
                    Want to learn more about what&nbsp;Renters Book,
                    LLC&nbsp;does with any information we collect? Click&nbsp;
                  </span>
                </span>
              </span>
              <a
                href="file:///C:/Users/Biz4Group/Desktop/testoday1.html#toc"
                style={{ color: "blue", textDecoration: "underline" }}
              >
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    here
                  </span>
                </span>
              </a>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "black" }}>
                    &nbsp;to review the notice in full.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <strong>
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "black" }}>TABLE OF CONTENTS</span>
                  </span>
                </span>
              </strong>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <a
                href="file:///C:/Users/Biz4Group/Desktop/testoday1.html#infocollect"
                style={{ color: "blue", textDecoration: "underline" }}
              >
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "#595959" }}>
                      1. WHAT INFORMATION DO WE COLLECT?
                    </span>
                  </span>
                </span>
              </a>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <a
                href="file:///C:/Users/Biz4Group/Desktop/testoday1.html#infouse"
                style={{ color: "blue", textDecoration: "underline" }}
              >
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "#595959" }}>
                      2. HOW DO WE PROCESS YOUR INFORMATION?
                    </span>
                  </span>
                </span>
              </a>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <a
                href="file:///C:/Users/Biz4Group/Desktop/testoday1.html#whoshare"
                style={{ color: "blue", textDecoration: "underline" }}
              >
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                  </span>
                </span>
              </a>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <a
                href="file:///C:/Users/Biz4Group/Desktop/testoday1.html#cookies"
                style={{ color: "blue", textDecoration: "underline" }}
              >
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "#595959" }}>
                      4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                    </span>
                  </span>
                </span>
              </a>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <a
                href="file:///C:/Users/Biz4Group/Desktop/testoday1.html#sociallogins"
                style={{ color: "blue", textDecoration: "underline" }}
              >
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "#595959" }}>
                      5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                    </span>
                  </span>
                </span>
              </a>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <a
                href="file:///C:/Users/Biz4Group/Desktop/testoday1.html#inforetain"
                style={{ color: "blue", textDecoration: "underline" }}
              >
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "#595959" }}>
                      6. HOW LONG DO WE KEEP YOUR INFORMATION?
                    </span>
                  </span>
                </span>
              </a>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <a
                href="file:///C:/Users/Biz4Group/Desktop/testoday1.html#infosafe"
                style={{ color: "blue", textDecoration: "underline" }}
              >
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "#595959" }}>
                      7. HOW DO WE KEEP YOUR INFORMATION SAFE?
                    </span>
                  </span>
                </span>
              </a>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <a
                href="file:///C:/Users/Biz4Group/Desktop/testoday1.html#privacyrights"
                style={{ color: "blue", textDecoration: "underline" }}
              >
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    8. WHAT ARE YOUR PRIVACY RIGHTS?
                  </span>
                </span>
              </a>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <a
                href="file:///C:/Users/Biz4Group/Desktop/testoday1.html#DNT"
                style={{ color: "blue", textDecoration: "underline" }}
              >
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "#595959" }}>
                      9. CONTROLS FOR DO-NOT-TRACK FEATURES
                    </span>
                  </span>
                </span>
              </a>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <a
                href="file:///C:/Users/Biz4Group/Desktop/testoday1.html#caresidents"
                style={{ color: "blue", textDecoration: "underline" }}
              >
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "#595959" }}>
                      10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                    </span>
                  </span>
                </span>
              </a>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <a
                href="file:///C:/Users/Biz4Group/Desktop/testoday1.html#policyupdates"
                style={{ color: "blue", textDecoration: "underline" }}
              >
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "#595959" }}>
                      11. DO WE MAKE UPDATES TO THIS NOTICE?
                    </span>
                  </span>
                </span>
              </a>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <a
                href="file:///C:/Users/Biz4Group/Desktop/testoday1.html#contact"
                style={{ color: "blue", textDecoration: "underline" }}
              >
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "#595959" }}>
                      12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                    </span>
                  </span>
                </span>
              </a>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <a
                href="file:///C:/Users/Biz4Group/Desktop/testoday1.html#request"
                style={{ color: "blue", textDecoration: "underline" }}
              >
                <span style={{ fontSize: "13.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "#595959" }}>
                      13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE
                      COLLECT FROM YOU?
                    </span>
                  </span>
                </span>
              </a>
            </span>
          </span>
        </p>

        <p>&nbsp;</p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <strong>
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "black" }}>
                      1. WHAT INFORMATION DO WE COLLECT?
                    </span>
                  </span>
                </span>
              </strong>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <strong>
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "black" }}>
                      The personal information you disclose to us
                    </span>
                  </span>
                </span>
              </strong>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <strong>
                <em>
                  <span style={{ fontSize: "11.5pt" }}>
                    <span style={{ fontFamily: "Times New Roman, serif" }}>
                      <span style={{ color: "#595959" }}>In Short:&nbsp;</span>
                    </span>
                  </span>
                </em>
              </strong>
              <em>
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "#595959" }}>
                      We collect personal information that you provide to us.
                    </span>
                  </span>
                </span>
              </em>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "#595959" }}>
                    We collect personal information that you voluntarily provide
                    to us when you&nbsp;register on the Services,&nbsp;express
                    an interest in obtaining information about us or our
                    products and Services, when you participate in activities on
                    the Services, or otherwise when you contact us.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <strong>
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "#595959" }}>
                      Personal Information Provided by You.
                    </span>
                  </span>
                </span>
              </strong>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "#595959" }}>
                    &nbsp;The personal information that we collect depends on
                    the context of your interactions with us and the Services,
                    the choices you make, and the products and features you use.
                    The personal information we collect may include the
                    following:
                  </span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <ul style={{ listStyleType: "square" }}>
          <li>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ color: "black" }}>
                <span style={{ fontFamily: " Calibri, sans-serif" }}>
                  <span style={{ fontSize: "11.5pt" }}>
                    <span style={{ fontFamily: "Times New Roman, serif" }}>
                      <span style={{ color: "#595959" }}>Names</span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>

        <ul style={{ listStyleType: "square" }}>
          <li>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ color: "black" }}>
                <span style={{ fontFamily: " Calibri, sans-serif" }}>
                  <span style={{ fontSize: "11.5pt" }}>
                    <span style={{ fontFamily: "Times New Roman, serif" }}>
                      <span style={{ color: "#595959" }}>Phone numbers</span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>

        <ul style={{ listStyleType: "square" }}>
          <li>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ color: "black" }}>
                <span style={{ fontFamily: " Calibri, sans-serif" }}>
                  <span style={{ fontSize: "11.5pt" }}>
                    <span style={{ fontFamily: "Times New Roman, serif" }}>
                      <span style={{ color: "#595959" }}>E-mail addresses</span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>

        <ul style={{ listStyleType: "square" }}>
          <li>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ color: "black" }}>
                <span style={{ fontFamily: " Calibri, sans-serif" }}>
                  <span style={{ fontSize: "11.5pt" }}>
                    <span style={{ fontFamily: "Times New Roman, serif" }}>
                      <span style={{ color: "#595959" }}>Passwords</span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>

        <ul style={{ listStyleType: "square" }}>
          <li>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ color: "black" }}>
                <span style={{ fontFamily: " Calibri, sans-serif" }}>
                  <span style={{ fontSize: "11.5pt" }}>
                    <span style={{ fontFamily: "Times New Roman, serif" }}>
                      <span style={{ color: "#595959" }}>Address</span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <strong>
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "black" }}>
                      Sensitive Information.
                    </span>
                  </span>
                </span>
              </strong>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "black" }}>
                    &nbsp;We do not process sensitive information.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <strong>
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "#595959" }}>Payment Data.</span>
                  </span>
                </span>
              </strong>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "#595959" }}>
                    &nbsp;We may collect data necessary to process your payment
                    if you make purchases, such as your payment instrument
                    number (such as a credit card number), and the security code
                    associated with your payment instrument. All payment data is
                    stored by&nbsp;
                  </span>
                </span>
              </span>
              <a
                href="https://stripe.com"
                style={{ color: "blue", textDecoration: "underline" }}
              >
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    https://stripe.com
                  </span>
                </span>
              </a>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "#595959" }}>
                    . You may find their privacy notice link(s)
                  </span>
                </span>
              </span>
              <a
                href="https://stripe.com/privacy"
                style={{ color: "blue", textDecoration: "underline" }}
              >
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    here
                  </span>
                </span>
              </a>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "#595959" }}>.</span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <strong>
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "#595959" }}>
                      Social Media Login Data.&nbsp;
                    </span>
                  </span>
                </span>
              </strong>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "#595959" }}>
                    We may provide you with the option to register with us using
                    your existing social media account details, like your
                    Facebook, Twitter, or other social media account. If you
                    choose to register in this way, we will collect the
                    information described in the section called&nbsp;&quot;
                  </span>
                </span>
              </span>
              <a
                href="file:///C:/Users/Biz4Group/Desktop/testoday1.html#sociallogins"
                style={{ color: "blue", textDecoration: "underline" }}
              >
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                  </span>
                </span>
              </a>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "#595959" }}>&quot;&nbsp;below.</span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "#595959" }}>
                    All personal information that you provide to us must be
                    true, complete, and accurate, and you must notify us of any
                    changes to such personal information.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <strong>
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "black" }}>
                      Information automatically collected
                    </span>
                  </span>
                </span>
              </strong>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <strong>
                <em>
                  <span style={{ fontSize: "11.5pt" }}>
                    <span style={{ fontFamily: "Times New Roman, serif" }}>
                      <span style={{ color: "#595959" }}>In Short:&nbsp;</span>
                    </span>
                  </span>
                </em>
              </strong>
              <em>
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "#595959" }}>
                      Some information &mdash; such as your Internet Protocol
                      (IP) address and/or browser and device characteristics
                      &mdash; is collected automatically when you visit our
                      Services.
                    </span>
                  </span>
                </span>
              </em>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "#595959" }}>
                    We automatically collect certain information when you visit,
                    use, or navigate the Services. This information does not
                    reveal your specific identity (like your name or contact
                    information) but may include device and usage information,
                    such as your IP address, browser, and device
                    characteristics, operating system, language preferences,
                    referring URLs, device name, country, location, information
                    about how and when you use our Services, and other technical
                    information. This information is primarily needed to
                    maintain the security and operation of our Services, and for
                    our internal analytics and reporting purposes.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "#595959" }}>
                    Like many businesses, we also collect information through
                    cookies and similar technologies.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "#595959" }}>
                    The information we collect includes:
                  </span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <ul style={{ listStyleType: "square" }}>
          <li>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ color: "black" }}>
                <span style={{ fontFamily: " Calibri, sans-serif" }}>
                  <em>
                    <span style={{ fontSize: "11.5pt" }}>
                      <span style={{ fontFamily: "Times New Roman, serif" }}>
                        <span style={{ color: "#595959" }}>
                          Log and Usage Data.
                        </span>
                      </span>
                    </span>
                  </em>
                  <span style={{ fontSize: "11.5pt" }}>
                    <span style={{ fontFamily: "Times New Roman, serif" }}>
                      <span style={{ color: "#595959" }}>
                        &nbsp;Log and usage data are service-related,
                        diagnostic, usage, and performance information our
                        servers automatically collect when you access or use our
                        Services and which we record in log files. Depending on
                        how you interact with us, this log data may include your
                        IP address, device information, browser type, and
                        settings and information about your activity in the
                        Services&nbsp;(such as the date/time stamps associated
                        with your usage, pages, and files viewed, searches, and
                        other actions you take such as which features you use),
                        device event information (such as system activity, error
                        reports (sometimes called&nbsp;&quot;crash dumps&quot;),
                        and hardware settings).
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>

        <ul style={{ listStyleType: "square" }}>
          <li>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ color: "black" }}>
                <span style={{ fontFamily: " Calibri, sans-serif" }}>
                  <em>
                    <span style={{ fontSize: "11.5pt" }}>
                      <span style={{ fontFamily: "Times New Roman, serif" }}>
                        <span style={{ color: "#595959" }}>Device Data.</span>
                      </span>
                    </span>
                  </em>
                  <span style={{ fontSize: "11.5pt" }}>
                    <span style={{ fontFamily: "Times New Roman, serif" }}>
                      <span style={{ color: "#595959" }}>
                        &nbsp;We collect device data such as information about
                        your computer, phone, tablet, or another device you use
                        to access the Services. Depending on the device used,
                        this device data may include information such as your IP
                        address (or proxy server), device and application
                        identification numbers, location, browser type, hardware
                        model, Internet service provider and/or mobile carrier,
                        operating system, and system configuration information.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <strong>
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "black" }}>
                      2. HOW DO WE PROCESS YOUR INFORMATION?
                    </span>
                  </span>
                </span>
              </strong>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <strong>
                <em>
                  <span style={{ fontSize: "11.5pt" }}>
                    <span style={{ fontFamily: "Times New Roman, serif" }}>
                      <span style={{ color: "#595959" }}>In Short:&nbsp;</span>
                    </span>
                  </span>
                </em>
              </strong>
              <em>
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "#595959" }}>
                      We process your information to provide, improve, and
                      administer our Services, communicate with you, for
                      security and fraud prevention, and comply with the law. We
                      may also process your information for other purposes with
                      your consent.
                    </span>
                  </span>
                </span>
              </em>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <strong>
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "#595959" }}>
                      We process your personal information for a variety of
                      reasons, depending on how you interact with our Services,
                      including:
                    </span>
                  </span>
                </span>
              </strong>
            </span>
          </span>
        </p>

        <ul style={{ listStyleType: "square" }}>
          <li>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ color: "black" }}>
                <span style={{ fontFamily: " Calibri, sans-serif" }}>
                  <strong>
                    <span style={{ fontSize: "11.5pt" }}>
                      <span style={{ fontFamily: "Times New Roman, serif" }}>
                        <span style={{ color: "#595959" }}>
                          To facilitate account creation and authentication and
                          otherwise manage user accounts.&nbsp;
                        </span>
                      </span>
                    </span>
                  </strong>
                  <span style={{ fontSize: "11.5pt" }}>
                    <span style={{ fontFamily: "Times New Roman, serif" }}>
                      <span style={{ color: "#595959" }}>
                        We may process your information so you can create and
                        log in to your account, as well as keep your account in
                        working order.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>

        <ul style={{ listStyleType: "square" }}>
          <li>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ color: "black" }}>
                <span style={{ fontFamily: " Calibri, sans-serif" }}>
                  <strong>
                    <span style={{ fontSize: "11.5pt" }}>
                      <span style={{ fontFamily: "Times New Roman, serif" }}>
                        <span style={{ color: "#595959" }}>
                          To deliver and facilitate the delivery of services to
                          the user.&nbsp;
                        </span>
                      </span>
                    </span>
                  </strong>
                  <span style={{ fontSize: "11.5pt" }}>
                    <span style={{ fontFamily: "Times New Roman, serif" }}>
                      <span style={{ color: "#595959" }}>
                        We may process your information to provide you with the
                        requested service.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>

        <ul style={{ listStyleType: "square" }}>
          <li>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ color: "black" }}>
                <span style={{ fontFamily: " Calibri, sans-serif" }}>
                  <strong>
                    <span style={{ fontSize: "11.5pt" }}>
                      <span style={{ fontFamily: "Times New Roman, serif" }}>
                        <span style={{ color: "#595959" }}>
                          To respond to user inquiries/offer support to
                          users.&nbsp;
                        </span>
                      </span>
                    </span>
                  </strong>
                  <span style={{ fontSize: "11.5pt" }}>
                    <span style={{ fontFamily: "Times New Roman, serif" }}>
                      <span style={{ color: "#595959" }}>
                        We may process your information to respond to your
                        inquiries and solve any potential issues you might have
                        with the requested service.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>

        <ul style={{ listStyleType: "square" }}>
          <li>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ color: "black" }}>
                <span style={{ fontFamily: " Calibri, sans-serif" }}>
                  <strong>
                    <span style={{ fontSize: "11.5pt" }}>
                      <span style={{ fontFamily: "Times New Roman, serif" }}>
                        <span style={{ color: "#595959" }}>
                          To enable user-to-user communications.&nbsp;
                        </span>
                      </span>
                    </span>
                  </strong>
                  <span style={{ fontSize: "11.5pt" }}>
                    <span style={{ fontFamily: "Times New Roman, serif" }}>
                      <span style={{ color: "#595959" }}>
                        We may process your information if you choose to use any
                        of our offerings that allow for communication with
                        another user.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>

        <ul style={{ listStyleType: "square" }}>
          <li>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ color: "black" }}>
                <span style={{ fontFamily: " Calibri, sans-serif" }}>
                  <strong>
                    <span style={{ fontSize: "11.5pt" }}>
                      <span style={{ fontFamily: "Times New Roman, serif" }}>
                        <span style={{ color: "#595959" }}>
                          To request feedback.&nbsp;
                        </span>
                      </span>
                    </span>
                  </strong>
                  <span style={{ fontSize: "11.5pt" }}>
                    <span style={{ fontFamily: "Times New Roman, serif" }}>
                      <span style={{ color: "#595959" }}>
                        We may process your information when necessary to
                        request feedback and to contact you about your use of
                        our Services.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>

        <ul style={{ listStyleType: "square" }}>
          <li>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ color: "black" }}>
                <span style={{ fontFamily: " Calibri, sans-serif" }}>
                  <strong>
                    <span style={{ fontSize: "11.5pt" }}>
                      <span style={{ fontFamily: "Times New Roman, serif" }}>
                        <span style={{ color: "#595959" }}>
                          To deliver targeted advertising to you.&nbsp;
                        </span>
                      </span>
                    </span>
                  </strong>
                  <span style={{ fontSize: "11.5pt" }}>
                    <span style={{ fontFamily: "Times New Roman, serif" }}>
                      <span style={{ color: "#595959" }}>
                        We may process your information to develop and
                        display&nbsp;personalized&nbsp;content and advertising
                        tailored to your interests, location, and more.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>

        <ul style={{ listStyleType: "square" }}>
          <li>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ color: "black" }}>
                <span style={{ fontFamily: " Calibri, sans-serif" }}>
                  <strong>
                    <span style={{ fontSize: "11.5pt" }}>
                      <span style={{ fontFamily: "Times New Roman, serif" }}>
                        To post testimonials.
                      </span>
                    </span>
                  </strong>
                  <span style={{ fontSize: "11.5pt" }}>
                    <span style={{ fontFamily: "Times New Roman, serif" }}>
                      &nbsp;We post testimonials on our Services that may
                      contain personal information.
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>

        <ul style={{ listStyleType: "square" }}>
          <li>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ color: "black" }}>
                <span style={{ fontFamily: " Calibri, sans-serif" }}>
                  <strong>
                    <span style={{ fontSize: "11.5pt" }}>
                      <span style={{ fontFamily: "Times New Roman, serif" }}>
                        To evaluate and improve our Services, products,
                        marketing, and your experience.
                      </span>
                    </span>
                  </strong>
                  <span style={{ fontSize: "11.5pt" }}>
                    <span style={{ fontFamily: "Times New Roman, serif" }}>
                      &nbsp;We may process your information when we believe it
                      is necessary to identify usage trends, determine the
                      effectiveness of our promotional campaigns, and to
                      evaluate and improve our Services, products, marketing,
                      and your experience.
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <strong>
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "black" }}>
                      3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
                      INFORMATION?
                    </span>
                  </span>
                </span>
              </strong>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <strong>
                <em>
                  <span style={{ fontSize: "11.5pt" }}>
                    <span style={{ fontFamily: "Times New Roman, serif" }}>
                      <span style={{ color: "#595959" }}>In Short:</span>
                    </span>
                  </span>
                </em>
              </strong>
              <em>
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "#595959" }}>
                      &nbsp;We may share information in specific situations
                      described in this section and/or with the
                      following&nbsp;third parties.
                    </span>
                  </span>
                </span>
              </em>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "black" }}>
                    We&nbsp;may need to share your personal information in the
                    following situations:
                  </span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <ul style={{ listStyleType: "square" }}>
          <li>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ color: "black" }}>
                <span style={{ fontFamily: " Calibri, sans-serif" }}>
                  <strong>
                    <span style={{ fontSize: "11.5pt" }}>
                      <span style={{ fontFamily: "Times New Roman, serif" }}>
                        Business Transfers.
                      </span>
                    </span>
                  </strong>
                  <span style={{ fontSize: "11.5pt" }}>
                    <span style={{ fontFamily: "Times New Roman, serif" }}>
                      &nbsp;We may share or transfer your information in
                      connection with, or during negotiations of, any merger,
                      sale of company assets, financing, or acquisition of all
                      or a portion of our business to another company.
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>

        <ul style={{ listStyleType: "square" }}>
          <li>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ color: "black" }}>
                <span style={{ fontFamily: " Calibri, sans-serif" }}>
                  <strong>
                    <span style={{ fontSize: "11.5pt" }}>
                      <span style={{ fontFamily: "Times New Roman, serif" }}>
                        Business Partners.
                      </span>
                    </span>
                  </strong>
                  <span style={{ fontSize: "11.5pt" }}>
                    <span style={{ fontFamily: "Times New Roman, serif" }}>
                      &nbsp;We may share your information with our business
                      partners to offer you certain products, services, or
                      promotions.
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>

        <ul style={{ listStyleType: "square" }}>
          <li>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ color: "black" }}>
                <span style={{ fontFamily: " Calibri, sans-serif" }}>
                  <strong>
                    <span style={{ fontSize: "11.5pt" }}>
                      <span style={{ fontFamily: "Times New Roman, serif" }}>
                        Other Users.
                      </span>
                    </span>
                  </strong>
                  <span style={{ fontSize: "11.5pt" }}>
                    <span style={{ fontFamily: "Times New Roman, serif" }}>
                      &nbsp;When you share personal information&nbsp;(for
                      example, by posting comments, contributions, or other
                      content to the Services)&nbsp;or otherwise interact with
                      public areas of the Services, such personal information
                      may be viewed by all users and may be publicly made
                      available outside the Services in perpetuity.&nbsp;If you
                      interact with other users of our Services and register for
                      our Services through a social network (such as Facebook),
                      your contacts on the social network will see your name,
                      profile photo, and descriptions of your
                      activity.&nbsp;Similarly, other users will be able to view
                      descriptions of your activity, communicate with you within
                      our Services, and view your profile.
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <strong>
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "black" }}>
                      4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                    </span>
                  </span>
                </span>
              </strong>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <strong>
                <em>
                  <span style={{ fontSize: "11.5pt" }}>
                    <span style={{ fontFamily: "Times New Roman, serif" }}>
                      <span style={{ color: "#595959" }}>In Short:</span>
                    </span>
                  </span>
                </em>
              </strong>
              <em>
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "#595959" }}>
                      &nbsp;We may use cookies and other tracking technologies
                      to collect and store your information.
                    </span>
                  </span>
                </span>
              </em>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "#595959" }}>
                    We may use cookies and similar tracking technologies (like
                    web beacons and pixels) to access or store information.
                    Specific information about how we use such technologies and
                    how you can refuse certain cookies is set out in our Cookie
                    Notice.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <strong>
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "black" }}>
                      5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                    </span>
                  </span>
                </span>
              </strong>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <strong>
                <em>
                  <span style={{ fontSize: "11.5pt" }}>
                    <span style={{ fontFamily: "Times New Roman, serif" }}>
                      <span style={{ color: "#595959" }}>In Short:&nbsp;</span>
                    </span>
                  </span>
                </em>
              </strong>
              <em>
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "#595959" }}>
                      If you choose to register or log in to our services using
                      a social media account, we may have access to certain
                      information about you.
                    </span>
                  </span>
                </span>
              </em>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "#595959" }}>
                    Our Services offer you the ability to register and log in
                    using your third-party social media account details (like
                    your Facebook or Twitter logins). Where you choose to do
                    this, we will receive certain profile information about you
                    from your social media provider. The profile information we
                    receive may vary depending on the social media provider
                    concerned, but will often include your name, email address,
                    friends list, and profile picture, as well as other
                    information you choose to make public on such a social media
                    platform
                  </span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "#595959" }}>
                    We will use the information we receive only for the purposes
                    that are described in this privacy notice or that are
                    otherwise made clear to you on the relevant Services. Please
                    note that we do not control, and are not responsible for,
                    other uses of your personal information by your third-party
                    social media provider. We recommend that you review their
                    privacy notice to understand how they collect, use, and
                    share your personal information, and how you can set your
                    privacy preferences on their sites and apps.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <strong>
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "black" }}>
                      6. HOW LONG DO WE KEEP YOUR INFORMATION?
                    </span>
                  </span>
                </span>
              </strong>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <strong>
                <em>
                  <span style={{ fontSize: "11.5pt" }}>
                    <span style={{ fontFamily: "Times New Roman, serif" }}>
                      <span style={{ color: "#595959" }}>In Short:&nbsp;</span>
                    </span>
                  </span>
                </em>
              </strong>
              <em>
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "#595959" }}>
                      We keep your information for as long as necessary
                      to&nbsp;fulfill&nbsp;the purposes outlined in this privacy
                      notice unless otherwise required by law.
                    </span>
                  </span>
                </span>
              </em>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "#595959" }}>
                    We will only keep your personal information for as long as
                    it is necessary for the purposes set out in this privacy
                    notice unless a longer retention period is required or
                    permitted by law (such as tax, accounting, or other legal
                    requirements). No purpose in this notice will require us to
                    keep your personal information for longer than&nbsp;the time
                    in which users have an account with us.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "#595959" }}>
                    When we have no ongoing legitimate business need to process
                    your personal information, we will either delete
                    or&nbsp;anonymize&nbsp;such information or, if this is not
                    possible (for example, because your personal information has
                    been stored in backup archives), then we will securely store
                    your personal information and isolate it from any further
                    processing until deletion is possible.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <strong>
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "black" }}>
                      7. HOW DO WE KEEP YOUR INFORMATION SAFE?
                    </span>
                  </span>
                </span>
              </strong>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <strong>
                <em>
                  <span style={{ fontSize: "11.5pt" }}>
                    <span style={{ fontFamily: "Times New Roman, serif" }}>
                      <span style={{ color: "#595959" }}>In Short:&nbsp;</span>
                    </span>
                  </span>
                </em>
              </strong>
              <em>
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "#595959" }}>
                      We aim to protect your personal information through a
                      system of&nbsp;organizational&nbsp;and technical security
                      measures.
                    </span>
                  </span>
                </span>
              </em>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "#595959" }}>
                    We have implemented appropriate and reasonable technical
                    and&nbsp;organizational&nbsp;security measures designed to
                    protect the security of any personal information we process.
                    However, despite our safeguards and efforts to secure your
                    information, no electronic transmission over the Internet or
                    information storage technology can be guaranteed to be 100%
                    secure, so we cannot promise or guarantee that hackers,
                    cybercriminals, or other&nbsp;unauthorized&nbsp;third
                    parties will not be able to defeat our security and
                    improperly collect, access, steal, or modify your
                    information. Although we will do our best to protect your
                    personal information, the transmission of personal
                    information to and from our Services is at your own risk.
                    You should only access the Services within a secure
                    environment.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <strong>
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "black" }}>
                      8. WHAT ARE YOUR PRIVACY RIGHTS?
                    </span>
                  </span>
                </span>
              </strong>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <strong>
                <em>
                  <span style={{ fontSize: "11.5pt" }}>
                    <span style={{ fontFamily: "Times New Roman, serif" }}>
                      <span style={{ color: "#595959" }}>In Short:</span>
                    </span>
                  </span>
                </em>
              </strong>
              <em>
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "#595959" }}>
                      &nbsp;&nbsp;You may review, change, or terminate your
                      account at any time.
                    </span>
                  </span>
                </span>
              </em>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "#595959" }}>
                    If you are located in the EEA or UK and you believe we are
                    unlawfully processing your personal information, you also
                    have the right to complain to your local data protection
                    supervisory authority. You can find their contact details
                    here:&nbsp;
                  </span>
                </span>
              </span>
              <a
                href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                style={{ color: "blue", textDecoration: "underline" }}
                rel="noreferrer"
                target="_blank"
              >
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
                  </span>
                </span>
              </a>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "#595959" }}>.</span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "#595959" }}>
                    If you are located in Switzerland, the contact details for
                    the data protection authorities are available here:&nbsp;
                  </span>
                </span>
              </span>
              <a
                href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                style={{ color: "blue", textDecoration: "underline" }}
                rel="noreferrer"
                target="_blank"
              >
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    https://www.edoeb.admin.ch/edoeb/en/home.html
                  </span>
                </span>
              </a>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "#595959" }}>.</span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <strong>
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "#595959" }}>
                      Withdrawing your consent:
                    </span>
                  </span>
                </span>
              </strong>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "#595959" }}>
                    &nbsp;If we are relying on your consent to process your
                    personal information,&nbsp;which may be express and/or
                    implied consent depending on the applicable law,&nbsp;you
                    have the right to withdraw your consent at any time. You can
                    withdraw your consent at any time by contacting us by using
                    the contact details provided in the section&nbsp;&quot;
                  </span>
                </span>
              </span>
              <a
                href="file:///C:/Users/Biz4Group/Desktop/testoday1.html#contact"
                style={{ color: "blue", textDecoration: "underline" }}
              >
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "#595959" }}>
                      HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                    </span>
                  </span>
                </span>
              </a>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "#595959" }}>&quot;&nbsp;below.</span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "black" }}>
                    However, please note that this will not affect the
                    lawfulness of the processing before its withdrawal
                    nor,&nbsp;when applicable law allows,&nbsp;will it affect
                    the processing of your personal information conducted in
                    reliance on lawful processing grounds other than consent.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <strong>
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "black" }}>Account Information</span>
                  </span>
                </span>
              </strong>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "black" }}>
                    If you would at any time like to review or change the
                    information in your account or terminate your account, you
                    can:
                  </span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <ul style={{ listStyleType: "square" }}>
          <li>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ color: "black" }}>
                <span style={{ fontFamily: " Calibri, sans-serif" }}>
                  <span style={{ fontSize: "11.5pt" }}>
                    <span style={{ fontFamily: "Times New Roman, serif" }}>
                      Log in to your account settings and update your user
                      account.
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "black" }}>
                    Upon your request to terminate your account, we will
                    deactivate or delete your account and information from our
                    active databases. However, we may retain some information in
                    our files to prevent fraud, troubleshoot problems, assist
                    with any investigations, enforce our legal terms, and/or
                    comply with applicable legal requirements.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <strong>
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "#595959" }}>
                      Cookies and similar technologies:
                    </span>
                  </span>
                </span>
              </strong>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "#595959" }}>
                    &nbsp;Most Web browsers are set to accept cookies by
                    default. If you prefer, you can usually choose to set your
                    browser to remove cookies and reject cookies. If you choose
                    to remove cookies or reject cookies, this could affect
                    certain features or services of our Services. To opt-out of
                    interest-based advertising by advertisers on our Services
                    visit&nbsp;
                  </span>
                </span>
              </span>
              <a
                href="http://www.aboutads.info/choices/"
                style={{ color: "blue", textDecoration: "underline" }}
                rel="noreferrer"
                target="_blank"
              >
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    http://www.aboutads.info/choices/
                  </span>
                </span>
              </a>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "#595959" }}>.</span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <strong>
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "black" }}>
                      9. CONTROLS FOR DO-NOT-TRACK FEATURES
                    </span>
                  </span>
                </span>
              </strong>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "#595959" }}>
                    Most web browsers and some mobile operating systems and
                    mobile applications include a Do-Not-Track (&quot;DNT&quot;)
                    feature or setting you can activate to signal your privacy
                    preference and not to have data about your online browsing
                    activities monitored and collected. At this stage, no
                    uniform technology standard for&nbsp;recognizing&nbsp;and
                    implementing DNT signals has been&nbsp;finalized. As such,
                    we do not currently respond to DNT browser signals or any
                    other mechanism that automatically communicates your choice
                    not to be tracked online. If a standard for online tracking
                    is adopted that we must follow in the future, we will inform
                    you about that practice in a revised version of this privacy
                    notice.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <strong>
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "black" }}>
                      10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                    </span>
                  </span>
                </span>
              </strong>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <strong>
                <em>
                  <span style={{ fontSize: "11.5pt" }}>
                    <span style={{ fontFamily: "Times New Roman, serif" }}>
                      <span style={{ color: "#595959" }}>In Short:&nbsp;</span>
                    </span>
                  </span>
                </em>
              </strong>
              <em>
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "#595959" }}>
                      Yes, if you are a resident of California, you are granted
                      specific rights regarding access to your personal
                      information.
                    </span>
                  </span>
                </span>
              </em>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "#595959" }}>
                    California Civil Code Section 1798.83, also known as
                    the&nbsp;&quot;Shine The Light&quot;&nbsp;law, permits our
                    users who are California residents to request and obtain
                    from us, once a year and free of charge, information about
                    categories of personal information (if any) we disclosed to
                    third parties for direct marketing purposes and the names
                    and addresses of all third parties with which we shared
                    personal information in the immediately preceding calendar
                    year. If you are a California resident and would like to
                    make such a request, please submit your request in writing
                    to us using the contact information provided below.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "#595959" }}>
                    If you are under 18 years of age, reside in California, and
                    have a registered account with Services, you have the right
                    to request the removal of unwanted data that you publicly
                    post on the Services. To request the removal of such data,
                    please contact us using the contact information provided
                    below and include the email address associated with your
                    account and a statement that you reside in California. We
                    will make sure the data is not publicly displayed on the
                    Services, but please be aware that the data may not be
                    completely or comprehensively removed from all our systems
                    (e.g.,&nbsp;backups, etc.).
                  </span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <strong>
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "black" }}>CCPA Privacy Notice</span>
                  </span>
                </span>
              </strong>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "#595959" }}>
                    The California Code of Regulations defines
                    a&nbsp;&quot;resident&quot;&nbsp;as:
                  </span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "#595959" }}>
                    (1) every individual who is in the State of California for
                    other than a temporary or transitory purpose and
                  </span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "#595959" }}>
                    (2) every individual who is domiciled in the State of
                    California who is outside the State of California for a
                    temporary or transitory purpose
                  </span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "#595959" }}>
                    All other individuals are defined
                    as&nbsp;&quot;non-residents.&quot;
                  </span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "#595959" }}>
                    If this definition of&nbsp;&quot;resident&quot;&nbsp;applies
                    to you, we must adhere to certain rights and obligations
                    regarding your personal information.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <strong>
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "#595959" }}>
                      What categories of personal information do we collect?
                    </span>
                  </span>
                </span>
              </strong>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "#595959" }}>
                    We have collected the following categories of personal
                    information in the past twelve (12) months:
                  </span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <table className="Table" style={{ marginLeft: "5px", width: "828px" }}>
          <tbody>
            <tr>
              <td
                style={{
                  borderBottom: "none",
                  borderLeft: "1px solid black",
                  borderRight: "1px solid black",
                  borderTop: "1px solid black",
                  width: " 291px",
                }}
              >
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ fontFamily: " Calibri, sans-serif" }}>
                      <strong>
                        <span style={{ fontSize: "11.5pt" }}>
                          <span
                            style={{ fontFamily: "Times New Roman, serif" }}
                          >
                            <span style={{ color: "#595959" }}>Category</span>
                          </span>
                        </span>
                      </strong>
                    </span>
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderBottom: "none",
                  borderLeft: "none",
                  borderRight: "1px solid black",
                  borderTop: "1px solid black",
                  width: " 448px",
                }}
              >
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ fontFamily: " Calibri, sans-serif" }}>
                      <strong>
                        <span style={{ fontSize: "11.5pt" }}>
                          <span
                            style={{ fontFamily: "Times New Roman, serif" }}
                          >
                            <span style={{ color: "#595959" }}>Examples</span>
                          </span>
                        </span>
                      </strong>
                    </span>
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderBottom: "none",
                  borderLeft: "none",
                  borderRight: "1px solid black",
                  borderTop: "1px solid black",
                  width: " 81px",
                }}
              >
                <p style={{ textAlign: "center" }}>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ fontFamily: " Calibri, sans-serif" }}>
                      <strong>
                        <span style={{ fontSize: "11.5pt" }}>
                          <span
                            style={{ fontFamily: "Times New Roman, serif" }}
                          >
                            <span style={{ color: "#595959" }}>Collected</span>
                          </span>
                        </span>
                      </strong>
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderBottom: "none",
                  borderLeft: "1px solid black",
                  borderRight: "1px solid black",
                  borderTop: "1px solid black",
                  width: " 291px",
                }}
              >
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ fontFamily: " Calibri, sans-serif" }}>
                      <span style={{ fontSize: "11.5pt" }}>
                        <span style={{ fontFamily: "Times New Roman, serif" }}>
                          <span style={{ color: "#595959" }}>
                            A. Identifiers
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderBottom: "none",
                  borderLeft: "none",
                  borderRight: "1px solid black",
                  borderTop: "1px solid black",
                  width: " 448px",
                }}
              >
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ fontFamily: " Calibri, sans-serif" }}>
                      <span style={{ fontSize: "11.5pt" }}>
                        <span style={{ fontFamily: "Times New Roman, serif" }}>
                          <span style={{ color: "#595959" }}>
                            Contact details, such as real name, alias, postal
                            address, telephone or mobile contact number, unique
                            personal identifier, online identifier, Internet
                            Protocol address, email address, and account name
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderBottom: "none",
                  borderLeft: "none",
                  borderRight: "1px solid black",
                  borderTop: "1px solid black",
                  width: " 81px",
                }}
              >
                <p style={{ textAlign: "center" }}>&nbsp;</p>

                <p style={{ textAlign: "center" }}>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ fontFamily: " Calibri, sans-serif" }}>
                      <span style={{ fontSize: "11.5pt" }}>
                        <span style={{ fontFamily: "Times New Roman, serif" }}>
                          <span style={{ color: "#595959" }}>NO</span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderBottom: "none",
                  borderLeft: "1px solid black",
                  borderRight: "1px solid black",
                  borderTop: "1px solid black",
                  width: " 291px",
                }}
              >
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ fontFamily: " Calibri, sans-serif" }}>
                      <span style={{ fontSize: "11.5pt" }}>
                        <span style={{ fontFamily: "Times New Roman, serif" }}>
                          <span style={{ color: "#595959" }}>
                            B. Personal information categories listed in the
                            California Customer Records statute
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderBottom: "none",
                  borderLeft: "none",
                  borderRight: "1px solid black",
                  borderTop: "1px solid black",
                  width: " 448px",
                }}
              >
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ fontFamily: " Calibri, sans-serif" }}>
                      <span style={{ fontSize: "11.5pt" }}>
                        <span style={{ fontFamily: "Times New Roman, serif" }}>
                          <span style={{ color: "#595959" }}>
                            Name, contact information, education, employment,
                            employment history, and financial information
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderBottom: "none",
                  borderLeft: "none",
                  borderRight: "1px solid black",
                  borderTop: "1px solid black",
                  width: " 81px",
                }}
              >
                <p style={{ textAlign: "center" }}>&nbsp;</p>

                <p style={{ textAlign: "center" }}>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ fontFamily: " Calibri, sans-serif" }}>
                      <span style={{ fontSize: "11.5pt" }}>
                        <span style={{ fontFamily: "Times New Roman, serif" }}>
                          <span style={{ color: "#595959" }}>NO</span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderBottom: "none",
                  borderLeft: "1px solid black",
                  borderRight: "1px solid black",
                  borderTop: "1px solid black",
                  width: " 291px",
                }}
              >
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ fontFamily: " Calibri, sans-serif" }}>
                      <span style={{ fontSize: "11.5pt" }}>
                        <span style={{ fontFamily: "Times New Roman, serif" }}>
                          <span style={{ color: "#595959" }}>
                            C. Protected classification characteristics under
                            California or federal law
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderBottom: "none",
                  borderLeft: "none",
                  borderRight: "1px solid black",
                  borderTop: "1px solid black",
                  width: " 448px",
                }}
              >
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ fontFamily: " Calibri, sans-serif" }}>
                      <span style={{ fontSize: "11.5pt" }}>
                        <span style={{ fontFamily: "Times New Roman, serif" }}>
                          <span style={{ color: "#595959" }}>
                            Gender and date of birth
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderBottom: "none",
                  borderLeft: "none",
                  borderRight: "1px solid black",
                  borderTop: "1px solid black",
                  width: " 81px",
                }}
              >
                <p style={{ textAlign: "center" }}>&nbsp;</p>

                <p style={{ textAlign: "center" }}>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ fontFamily: " Calibri, sans-serif" }}>
                      <span style={{ fontSize: "11.5pt" }}>
                        <span style={{ fontFamily: "Times New Roman, serif" }}>
                          <span style={{ color: "#595959" }}>NO</span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderBottom: "none",
                  borderLeft: "1px solid black",
                  borderRight: "1px solid black",
                  borderTop: "1px solid black",
                  width: " 291px",
                }}
              >
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ fontFamily: " Calibri, sans-serif" }}>
                      <span style={{ fontSize: "11.5pt" }}>
                        <span style={{ fontFamily: "Times New Roman, serif" }}>
                          <span style={{ color: "#595959" }}>
                            D. Commercial information
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderBottom: "none",
                  borderLeft: "none",
                  borderRight: "1px solid black",
                  borderTop: "1px solid black",
                  width: " 448px",
                }}
              >
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ fontFamily: " Calibri, sans-serif" }}>
                      <span style={{ fontSize: "11.5pt" }}>
                        <span style={{ fontFamily: "Times New Roman, serif" }}>
                          <span style={{ color: "#595959" }}>
                            Transaction information, purchase history, financial
                            details, and payment information
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderBottom: "none",
                  borderLeft: "none",
                  borderRight: "1px solid black",
                  borderTop: "1px solid black",
                  width: " 81px",
                }}
              >
                <p style={{ textAlign: "center" }}>&nbsp;</p>

                <p style={{ textAlign: "center" }}>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ fontFamily: " Calibri, sans-serif" }}>
                      <span style={{ fontSize: "11.5pt" }}>
                        <span style={{ fontFamily: "Times New Roman, serif" }}>
                          <span style={{ color: "#595959" }}>NO</span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderBottom: "none",
                  borderLeft: "1px solid black",
                  borderRight: "1px solid black",
                  borderTop: "1px solid black",
                  width: " 291px",
                }}
              >
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ fontFamily: " Calibri, sans-serif" }}>
                      <span style={{ fontSize: "11.5pt" }}>
                        <span style={{ fontFamily: "Times New Roman, serif" }}>
                          <span style={{ color: "#595959" }}>
                            E. Biometric information
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderBottom: "none",
                  borderLeft: "none",
                  borderRight: "1px solid black",
                  borderTop: "1px solid black",
                  width: " 448px",
                }}
              >
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ fontFamily: " Calibri, sans-serif" }}>
                      <span style={{ fontSize: "11.5pt" }}>
                        <span style={{ fontFamily: "Times New Roman, serif" }}>
                          <span style={{ color: "#595959" }}>
                            Fingerprints and voiceprints
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderBottom: "none",
                  borderLeft: "none",
                  borderRight: "1px solid black",
                  borderTop: "1px solid black",
                  width: " 81px",
                }}
              >
                <p style={{ textAlign: "center" }}>&nbsp;</p>

                <p style={{ textAlign: "center" }}>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ fontFamily: " Calibri, sans-serif" }}>
                      <span style={{ fontSize: "11.5pt" }}>
                        <span style={{ fontFamily: "Times New Roman, serif" }}>
                          <span style={{ color: "#595959" }}>NO</span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderBottom: "none",
                  borderLeft: "1px solid black",
                  borderRight: "1px solid black",
                  borderTop: "1px solid black",
                  width: " 291px",
                }}
              >
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ fontFamily: " Calibri, sans-serif" }}>
                      <span style={{ fontSize: "11.5pt" }}>
                        <span style={{ fontFamily: "Times New Roman, serif" }}>
                          <span style={{ color: "#595959" }}>
                            F. Internet or other similar network activity
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderBottom: "none",
                  borderLeft: "none",
                  borderRight: "1px solid black",
                  borderTop: "1px solid black",
                  width: " 448px",
                }}
              >
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ fontFamily: " Calibri, sans-serif" }}>
                      <span style={{ fontSize: "11.5pt" }}>
                        <span style={{ fontFamily: "Times New Roman, serif" }}>
                          <span style={{ color: "#595959" }}>
                            Browsing history, search history,
                            online&nbsp;behavior, interest data, and
                            interactions with our and other websites,
                            applications, systems, and advertisements
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderBottom: "none",
                  borderLeft: "none",
                  borderRight: "1px solid black",
                  borderTop: "1px solid black",
                  width: " 81px",
                }}
              >
                <p style={{ textAlign: "center" }}>&nbsp;</p>

                <p style={{ textAlign: "center" }}>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ fontFamily: " Calibri, sans-serif" }}>
                      <span style={{ fontSize: "11.5pt" }}>
                        <span style={{ fontFamily: "Times New Roman, serif" }}>
                          <span style={{ color: "#595959" }}>NO</span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderBottom: "none",
                  borderLeft: "1px solid black",
                  borderRight: "1px solid black",
                  borderTop: "1px solid black",
                  width: " 291px",
                }}
              >
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ fontFamily: " Calibri, sans-serif" }}>
                      <span style={{ fontSize: "11.5pt" }}>
                        <span style={{ fontFamily: "Times New Roman, serif" }}>
                          <span style={{ color: "#595959" }}>
                            G. Geolocation data
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderBottom: "none",
                  borderLeft: "none",
                  borderRight: "1px solid black",
                  borderTop: "1px solid black",
                  width: " 448px",
                }}
              >
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ fontFamily: " Calibri, sans-serif" }}>
                      <span style={{ fontSize: "11.5pt" }}>
                        <span style={{ fontFamily: "Times New Roman, serif" }}>
                          <span style={{ color: "#595959" }}>
                            Device location
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderBottom: "none",
                  borderLeft: "none",
                  borderRight: "1px solid black",
                  borderTop: "1px solid black",
                  width: " 81px",
                }}
              >
                <p style={{ textAlign: "center" }}>&nbsp;</p>

                <p style={{ textAlign: "center" }}>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ fontFamily: " Calibri, sans-serif" }}>
                      <span style={{ fontSize: "11.5pt" }}>
                        <span style={{ fontFamily: "Times New Roman, serif" }}>
                          <span style={{ color: "#595959" }}>NO</span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderBottom: "none",
                  borderLeft: "1px solid black",
                  borderRight: "1px solid black",
                  borderTop: "1px solid black",
                  width: " 291px",
                }}
              >
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ fontFamily: " Calibri, sans-serif" }}>
                      <span style={{ fontSize: "11.5pt" }}>
                        <span style={{ fontFamily: "Times New Roman, serif" }}>
                          <span style={{ color: "#595959" }}>
                            H. Audio, electronic, visual, thermal, olfactory, or
                            similar information
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderBottom: "none",
                  borderLeft: "none",
                  borderRight: "1px solid black",
                  borderTop: "1px solid black",
                  width: " 448px",
                }}
              >
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ fontFamily: " Calibri, sans-serif" }}>
                      <span style={{ fontSize: "11.5pt" }}>
                        <span style={{ fontFamily: "Times New Roman, serif" }}>
                          <span style={{ color: "#595959" }}>
                            Images and audio, video, or call recordings created
                            in connection with our business activities
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderBottom: "none",
                  borderLeft: "none",
                  borderRight: "1px solid black",
                  borderTop: "1px solid black",
                  width: " 81px",
                }}
              >
                <p style={{ textAlign: "center" }}>&nbsp;</p>

                <p style={{ textAlign: "center" }}>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ fontFamily: " Calibri, sans-serif" }}>
                      <span style={{ fontSize: "11.5pt" }}>
                        <span style={{ fontFamily: "Times New Roman, serif" }}>
                          <span style={{ color: "#595959" }}>NO</span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderBottom: "none",
                  borderLeft: "1px solid black",
                  borderRight: "1px solid black",
                  borderTop: "1px solid black",
                  width: "291px",
                }}
              >
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ fontFamily: " Calibri, sans-serif" }}>
                      <span style={{ fontSize: "11.5pt" }}>
                        <span style={{ fontFamily: "Times New Roman, serif" }}>
                          <span style={{ color: "#595959" }}>
                            I. Professional or employment-related information
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderBottom: "none",
                  borderLeft: "none",
                  borderRight: "1px solid black",
                  borderTop: "1px solid black",
                  width: "448px",
                }}
              >
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ fontFamily: " Calibri, sans-serif" }}>
                      <span style={{ fontSize: "11.5pt" }}>
                        <span style={{ fontFamily: "Times New Roman, serif" }}>
                          <span style={{ color: "#595959" }}>
                            Business contact details to provide you our Services
                            at a business level or job title, work history, and
                            professional qualifications if you apply for a job
                            with us
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderBottom: "none",
                  borderLeft: "none",
                  borderRight: "1px solid black",
                  borderTop: "1px solid black",
                  width: "81px",
                }}
              >
                <p style={{ textAlign: "center" }}>&nbsp;</p>

                <p style={{ textAlign: "center" }}>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ fontFamily: " Calibri, sans-serif" }}>
                      <span style={{ fontSize: "11.5pt" }}>
                        <span style={{ fontFamily: "Times New Roman, serif" }}>
                          <span style={{ color: "#595959" }}>NO</span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderBottom: "none",
                  borderLeft: "1px solid black",
                  borderRight: "1px solid black",
                  borderTop: "1px solid black",
                  width: "291px",
                }}
              >
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ fontFamily: " Calibri, sans-serif" }}>
                      <span style={{ fontSize: "11.5pt" }}>
                        <span style={{ fontFamily: "Times New Roman, serif" }}>
                          <span style={{ color: "#595959" }}>
                            J. Education Information
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderBottom: "1px solid black",
                  borderLeft: "none",
                  borderRight: "1px solid black",
                  borderTop: "1px solid black",
                  width: "448px",
                }}
              >
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ fontFamily: " Calibri, sans-serif" }}>
                      <span style={{ fontSize: "11.5pt" }}>
                        <span style={{ fontFamily: "Times New Roman, serif" }}>
                          <span style={{ color: "#595959" }}>
                            Student records and directory information
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderBottom: "1px solid black",
                  borderLeft: "none",
                  borderRight: "1px solid black",
                  borderTop: "1px solid black",
                  width: "81px",
                }}
              >
                <p style={{ textAlign: "center" }}>&nbsp;</p>

                <p style={{ textAlign: "center" }}>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ fontFamily: " Calibri, sans-serif" }}>
                      <span style={{ fontSize: "11.5pt" }}>
                        <span style={{ fontFamily: "Times New Roman, serif" }}>
                          <span style={{ color: "#595959" }}>NO</span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  borderBottom: "1px solid black",
                  borderLeft: "1px solid black",
                  borderRight: "1px solid black",
                  borderTop: "1px solid black",
                  width: "81px",
                }}
              >
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ fontFamily: " Calibri, sans-serif" }}>
                      <span style={{ fontSize: "11.5pt" }}>
                        <span style={{ fontFamily: "Times New Roman, serif" }}>
                          <span style={{ color: "#595959" }}>
                            K. Inferences are drawn from other personal
                            information
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderBottom: "1px solid black",
                  borderLeft: "none",
                  borderRight: "1px solid black",
                  borderTop: "1px solid black",
                  width: "81px",
                }}
              >
                <p>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ fontFamily: " Calibri, sans-serif" }}>
                      <span style={{ fontSize: "11.5pt" }}>
                        <span style={{ fontFamily: "Times New Roman, serif" }}>
                          <span style={{ color: "#595959" }}>
                            Inferences are drawn from any of the collected
                            personal information listed above to create a
                            profile or summary about, for example, an
                            individual&rsquo;s preferences and characteristics
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </td>
              <td
                style={{
                  borderBottom: "1px solid black",
                  borderLeft: "none",
                  borderRight: "1px solid black",
                  borderTop: "1px solid black",
                  width: "81px",
                }}
              >
                <p style={{ textAlign: "center" }}>&nbsp;</p>

                <p style={{ textAlign: "center" }}>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ fontFamily: " Calibri, sans-serif" }}>
                      <span style={{ fontSize: "11.5pt" }}>
                        <span style={{ fontFamily: "Times New Roman, serif" }}>
                          <span style={{ color: "#595959" }}>NO</span>
                        </span>
                      </span>
                    </span>
                  </span>
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <p>&nbsp;</p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "#595959" }}>
                    We may also collect other personal information outside of
                    these categories through instances where you interact with
                    us in person, online, or by phone or mail in the context of:
                  </span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <ul style={{ listStyleType: "square" }}>
          <li>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ color: "black" }}>
                <span style={{ fontFamily: " Calibri, sans-serif" }}>
                  <span style={{ fontSize: "11.5pt" }}>
                    <span style={{ fontFamily: "Times New Roman, serif" }}>
                      <span style={{ color: "#595959" }}>
                        Receiving help through our customer support channels;
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>

        <ul style={{ listStyleType: "square" }}>
          <li>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ color: "black" }}>
                <span style={{ fontFamily: " Calibri, sans-serif" }}>
                  <span style={{ fontSize: "11.5pt" }}>
                    <span style={{ fontFamily: "Times New Roman, serif" }}>
                      <span style={{ color: "#595959" }}>
                        Participation in customer surveys or contests; and
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>

        <ul style={{ listStyleType: "square" }}>
          <li>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ color: "black" }}>
                <span style={{ fontFamily: " Calibri, sans-serif" }}>
                  <span style={{ fontSize: "11.5pt" }}>
                    <span style={{ fontFamily: "Times New Roman, serif" }}>
                      <span style={{ color: "#595959" }}>
                        Facilitation in the delivery of our Services and
                        responding to your inquiries.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <strong>
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "#595959" }}>
                      How do we use and share your personal information?
                    </span>
                  </span>
                </span>
              </strong>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "#595959" }}>
                    More information about our data collection and sharing
                    practices can be found in this privacy notice.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "#595959" }}>
                    You may contact us&nbsp;by email
                    at&nbsp;info@rentersbook.com,&nbsp;
                  </span>
                </span>
              </span>
              <span style={{ fontSize: "13.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "black" }}>
                    or by referring to the contact details at the bottom of this
                    document.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "#595959" }}>
                    If you are using an&nbsp;authorized&nbsp;agent to exercise
                    your right to opt-out we may deny a request if
                    the&nbsp;authorized&nbsp;agent does not submit proof that
                    they have been validly&nbsp;authorized&nbsp;to act on your
                    behalf.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <strong>
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "#595959" }}>
                      Will your information be shared with anyone else?
                    </span>
                  </span>
                </span>
              </strong>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "#595959" }}>
                    We may disclose your personal information with our service
                    providers pursuant to a written contract between us and each
                    service provider. Each service provider is a for-profit
                    entity that processes the information on our behalf.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "#595959" }}>
                    We may use your personal information for our own business
                    purposes, such as for undertaking internal research for
                    technological development and demonstration. This is not
                    considered to be&nbsp;the &quot;selling&quot;&nbsp;of your
                    personal information.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "#595959" }}>
                    Renters Book, LLC&nbsp;has not disclosed or sold any
                    personal information to third parties for a business or
                    commercial purpose in the preceding twelve (12)
                    months.&nbsp;Renters Book, LLC&nbsp;will not sell personal
                    information in the future belonging to website visitors,
                    users, and other consumers.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <strong>
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "#595959" }}>
                      Your rights with respect to your personal data
                    </span>
                  </span>
                </span>
              </strong>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <u>
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "#595959" }}>
                      Right to request deletion of the data &mdash; Request to
                      delete
                    </span>
                  </span>
                </span>
              </u>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "#595959" }}>
                    You can ask for the deletion of your personal information.
                    If you ask us to delete your personal information, we will
                    respect your request and delete your personal information,
                    subject to certain exceptions provided by law, such as (but
                    not limited to) the exercise by another consumer of his or
                    her right to free speech, our compliance requirements
                    resulting from a legal obligation, or any processing that
                    may be required to protect against illegal activities.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <u>
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "#595959" }}>
                      Right to be informed &mdash; Request to know
                    </span>
                  </span>
                </span>
              </u>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "#595959" }}>
                    Depending on the circumstances, you have a right to know:
                  </span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <ul style={{ listStyleType: "square" }}>
          <li>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ color: "black" }}>
                <span style={{ fontFamily: " Calibri, sans-serif" }}>
                  <span style={{ fontSize: "11.5pt" }}>
                    <span style={{ fontFamily: "Times New Roman, serif" }}>
                      <span style={{ color: "#595959" }}>
                        whether we collect and use your personal information;
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>

        <ul style={{ listStyleType: "square" }}>
          <li>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ color: "black" }}>
                <span style={{ fontFamily: " Calibri, sans-serif" }}>
                  <span style={{ fontSize: "11.5pt" }}>
                    <span style={{ fontFamily: "Times New Roman, serif" }}>
                      <span style={{ color: "#595959" }}>
                        the categories of personal information that we collect;
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>

        <ul style={{ listStyleType: "square" }}>
          <li>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ color: "black" }}>
                <span style={{ fontFamily: " Calibri, sans-serif" }}>
                  <span style={{ fontSize: "11.5pt" }}>
                    <span style={{ fontFamily: "Times New Roman, serif" }}>
                      <span style={{ color: "#595959" }}>
                        the purposes for which the collected personal
                        information is used;
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>

        <ul style={{ listStyleType: "square" }}>
          <li>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ color: "black" }}>
                <span style={{ fontFamily: " Calibri, sans-serif" }}>
                  <span style={{ fontSize: "11.5pt" }}>
                    <span style={{ fontFamily: "Times New Roman, serif" }}>
                      <span style={{ color: "#595959" }}>
                        whether we sell your personal information to third
                        parties;
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>

        <ul style={{ listStyleType: "square" }}>
          <li>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ color: "black" }}>
                <span style={{ fontFamily: " Calibri, sans-serif" }}>
                  <span style={{ fontSize: "11.5pt" }}>
                    <span style={{ fontFamily: "Times New Roman, serif" }}>
                      <span style={{ color: "#595959" }}>
                        the categories of personal information that we sold or
                        disclosed for a business purpose;
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>

        <ul style={{ listStyleType: "square" }}>
          <li>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ color: "black" }}>
                <span style={{ fontFamily: " Calibri, sans-serif" }}>
                  <span style={{ fontSize: "11.5pt" }}>
                    <span style={{ fontFamily: "Times New Roman, serif" }}>
                      <span style={{ color: "#595959" }}>
                        the categories of third parties to whom the personal
                        information was sold or disclosed for a business
                        purpose; and
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>

        <ul style={{ listStyleType: "square" }}>
          <li>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ color: "black" }}>
                <span style={{ fontFamily: " Calibri, sans-serif" }}>
                  <span style={{ fontSize: "11.5pt" }}>
                    <span style={{ fontFamily: "Times New Roman, serif" }}>
                      <span style={{ color: "#595959" }}>
                        the business or commercial purpose for collecting or
                        selling personal information.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "#595959" }}>
                    In accordance with applicable law, we are not obligated to
                    provide or delete consumer information that is de-identified
                    in response to a consumer request or to re-identify
                    individual data to verify a consumer request.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <u>
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "#595959" }}>
                      Right to Non-Discrimination for the Exercise of a
                      Consumer&rsquo;s Privacy Rights
                    </span>
                  </span>
                </span>
              </u>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "#595959" }}>
                    We will not discriminate against you if you exercise your
                    privacy rights.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <u>
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "#595959" }}>
                      Verification process
                    </span>
                  </span>
                </span>
              </u>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "#595959" }}>
                    Upon receiving your request, we will need to verify your
                    identity to determine if you are the same person about whom
                    we have the information in our system. These verification
                    efforts require us to ask you to provide information so that
                    we can match it with the information you have previously
                    provided us. For instance, depending on the type of request
                    you submit, we may ask you to provide certain information so
                    that we can match the information you provide with the
                    information we already have on file, or we may contact you
                    through a communication method (e.g.,&nbsp;phone or email)
                    that you have previously provided to us. We may also use
                    other verification methods as the circumstances dictate.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "#595959" }}>
                    We will only use personal information provided in your
                    request to verify your identity or authority to make the
                    request. To the extent possible, we will avoid requesting
                    additional information from you for the purposes of
                    verification. However, if we cannot verify your identity
                    from the information already maintained by us, we may
                    request that you provide additional information for the
                    purposes of verifying your identity and for security or
                    fraud-prevention purposes. We will delete such additionally
                    provided information as soon as we finish verifying you.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <u>
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "#595959" }}>
                      Other privacy rights
                    </span>
                  </span>
                </span>
              </u>
            </span>
          </span>
        </p>

        <ul style={{ listStyleType: "square" }}>
          <li>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ color: "black" }}>
                <span style={{ fontFamily: " Calibri, sans-serif" }}>
                  <span style={{ fontSize: "11.5pt" }}>
                    <span style={{ fontFamily: "Times New Roman, serif" }}>
                      <span style={{ color: "#595959" }}>
                        You may object to the processing of your personal
                        information.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>

        <ul style={{ listStyleType: "square" }}>
          <li>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ color: "black" }}>
                <span style={{ fontFamily: " Calibri, sans-serif" }}>
                  <span style={{ fontSize: "11.5pt" }}>
                    <span style={{ fontFamily: "Times New Roman, serif" }}>
                      <span style={{ color: "#595959" }}>
                        You may request correction of your personal data if it
                        is incorrect or no longer relevant, or ask to restrict
                        the processing of the information.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>

        <ul style={{ listStyleType: "square" }}>
          <li>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ color: "black" }}>
                <span style={{ fontFamily: " Calibri, sans-serif" }}>
                  <span style={{ fontSize: "11.5pt" }}>
                    <span style={{ fontFamily: "Times New Roman, serif" }}>
                      <span style={{ color: "#595959" }}>
                        You can designate an&nbsp;authorized&nbsp;agent to make
                        a request under the CCPA on your behalf. We may deny a
                        request from an&nbsp;authorized&nbsp;agent that does not
                        submit proof that they have been
                        validly&nbsp;authorized&nbsp;to act on your behalf in
                        accordance with the CCPA.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>

        <ul style={{ listStyleType: "square" }}>
          <li>
            <span style={{ fontSize: "11pt" }}>
              <span style={{ color: "black" }}>
                <span style={{ fontFamily: " Calibri, sans-serif" }}>
                  <span style={{ fontSize: "11.5pt" }}>
                    <span style={{ fontFamily: "Times New Roman, serif" }}>
                      <span style={{ color: "#595959" }}>
                        You may request to opt-out from future selling of your
                        personal information to third parties. Upon receiving an
                        opt-out request, we will act upon the request as soon as
                        feasibly possible, but no later than fifteen (15) days
                        from the date of the request submission.
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </li>
        </ul>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "#595959" }}>
                    To exercise these rights, you can contact us&nbsp;by email
                    at&nbsp;info@rentersbook.com,&nbsp;
                  </span>
                </span>
              </span>
              <span style={{ fontSize: "13.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "black" }}>
                    or by referring to the contact details at the bottom of this
                    document. If you have a complaint about how we handle your
                    data, we would like to hear from you.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <strong>
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "black" }}>
                      11. DO WE MAKE UPDATES TO THIS NOTICE?
                    </span>
                  </span>
                </span>
              </strong>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <strong>
                <em>
                  <span style={{ fontSize: "11.5pt" }}>
                    <span style={{ fontFamily: "Times New Roman, serif" }}>
                      <span style={{ color: "#595959" }}>In Short:&nbsp;</span>
                    </span>
                  </span>
                </em>
              </strong>
              <em>
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "#595959" }}>
                      Yes, we will update this notice as necessary to stay
                      compliant with relevant laws.
                    </span>
                  </span>
                </span>
              </em>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "#595959" }}>
                    We may update this privacy notice from time to time. The
                    updated version will be indicated by an
                    updated&nbsp;&quot;Revised&quot;&nbsp;date and the updated
                    version will be effective as soon as it is accessible. If we
                    make material changes to this privacy notice, we may notify
                    you either by prominently posting a notice of such changes
                    or by directly sending you a notification. We encourage you
                    to review this privacy notice frequently to be informed of
                    how we are protecting your information.
                  </span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <strong>
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "black" }}>
                      12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                    </span>
                  </span>
                </span>
              </strong>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "#595959" }}>
                    If you have questions or comments about this notice, you
                    may&nbsp;email us at&nbsp;info@rentersbook.com&nbsp;or by
                    post to:
                  </span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "#595959" }}>Renters Book, LLC</span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "black" }}>__________</span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "black" }}>Midvale Ave.</span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "black" }}>Philadelphia</span>
                </span>
              </span>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "#595959" }}>,&nbsp;PA&nbsp;19144</span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "black" }}>United States</span>
                </span>
              </span>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <strong>
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "black" }}>
                      13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE
                      COLLECT FROM YOU?
                    </span>
                  </span>
                </span>
              </strong>
            </span>
          </span>
        </p>

        <p>
          <span style={{ fontSize: "11pt" }}>
            <span style={{ fontFamily: " Calibri, sans-serif" }}>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "#595959" }}>
                    Based on the applicable laws of your country, you may have
                    the right to request access to the personal information we
                    collect from you, change that information, or delete
                    it.&nbsp;To request to review, update, or delete your
                    personal information, please&nbsp;visit&nbsp;
                  </span>
                </span>
              </span>
              <u>
                <span style={{ fontSize: "11.5pt" }}>
                  <span style={{ fontFamily: "Times New Roman, serif" }}>
                    <span style={{ color: "blue" }}>
                      <a
                        href="https://www.rentersbook.com"
                        style={{ color: "blue", textDecoration: "underline" }}
                      >
                        https://www.rentersbook.com
                      </a>
                    </span>
                  </span>
                </span>
              </u>
              <span style={{ fontSize: "11.5pt" }}>
                <span style={{ fontFamily: "Times New Roman, serif" }}>
                  <span style={{ color: "#595959" }}>.</span>
                </span>
              </span>
            </span>
          </span>
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
