import React, { ChangeEvent } from "react";
import {
  UseFormRegister,
  FieldErrors,
  FieldValues,
  Control,
  Controller,
} from "react-hook-form";
import { useTranslation } from "react-i18next";

import CommonErrorText from "./CommonErrorText";

interface IOptions {
  id?: string | number;
  name?: string;
  value?: string | number;
  key?: string;
}

interface Iprops {
  option: IOptions[];
  errors?: FieldErrors<FieldValues>;
  register?: UseFormRegister<FieldValues>;
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;

  name: string;
  className: string;
  firstOption?: string;
  defaultValue?:
    | {
        name: string;
        id: string;
      }
    | string;
  control?: Control;
  disabled?: boolean;
  label?: string;
  required?: boolean;
  dontShowDefaultFirstOption?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const CommonSelect: React.FC<Iprops> = (props) => {
  const {
    option,
    errors,
    className = "",
    register,
    onChange,
    name,
    defaultValue = "",
    control,
    firstOption,
    disabled,
    label,
    required,
    // isOptionDisabled = false,
    dontShowDefaultFirstOption = false,
  } = props;

  const { t } = useTranslation();

  return (
    <>
      {label && (
        <label htmlFor={name} className="font14">
          {label}
          {required ? <sup className="colorRed">*</sup> : null}
        </label>
      )}
      {register ? (
        <select
          className={`form-select me-3 ${className || ""}`}
          aria-label=".form-select-sm example"
          {...register(name)}
          disabled={disabled}
          required={required}
          defaultValue=""
        >
          <option value="" selected disabled>
            {firstOption ? firstOption : t("default_option")}
          </option>
          {option?.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name ? item.name : item.value}
            </option>
          ))}
        </select>
      ) : (
        <Controller
          render={({ field }) => (
            <select
              {...field}
              className={`form-control me-3 m-0 ${className || ""}`}
              disabled={disabled}
              onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                field.onChange(e);
                if (onChange) {
                  onChange(e);
                }
              }}
            >
              {!dontShowDefaultFirstOption ? (
                <option value="" selected disabled>
                  {firstOption ? firstOption : t("default_option")}
                </option>
              ) : null}
              {option?.map((item) => (
                <option key={item.key} value={item.key}>
                  {item.value ?? item.value}
                </option>
              ))}
            </select>
          )}
          name={name}
          control={control}
          defaultValue={defaultValue || ""}
        />
      )}

      {/* Common Error message component  */}
      <CommonErrorText errors={errors} />
    </>
  );
};

export default CommonSelect;
