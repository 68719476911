import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers/Index";

import { checkCurrentPlan } from "../../service/susbcription";
import { IPrevData } from "../../interfaces/commonInterfaces";
import toastMesage from "../../components/commonFormGroup/CommonToastMessage";

import ROUTES from "../../constant/routes";
import SubscriptionIcon from "../../assets/images/subscription.png";
import CommonSVGFiles from "../../components/commonFormGroup/CommonSVGFiles";
import CommonPopup from "../../components/commonPopup/CommonPopup";

interface IProps {
  plan: string;
  planId:string;
  paypalPlanId:string;
  recurrence: string;
  amount: number;
  prevData?: IPrevData;
  subscription:boolean;
}

const Plan: React.FC<IProps> = ({ plan, recurrence, amount, prevData,planId,paypalPlanId ,subscription=true}) => {
  const { t: translation } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [showMethodModal, setshowMethodModal] = useState(false);

  const navigate = useNavigate();

  const { customer_id } = useSelector(
    (state: RootState) => state?.AuthReducer?.authData
  );

  const gettingCurrPlan = async () => {
    try {
      setLoading(true);
      if (customer_id && plan && amount>0) {
        // FIND EXIST PLAN OF USER
        const {data} = await checkCurrentPlan({
          customerId: customer_id,
          planName: plan,
        });

        if (data && data.success) {
          // Navigate To Checkout Form
          setshowMethodModal(true);
        } else {
          toast.dismiss();
          toastMesage(translation(data?.message),"info");
        }
        setLoading(false);
      } else {
        setshowMethodModal(true);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const getMethodHandler=(method:"stripe"|"paypal")=>{
    navigate(ROUTES.CHECKOUT.replace(":method",method), {
      state: {
        plan,
        amount,
        prevData,
        planId,
        paypalPlanId,
        subscription
      },
    });
  };

  return (
    <div
      className="mx-auto card text-dark bg-light mb-3 border border-primary plan-card"
      style={{ maxWidth: "18rem" }}
    >
      <div className="card-header bg-primary text-white text-center">
        <h6 className="p-1 mb-0"> {translation(plan)}</h6>
      </div>
      <div className="card-body text-center p-2">
        <img src={SubscriptionIcon} alt="icon" className="subscription-icon" />
        <h6 className="my-2">
          <b>$ {amount}</b>/{translation(recurrence)}
        </h6>

        <h6 className="card-title">{translation("reviews")} </h6>
        <h6 className="card-title">{translation("rate_tenant")}</h6>
        <h6 className="card-title">{translation("rate_landlord")} </h6>
      </div>
      <div className="card-footer text-center">
        <button
          className="main-button primary-btn border-radius col-11 my-1 font14 "
          type="button"
          disabled={loading}
          onClick={() => gettingCurrPlan()}
        >
          {translation("subscribe_now")}
          {loading ? (
            <span className="spinner-border spinner-border-sm me-2 ms-2" />
          ) : (
            <CommonSVGFiles svgName="arrowForward" />
          )}
        </button>
      </div>
      {showMethodModal && (
        <CommonPopup
          from="paymentMethod"
          headerMessage={translation("payment_method")}
          clickedButton={() => setshowMethodModal(false)}
          getMethod={getMethodHandler}
        />
      )}
    </div>

  );
};

export default Plan;
