const ROUTES = {
  auth: {
    LOGIN: "/auth/login",
    SOCIAL_LOGIN: "/auth/social-login",
    SIGNUP: "/auth/signup",
    FORGOT_PASSWORD: "/auth/send-otp",
    VERIFY_OTP: "/auth/otp-verification",
    RESET_PASSWORD: "/auth/reset-password",
    MATCHTOKEN: "/auth/match-token",
    GET_USER: "/auth/get-user",
    // ACTIVATE_DEACTIVATE_USER: "/auth/status/update",
  },

  user: {
    ADD_LANDLORD: "/landlord/add-landlord",
    PROFILE: "/user/user-profile/:id",
    DETAILS: "/user/user-details/:id",
    UPDATE_PASSWORD: "/user/user-profile/update-password/:id",
    UPDATE_PROFILE: "user/user-profile/update-profile",
    UPDATE_PROPERTY: "/user/user-profile/update-property/:id",
    SEARCH_USER_NAME: "/user/search-user-name",
    GET_REVIEW_AND_RATING: "/user/review-and-rating",
    CONTACT_US: "/user/contact-us",
    DELETE_PROPERTY: "/user/delete-user-property",
    ADD_USER_PROPERTY: "/user/add-user-property",
    HOMEPAGE_CONTENT: "/user/homepage-content",
    ABOUT_US_CONTENT: "/user/about-us-content",
    HOMEPAGE_ADVERTISEMENT: "/user/homepage-advertisement",
    UPDATE_VISITED_COUNT: "/user/update-visited-count",
    UPDATE_REVIEW_TERM_CONDITION: "/user/update-review-term-condition",
    GET_VISITED_COUNT: "/user/get-visited-count",
    GET_PLAN_LIST: "/user/plan-list",
    GET_CUSTOM_REVIEW_PLAN: "/user/custom-review-plan",
  },

  google: {
    SEARCH_PREDICTION: "/google/get-predictions",
    PLACES_DETAIL: "/google/get-places-details",
    PLACE_DETAILS: "/google/location-details",
    ADD_TENANT: "/tenant/add-tenant",
  },
  property: {
    SEARCH_ADDRESS: "/landlord/search-property-address",
  },
  lanlord: {
    GET_LANDLORD: "/landlord/search-landlord-name",
    GET_ADDRESS_BY_PROPERTY_TYPE: "/landlord/search-address-by-property-type",
    RATE_LANDLORD: "/landlord/rate-landlord",
    CHECK_LANDLORD_PROPERTY: "/landlord/get-landlord-property-data",
    CLAIMED_PROPERTY: "landlord/claimed-property",
  },

  tenant: {
    ADD_TENANT: "/tenant/add-tenant",
    GET_TENANT_NAME: "tenant/get-tenant",
    GET_TENANT_LATEST_ADDRESS: "tenant/get-tenant-latest-address-from-review",
    RATE_TENANT: "/tenant/rate-tenant",
    GET_PRESIGNED_URL: "/tenant/pre-signed-url",
  },
  review: {
    GET_SEARCH_RESULT: "/review/get-search-filter-result",
    GET_REVIEW_DATA: "/review/get-review-for-user",
    REPORT_REVIEW: "/review/report-review",
    CHECK_EXIST_REVIEWS: "review/check-reviews",
  },
  susbcription: {
    CHECK_CURRENT_PLAN: "/subscription/get-current-plan",
    CREATE_SUBSCRIPTION: "/subscription/create-subscription",
    CREATE_CUSTOM_REVIEW_PAYMENT: "/subscription/create-custom-review-payment",
    SUBSCRIPTION_DETAILS: "/subscription/subscription-details",
    CANCEL_SUBSCRIPTION: "/subscription/cancel-subscription",
    GET_INVOICE: "/subscription/invoice",
  },
  paypal:{
    CREATE_SUSBCRIPTION:"/paypal/create-subscription",
    CANCEL_SUSBCRIPTION:"/paypal/cancel-subscription",
    SINGLE_CHARGE:"/paypal/single-charge",
    GET_CUSTOME_REVIEW_PLAN:"/paypal/custome-review-plan",
    GET_PAYPAL_INVOICE:"/paypal/invoice/:id"
  }
};

export default ROUTES;
