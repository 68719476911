import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import moment from "moment";
import toastMesage  from "../../components/commonFormGroup/CommonToastMessage";
import {
  IPlanList,
  ISubscriptionDetails,
} from "../../interfaces/commonInterfaces";
import { RootState } from "../../redux/reducers/Index";
import {
  cancelCurrentActivePlanService,
  getInvoiceService,
  getCurrentActivePlanService,
} from "../../service/susbcription";
import CommonPopup from "../../components/commonPopup/CommonPopup";
// import useOnClickOutside from "../../utils/useOutSideAlert";
import Plan from "../subscriptionPlan/Plan";
import CurrentPlan from "./CurrentPlan";
import {  currentTimeStamp, SUBSCRIPTION_STATUS, SUBSDETAILS } from "../../constant/commonConstant";
import { getPlanList } from "../../service/user";
import { cancelPaypalSusbcription} from "../../service/paypal";
import "./Subscription.scss";

const SubscriptionDetails: React.FC = () => {
  const { t: translation } = useTranslation();
  const [currentPlanLoading, setCurrPlanLoading] = useState(false);
  const [cancelSubsloading, setCancelSubsLoading] = useState(false);
  const [planListLoading, setPlanListLoading] = useState(false);
  const [showSubsCancelModal, setShowSubsCancelModal] = useState(false);
  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const [tab, setTab] = useState(false);
  const [planList, setPlanList] = useState<IPlanList[]>();
  const [details, setDetails] = useState<ISubscriptionDetails>();
  const authData = useSelector(
    (state: RootState) => state?.AuthReducer?.authData
  );

  const parentDivRef = useRef(null);

  // useOnClickOutside(parentDivRef, () => setShowSubsCancelModal(false));
  /*
    getting Subscription Details
  */

  const gettingCurrentPlan = async () => {
    try {
      setCurrPlanLoading(true);
      if (authData.id) {
        const {data} = await getCurrentActivePlanService(authData.id);
        if ( data && data.success&& data.data) {
          setDetails(data.data);
        }
      }
      setCurrPlanLoading(false);
    } catch (error) {
      console.error(error);
    }
    setCurrPlanLoading(false);
  };
  /**
   *  get plan list
   */
  const gettingPlanList = async () => {
    try {
      setPlanListLoading(true);
      const {data} = await getPlanList();
      if (data  &&data.success&& data.data) {
        setPlanList(data.data);
      }
      setPlanListLoading(false);
    } catch (error) {
      console.error(error);
    }
    setPlanListLoading(false);
  };

  /*
   Cancel Subscription
  */

  const cancelSubscription = async () => {
    try {
      setCancelSubsLoading(true);
      if (authData.id) {
        if(details && details.subscription_id.includes("sub") && details.customer_id.includes("cus")){
          const {data} = await cancelCurrentActivePlanService(authData.id);
          if ( data && data.success && data.data) {
            gettingCurrentPlan();
            toastMesage(
              translation(data?.message),"success"
            );
          } else toastMesage(translation(data?.message),"error");
        }else{
            // FOR PAYPAL 
              const  {data}=await cancelPaypalSusbcription({subscriptionId :details?.subscription_id})
              if(data && data.success){
                gettingCurrentPlan();
                toastMesage(translation(data?.message),"success")
              }else{
                toastMesage(translation(data?.message),"error");
              }
        }
      }
      setCancelSubsLoading(false);
    } catch (error) {
      console.error(error);
    }
    setCancelSubsLoading(false);
  };

  /*
     Generate Invoice
  */

  const getInvoice = async () => {
    try {
      if (authData.id) {
        setInvoiceLoading(true);
        const {data}=await getInvoiceService(authData.id);
        if (data && data.success && data.data) {
          const strWindowFeatures =
            "location=yes,height=850,width=800,scrollbars=yes,status=yes";
          window
         ?.open(data?.data, "_blank", strWindowFeatures)
         ?.focus();
        }
   
        setInvoiceLoading(false);
      }
    } catch (error) {
      console.error(error)
    }
    setInvoiceLoading(false);
  };

  const handleCallbackForCommonPopup = async (arg: string) => {
    if (arg === "YES") {
      cancelSubscription();
      setShowSubsCancelModal(false);
    } else {
      setShowSubsCancelModal(false);
    }
  };

  useEffect(() => {
    if (authData && authData.id) {
      gettingPlanList();
      gettingCurrentPlan();
    }
  }, []);

  return (
    <div className="subscription-page">
      <div className="container" ref={parentDivRef}>
        <div className="plan-choose-tab">
          {/* CURRENT PLAN  */}
          <button
            className={`main-button  ${tab ? "white-btn" : "primary-btn"}`}
            type="button"
            onClick={() => setTab(false)}
          >
            {translation("current_plan")}
          </button>
          {/* SUBSCRIPTION PLAN LIST */}
          <button
            className={`main-button ${tab ? "primary-btn" : "white-btn"}`}
            type="button"
            onClick={() => setTab(true)}
          >
            {translation("available_plans")}
          </button>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              {/* {details &&
                  !moment
                    .unix(details.end_at)
                    .isSameOrAfter(currentTimeStamp) && (
                    <h5 className="text-center">
                      {translation("your_plan_has_been_expired")}{" "}
                    </h5>
                  )} */}
              {tab &&
                planList &&
                planList.length > 0 ?
                planList.map((it, index: number) => {
                  return (
                    <div className="col-lg-3 col-md-6 " key={index}>
                      <Plan
                        plan={it?.title}
                        recurrence={it?.recurrence}
                        amount={it.amount}
                        planId={it.plan_id}
                        paypalPlanId={it.paypal_plan_id}
                        prevData={{
                          prevPath: SUBSDETAILS,
                        }}
                        subscription
                      />
                    </div>
                  );
                }):!planListLoading && tab &&
                <div className="text-center">
                <strong>{translation("unable_to_fetch_plan_list")} </strong>
              </div>
              }

              {!tab &&
              details &&
              details.status === SUBSCRIPTION_STATUS.ACTIVE 
              && moment.unix(details.end_at).isAfter(currentTimeStamp)
               ? (
                <CurrentPlan
                  details={details}
                  loading={cancelSubsloading || currentPlanLoading}
                  setShowSubsCancelModal={setShowSubsCancelModal}
                  getInvoice={getInvoice}
                  invoiceLoading={invoiceLoading}
                />
              ) : currentPlanLoading || planListLoading ? (
                <div className="text-center">
                  <span className="spinner-border spinner-border-sm me-2 ms-2" />
                </div>
              ) 
              : (
                !tab && (
                  <div className="text-center">
                    <strong>{translation("no_subscription_plan")} </strong>
                  </div>
                )
              )
              }
            </div>
          </div>
        </div>

        <ToastContainer />
      </div>
      {showSubsCancelModal && (
        <CommonPopup
          headerMessage={translation(
            "are_you_sure_you_want_to_cancel_subscription_renewal"
          )}
          popupType="confirm"
          clickedButton={handleCallbackForCommonPopup}
        />
      )}
    </div>
  );
};

export default SubscriptionDetails;
