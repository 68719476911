import { LegacyRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { ISubscriptionDetails } from '../../interfaces/commonInterfaces';
import { RootState } from '../../redux/reducers/Index';





interface IProps {
   details:ISubscriptionDetails;
   printRef:LegacyRef<HTMLDivElement>|undefined;
}
  
  const PdfDoc =
    ({details,printRef}: IProps, ) => {
     const { t: translation } = useTranslation();
     const authData = useSelector(
      (state: RootState) => state?.AuthReducer?.authData
    );
      
 
        return (
          <div ref={printRef} style={{ padding: 10 }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h4 style={{ fontWeight: 700, fontSize: 20, margin: 0 }}>
                 {translation("invoice")}
              </h4>
              <h3 style={{ color: "#808080", margin: 0, fontSize: 22 }}>
                {details?.subscription_id}
              </h3>
            </div>
            <div style={{ margin: 25 }}>
              <p style={{ margin: 0 }}>
                <strong
                  style={{
                    minWidth: 110,
                    display: "inline-block",
                    marginRight: 10,
                  }}
                >
                   {translation("invoice_number")}
                </strong>
                : {details?.subscription_id}
              </p>
              <p style={{ margin: 0 }}>
                <strong
                  style={{
                    minWidth: 110,
                    display: "inline-block",
                    marginRight: 10,
                  }}
                >
                  {translation("date_of_issue")}
                </strong>
                :  {moment.unix(details?.last_payment).format("MMM Do YYYY")}
              </p>
              <p style={{ margin: 0 }}>
                <strong
                  style={{
                    minWidth: 110,
                    display: "inline-block",
                    marginRight: 10,
                  }}
                >
                 {translation("date_due")}
                </strong>
                : {details && details.next_payment ? moment.unix(details?.next_payment).format("MMM Do YYYY"):"-"}
              </p>
            </div>

            <div style={{ display: "flex" }}>
              <p style={{ margin: 0, width: "40%" }}> {" "}</p>
              <div>
                <p style={{ margin: 0 }}>
                  <strong>{translation("bill_to")}</strong>
                </p>
                <p style={{ margin: 0 }}>{authData?.first_name} {authData?.last_name} </p>
                <p style={{ margin: 0 }}>{authData?.email}</p>
              </div>
            </div>
{/* 
            <h5 style={{ fontSize: 18, marginBottom: 0 }}>
              $4.99 USD due July 30, 2024
            </h5>
            <p
              style={{
                marginTop: 0,
                textDecoration: "underline",
                color: "#675dfa",
                cursor: "pointer",
              }}
            >
              Pay online
            </p> */}

            <table style={{ width: "100%", borderCollapse: "collapse" }}>
              <thead>
                <tr>
                  <th
                    align="left"
                    style={{ borderBottom: "1px solid #000", padding: 5 }}
                  >
                   {translation("description")}
                  </th>
                  <th
                    align="center"
                    style={{ borderBottom: "1px solid #000", padding: 5 }}
                  >
                    {translation("qty")}
                  </th>
                  <th
                    align="center"
                    style={{ borderBottom: "1px solid #000", padding: 5 }}
                  >
                   {translation("unit_price")}
                  </th>
                  <th
                    align="center"
                    style={{ borderBottom: "1px solid #000", padding: 5 }}
                  >
                    {translation("amount")}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td align="left" style={{ padding: 5 }}>
                    <p style={{ margin: 0 }}>{translation(details?.title)}</p>
                    <p style={{ margin: 0 }}>  {moment.unix(details.last_payment).format("MMM Do YYYY")} -
                    {moment.unix(details.next_payment).format("MMM Do YYYY")}
                    </p>
                  </td>
                  <td align="center" style={{ padding: 5 }}>
                    1
                  </td>
                  <td align="center" style={{ padding: 5 }}>
                  ${details?.amount}
                  </td>
                  <td align="center" style={{ padding: 5 }}>
                   ${details?.amount}
                  </td>
                </tr>
              </tbody>
            </table>

            <ul
              style={{
                listStyle: "none",
                padding: 0,
                width: "50%",
                margin: "20px 0 0 auto",
              }}
            >
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderTop: "1px solid #ddd",
                }}
              >
                <p style={{ margin: 5 }}>{translation("subtotal")} </p>
                <p style={{ margin: 5 }}>${details?.amount}</p>
              </li>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderTop: "1px solid #ddd",
                }}
              >
                <p style={{ margin: 5 }}>{translation("total")}</p>
                <p style={{ margin: 5 }}>${details?.amount}</p>
              </li>
              <li
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderTop: "1px solid #ddd",
                }}
              >
                <p style={{ margin: 5 }}>
                  <strong>{translation("amount")}</strong>
                </p>
                <p style={{ margin: 5 }}>
                  <strong>${details?.amount}</strong>
                </p>
              </li>
            </ul>
          </div>
        );
    
    }
export default PdfDoc;