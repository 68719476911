import * as http from "../utils/http";
import endpoint from "../constant/endpoints";
import ApiResponse from "../resources/entity/IApiResponse";
import {
  ICheckCurrentPlan,
  ICreateSubscription,
  IRateLoandlordService,
  IRateTenantService,
} from "../interfaces/commonInterfaces";

/**
 *  Create Subscription
 * @param data 
 * @returns 
 */
export const createSubscription = (
  data: ICreateSubscription
): Promise<ApiResponse> => {
  return http.post(`${endpoint.susbcription.CREATE_SUBSCRIPTION}`, data);
};

/**
 *  Check Current Plan
 * @param data 
 * @returns 
 */
export const checkCurrentPlan = (
  data: ICheckCurrentPlan
): Promise<ApiResponse> => {
  return http.post(`${endpoint.susbcription.CHECK_CURRENT_PLAN}`, data);
};

/**
 * Get Subscription details
 * @param id 
 * @returns 
 */
export const getCurrentActivePlanService = (
  id: number
): Promise<ApiResponse> => {
  return http.get(`${endpoint.susbcription.SUBSCRIPTION_DETAILS}?id=${id}`);
};

/**
 * Cancel Subscription
 * @param id 
 * @returns 
 */
export const cancelCurrentActivePlanService = (id: number): Promise<ApiResponse> => {
  return http.get(`${endpoint.susbcription.CANCEL_SUBSCRIPTION}?id=${id}`);
};

/**
 *  Get Invoice
 * @param id 
 * @returns 
 */
export const getInvoiceService = (id: number): Promise<ApiResponse> => {
  return http.get(`${endpoint.susbcription.GET_INVOICE}?id=${id}`);
};

/**
 * Custome Review Payment
 * @param data 
 * @param rateData 
 * @returns 
 */
export const createCustomReviewPayment = (
  data: ICreateSubscription,
  rateData: IRateTenantService | IRateLoandlordService
): Promise<ApiResponse> => {
  return http.post(`${endpoint.susbcription.CREATE_CUSTOM_REVIEW_PAYMENT}`, {
    data,
    rateData,
  });
};
