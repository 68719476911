import { combineReducers } from "redux";
import ActionType from "../../resources/enums";
import AuthReducer from "./AuthReducer";

const appReducer = combineReducers({
  AuthReducer,
});

let rootReducer = null;

export default rootReducer = (state: any, action: { type: ActionType }) => {
  let tmp = state;
  if (action.type === ActionType.LOGOUT) {
    tmp = undefined;
  }

  return appReducer(tmp, action);
};

export type RootState = ReturnType<typeof rootReducer>;
