import * as http from "../utils/http";
import endpoint from "../constant/endpoints";
import ApiResponse from "../resources/entity/IApiResponse";
import { IMatchToken } from "../interfaces/commonInterfaces";
import {
  ISignUp,
  ILoginData,
  IForgetPassword,
  IVerifyOtpParams,
  IResetPassword,
  ISocialLogin,
} from "../interfaces/authInterface";

export const matchToken = (data: IMatchToken): Promise<ApiResponse> =>
  http.post(`${endpoint.auth.MATCHTOKEN}`, data);

export const signUpService = (data: ISignUp): Promise<ApiResponse> => {
  return http.post(endpoint.auth.SIGNUP, data);
};

export const loginService = (data: ILoginData): Promise<ApiResponse> => {
  return http.post(endpoint.auth.LOGIN, data);
};

export const socialLoginService = (
  data: ISocialLogin
): Promise<ApiResponse> => {
  return http.post(endpoint.auth.SOCIAL_LOGIN, data);
};

export const forgotPasswordService = (
  data: IForgetPassword
): Promise<ApiResponse> => {
  return http.post(endpoint.auth.FORGOT_PASSWORD, data);
};

export const verifyOtpService = (
  data: IVerifyOtpParams
): Promise<ApiResponse> => {
  return http.post(endpoint.auth.VERIFY_OTP, data);
};

export const resetPasswordService = (
  data: IResetPassword
): Promise<ApiResponse> => http.patch(endpoint.auth.RESET_PASSWORD, data);

export const getUserService = (id:string): Promise<ApiResponse> => 
  http.get(endpoint.auth.GET_USER.concat(`/${id}`));

