import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import { IAboutUsContent } from "../../interfaces/commonInterfaces";
import { getAboutUsContent } from "../../service/user";
import AboutImg from "../../assets/images/about.jpeg";
import { ACTIVE_INACTIVE_STATUS } from "../../constant/commonConstant";

const About: FC = () => {
  const [data, setData] = useState<IAboutUsContent>();
  const { t: translation } = useTranslation();
  const [loading, setLoading] = useState(false);
  const getLandingPageContent = async () => {
    setLoading(true);
    try {
      const response = await getAboutUsContent();
      if (response && response.data && response.data.data) {
        setData(response.data.data[0]);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getLandingPageContent();
  }, []);

  return (
    <div className="about-main">
      <div className="container">
        <div className="text-center my-5">
          <h3> {translation("about")} </h3>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div>
              {data && data.status === ACTIVE_INACTIVE_STATUS.ACTIVE && data.about_us_text ? (
                <p>{parse(`${data.about_us_text}`)} </p>
              ) : loading ? (
                <span className="spinner-border spinner-border-sm me-2 ms-2" />
              ) : (
                <p> {translation("unable_to_load_data")} </p>
              )}
            </div>
          </div>

          <div className="col-md-6">
            <div className="about-banner-img">
              <img src={AboutImg} alt="about" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
