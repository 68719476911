import React, { useState } from "react";
import { useSelector } from 'react-redux';
import { useNavigate} from "react-router-dom";
import { PayPalScriptProvider, PayPalButtons,usePayPalScriptReducer  } from "@paypal/react-paypal-js";
import { useTranslation } from 'react-i18next';
import toastMesage from '../../components/commonFormGroup/CommonToastMessage';
import { RootState } from '../../redux/reducers/Index';
import { createPaypalSingleCharge, createPaypalSusbcription,} from '../../service/paypal';
import {
  ADD_LANDLORD,
  ADD_PROPERTY,
  ADD_TENANT,
  PROFILE_PAGE,
  RATE_LANDLORD,
  RATE_TENANT,
  SUBSDETAILS,
} from "../../constant/commonConstant";
import { IPrevData } from "../../interfaces/commonInterfaces";
import ROUTES from "../../constant/routes";
import CommonPopup from "../../components/commonPopup/CommonPopup";



interface IProps{
  paypalPlanId:string;
  amount:number;
  paymentType:"singleCharge"|"subscription";
  prevData:IPrevData;
}


const SingleChargeButtons = ({ amount,email,prevData}:
  {amount:number,email:string,prevData:IPrevData}) => {
  const [openModal,setOpenModal]=useState(false);  
  const { t: translation } = useTranslation();
  const navigate = useNavigate();
  const [{ isPending }] = usePayPalScriptReducer();
 
   if(isPending){
    return <span className="spinner-border spinner-border-sm me-2 ms-2 p-3" />
   }
   const handleCallbackForCommonPopup = async (buttonType: string) => {
    if (buttonType === "NO") {
      setOpenModal(false);
      navigate(ROUTES.HOME);
    }
  };
  return (
    <div>
      {!openModal ? (
        <PayPalScriptProvider
          options={{
            clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID as string,
            components: "buttons",
          }}
        >
          <PayPalButtons
            style={{
              layout: "horizontal",
              height: 44,
              label: "pay",
            }}
            onError={(err) => {
              console.error("Error in single charge", err);
            }}
            forceReRender={[amount]}
            createOrder={(_data, actions) => {
              const res = actions.order.create({
                purchase_units: [
                  {
                    amount: {
                      currency_code: "USD",
                      value: `${amount}`,
                    },
                    custom_id: email,
                  },
                ],
                intent: "CAPTURE",
              });
              return res;
            }}
            onApprove={async (data, actions) => {
              if (!actions.order) {
                return toastMesage(translation("something_went_wrong"),"error");
              }
              const res = await actions.order.capture();
              const { data: result } = await createPaypalSingleCharge({
                ...res,
                ...data,
                prevData,
              });
              if (result && result.success) {
                setOpenModal(true);
              } else {
                toastMesage(translation(result?.message),"error");
              }
            }}
          />
        </PayPalScriptProvider>
      ) : (
        <CommonPopup
          from={RATE_LANDLORD||RATE_TENANT}
          headerMessage={translation("confirm_submission")}
          popupType="confirm"
          clickedButton={handleCallbackForCommonPopup}
        />
      )}
    </div>
  );
};


      
const SubscriptionButtons = ({ email,prevData,paypalPlanId,userId}:
  {email:string;prevData:IPrevData;paypalPlanId:string;userId:number}) => {
  const { t: translation } = useTranslation();
  const navigate = useNavigate();
  const [{ isPending }] = usePayPalScriptReducer();
  if(isPending){
   return <span className="spinner-border spinner-border-sm me-2 ms-2 p-3" />
  }
  return (
    <PayPalScriptProvider
      options={{
        clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID as string,
        components: "buttons",
        intent: "subscription",
        vault: true,
      }}
    >
      <PayPalButtons
        createSubscription={(_data, actions) => {
          return actions.subscription
            .create({
              plan_id: paypalPlanId,
              custom_id:email
            })
            .then((orderId) => {
              return orderId;
            });
  

        }}
        style={{
          layout: "horizontal",
          label: "subscribe",
          height: 44,
        }}
        onError={(err)=>{
          console.error("Error in subscription",err)
        }}
        onApprove={async (data) => {
          // if (!actions.order) {
          //   return toastMesageError(translation("something_went_wrong"));
          // }
   
          if(data.subscriptionID){
            const {data:res}= await createPaypalSusbcription({...data,paypalPlanId,userId});
            if(res && res.success && res.data){
                switch (prevData.prevPath) {
                  case RATE_LANDLORD:
                    navigate(ROUTES.RATE_LANDLORD, {
                      state: {prevData},
                    });
                    break;
                  case RATE_TENANT:
                    navigate(ROUTES.RATE_TENANT, {
                      state: { prevData},
                    });
                    break;
                  case PROFILE_PAGE:
                    navigate(ROUTES.PROFILE);
                    break;
                  case ADD_PROPERTY:
                    navigate(ROUTES.ADD_PROPERTY);
                    break;
                  case ADD_TENANT:
                    navigate(ROUTES.ADD_TENANT);
                    break;
                  case ADD_LANDLORD:
                    navigate(ROUTES.ADD_LANDLORD);
                    break;
                  case SUBSDETAILS:
                    navigate(ROUTES.SUSBCRIPTION_DETAILS);
                    break;
                  default:
                    break;
                }
                setTimeout(()=>{
                  toastMesage(translation(res.message),"success");
                },2200)
            }else{
              toastMesage(translation(res?.message),"error");
            }
          }
        }}
        
      />
    </PayPalScriptProvider>
  );
};




const Paypal:React.FC<IProps> = ({paypalPlanId,amount,paymentType,prevData}) => {
  const authData = useSelector((state: RootState) => state?.AuthReducer?.authData);
 return (
  <PayPalScriptProvider
    options={{
      clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID as string,
      components: "buttons",
    }}
  >
    <div className="container">
      <div className="payment-container">
        {paymentType === "singleCharge" ? 
        <SingleChargeButtons 
         amount={amount} 
         email={authData.email}
         prevData={prevData}
         /> :
         <SubscriptionButtons 
          email={authData.email} 
          userId={authData.id}
          prevData={prevData} 
          paypalPlanId={paypalPlanId} 
          />}
      </div>
    </div>
  </PayPalScriptProvider>
)
}


export default Paypal;

