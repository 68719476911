import "./components/i18n/I18n";

import PublicRoutes from "./routes/PublicRoutes";


function App() {
  return <PublicRoutes />;
}

export default App;
