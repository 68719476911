import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { IPrevData } from "../../interfaces/commonInterfaces";
import CheckoutForm from "./CheckoutForm";
import Paypal from "./Paypal";

const Checkout: React.FC = () => {
  const { t: translation } = useTranslation();
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY as string);
  const location=useLocation().state as {paypalPlanId:string;amount:number,
    prevData:IPrevData;subscription:boolean};
  const params=useParams() as {method:"stripe"|"paypal"};  
  switch (params.method) {
    case "stripe":
        return (
        <Elements stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      );
    case "paypal":
     return <Paypal 
      paypalPlanId={location.paypalPlanId}
      amount={location.amount}
      paymentType={location.subscription ? "subscription":"singleCharge"}
      prevData={location.prevData}
       />
    default:
      break;
  }

  return <h1> {translation("unknown_payment_method")} </h1>

};

export default Checkout;
