import * as http from "../utils/http";
import endpoint from "../constant/endpoints";
import ApiResponse from "../resources/entity/IApiResponse";
import {
  ICheckExistReviews,
  IReportReview,
} from "../interfaces/commonInterfaces";
// import { IAddLandlordAndTenant } from "../interfaces/commonInterfaces";

export const getSearchResult = (
  from: string,
  searchWord: string,
  id: string
): Promise<ApiResponse> => {
  return http.get(
    `${endpoint.review.GET_SEARCH_RESULT}?searchWord=${searchWord}&from=${from}&id=${id}`
  );
};

export const getReviewData = (
  tenantId?: string,
  landLordId?: string,
  fulladdress?: string,
  from?: string
): Promise<ApiResponse> => {
  return http.get(`${endpoint.review.GET_REVIEW_DATA}?tenantId=${tenantId}&landlordId=${landLordId}&from=${from}&fulladdress=${fulladdress}
      `);
};
/**
 *  Repoprt Reviews
 */

export const reportReview = (data: IReportReview): Promise<ApiResponse> => {
  return http.post(endpoint.review.REPORT_REVIEW, data);
};
/**
 * Check Exist Reviews
 */
export const checkExistReviews = (
  data: ICheckExistReviews
): Promise<ApiResponse> => {
  return http.post(endpoint.review.CHECK_EXIST_REVIEWS, data);
};
