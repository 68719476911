import { useTranslation } from "react-i18next";
import Page404 from "../../assets/images/page-not-found.gif";

const PageNotFound = () => {
  const { t: translation } = useTranslation();
  return (
    <div className="pagenotfound">
      <img src={Page404} alt="" />
      <h2>{translation("page_not_found")}</h2>
    </div>
  );
};

export default PageNotFound;
