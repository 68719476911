enum ActionType {
  LOGIN = "LOGIN",
  UPDATE_PERMISSIONS = "UPDATE_PERMISSIONS",
  UPDATEAUTHDATA = "UPDATEAUTHDATA",
  FORGOTPASSWORD = "FORGOTPASSWORD",
  LOGOUT = "LOGOUT",
  LOCALSTORAGE = "localstorage",
  DEFAULT = "DEFAULT",
  FAILURE = "FAILURE",
  RESET_PASSWORD = "reset_password",
  USER_ACCOUNT_TYPES = "user_account_types",
  ERROR_HANDLING = "error_handling",
  UNAUTHORIZED_ERROR = "unauthorized_error",
  NOT_FOUND_ERROR = "not_found_error",
  INTERNAL_SERVER_ERROR = "internal_server_error",
  CHANGE_PASSWORD = "CHANGE_PASSWORD",
  UPDATE_AUTH = "UPDATE_AUTH",
  CLEAR_AUTH = "CLEAR_AUTH",
  CHANGEPASSWORD = "CHANGE_PASSWORD",
  PENDING = "PENDING",
  COMPLETED = "COMPLETED",
  SOCIAL_lOGIN = "SOCIAL_lOGIN",
}

export default ActionType;
