import * as http from "../utils/http";
import endpoint from "../constant/endpoints";
import ApiResponse from "../resources/entity/IApiResponse";
import {
  IAddLandlordAndTenant,
  IPropertyData,
  IUpdatePasssword,
  IUpdateProfile,
  IUpdateProperty,
} from "../interfaces/commonInterfaces";
import { IContactUs } from "../interfaces/authInterface";
// import { date } from "yup";

export const getPropertyDetails = (id: string): Promise<ApiResponse> => {
  return http.get(`${endpoint.user.PROFILE.replace(":id", id)}`);
};

export const getUserDetails = (id: string): Promise<ApiResponse> => {
  return http.get(`${endpoint.user.DETAILS.replace(":id", id)}`);
};

export const updatePasswordService = (
  id: string,
  data: IUpdatePasssword
): Promise<ApiResponse> => {
  return http.patch(
    `${endpoint.user.UPDATE_PASSWORD.replace(":id", id)}`,
    data
  );
};

export const updateProfileService = (
  data: IUpdateProfile
): Promise<ApiResponse> => {
  return http.patch(`${endpoint.user.UPDATE_PROFILE}`, data);
};

export const updatePropertyService = (
  id: string,
  data: IUpdateProperty,
  propertyData: IPropertyData[]
): Promise<ApiResponse> => {
  return http.patch(`${endpoint.user.UPDATE_PROPERTY.replace(":id", id)}`, {
    data,
    propertyData,
  });
};

export const getReviewAndRatingService = (
  slug: string,
  searchWord: string
): Promise<ApiResponse> => {
  return http.get(
    `${endpoint.user.GET_REVIEW_AND_RATING}${
      slug ? `?slug=${slug}` : ""
    }?slug=${slug}${searchWord ? `&searchWord=${searchWord}` : ""}`
  );
};

export const saveContactUsData = (data: IContactUs): Promise<ApiResponse> => {
  return http.post(`${endpoint.user.CONTACT_US}`, data);
};

export const deleteUserProperty = (id: string): Promise<ApiResponse> => {
  return http.remove(`${endpoint.user.DELETE_PROPERTY}/?id=${id}`);
};

export const addUserProperty = (
  date: IAddLandlordAndTenant
): Promise<ApiResponse> => {
  return http.post(`${endpoint.user.ADD_USER_PROPERTY}`, date);
};

export const getHomePageContent = (): Promise<ApiResponse> =>
  http.get(`${endpoint.user.HOMEPAGE_CONTENT}`);

export const getPlanList = (): Promise<ApiResponse> => {
  return http.get(`${endpoint.user.GET_PLAN_LIST}`);
};

export const getCustomReviewPlan = (): Promise<ApiResponse> => {
  return http.get(`${endpoint.user.GET_CUSTOM_REVIEW_PLAN}`);
};
export const getAboutUsContent = (): Promise<ApiResponse> =>
  http.get(`${endpoint.user.ABOUT_US_CONTENT}`);

export const getHomePageAdvetisement = (): Promise<ApiResponse> =>
  http.get(`${endpoint.user.HOMEPAGE_ADVERTISEMENT}`);

export const updateVisitedService = (id: number): Promise<ApiResponse> =>
  http.get(`${endpoint.user.UPDATE_VISITED_COUNT}?id=${id}`);

export const getVisitedService = (id: number): Promise<ApiResponse> =>
  http.get(`${endpoint.user.GET_VISITED_COUNT}?id=${id}`);

export const updateReviewTermCondition = (id: number): Promise<ApiResponse> =>
  http.get(`${endpoint.user.UPDATE_REVIEW_TERM_CONDITION}?id=${id}`);
