import * as http from "../utils/http";
import endpoint from "../constant/endpoints";
import ApiResponse from "../resources/entity/IApiResponse";
import {
  IAddLandlordAndTenant,
  IClaimedProperty,
  IRateLoandlordService,
} from "../interfaces/commonInterfaces";

export const addLandlordService = (
  data: IAddLandlordAndTenant
): Promise<ApiResponse> => {
  return http.post(endpoint.user.ADD_LANDLORD, data);
};

export const getPropertyAddressService = (
  searchWord: string
): Promise<ApiResponse> => {
  return http.get(
    `${endpoint.property.SEARCH_ADDRESS}?searchWord=${searchWord}`
  );
};

export const getUserNameService = (
  searchWord: string
): Promise<ApiResponse> => {
  return http.get(`${endpoint.user.SEARCH_USER_NAME}?searchWord=${searchWord}`);
};

export const getLandlordByName = (
  searchWord: string,
  id:number
): Promise<ApiResponse> => {
  return http.get(
    `${endpoint.lanlord.GET_LANDLORD}?id=${id}&searchWord=${searchWord}`
  );
};

export const getAddressByPropertyType = (
  userId: number,
  type: string
): Promise<ApiResponse> => {
  return http.get(
    `${endpoint.lanlord.GET_ADDRESS_BY_PROPERTY_TYPE}?userId=${userId}&type=${type}`
  );
};

export const rateLandLord = (
  data: IRateLoandlordService
): Promise<ApiResponse> => {
  return http.post(`${endpoint.lanlord.RATE_LANDLORD}`, data);
};

export const checkLandLordProperty = (
  userId: string,
  fulladdress: string,
  type: string
): Promise<ApiResponse> => {
  return http.get(
    `${endpoint.lanlord.CHECK_LANDLORD_PROPERTY}?userId=${userId}&fulladdress=${fulladdress}&type=${type}`
  );
};

export const claimedPropertyByLandlord = (
  data: IClaimedProperty
): Promise<ApiResponse> => {
  return http.post(`${endpoint.lanlord.CLAIMED_PROPERTY}`, data);
};
