import React, { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { isExpired } from "react-jwt";
import { toast } from "react-toastify";
import  toastMesage  from "../components/commonFormGroup/CommonToastMessage";
import { matchToken } from "../service/auth";
import { logout, updateAuth } from "../redux/actions/AuthAction";
import { TokenExpired, UserBlockByAdmin } from "../constant/commonConstant";
import Storage from "../utils/storage";
import ROUTES from "../constant/routes";
import ActionType from "../resources/enums";

/**
 * Private routes are only visible for login users
 * */
interface Props {
  component: React.ComponentType;
}
const ProtectedRoutes: React.FC<Props> = ({ component: RouteComponent }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const { t: translation } = useTranslation();
  const tokenData = Storage.get("__ATK__");

  const authCheck = async () => {
    try {
      const response = await matchToken({ token: tokenData });
      toast.dismiss();
      if (response && response.data && response.data.success) {
        dispatch(updateAuth(response?.data?.data));
        if (response?.data?.data?.visited_count === 0) {
          localStorage.setItem("VC", response?.data?.data?.visited_count);
        }
        if (response?.data?.data?.customer_id) {
          localStorage.setItem("CI", response?.data?.data?.customer_id);
        }
      }
      if (response?.data?.message === UserBlockByAdmin) {
        toastMesage(translation(response?.data?.message));
        setTimeout(() => {
          dispatch(logout());
          navigate(ROUTES.LOGIN);
        }, 2500);
      }
      if (response?.data?.message === TokenExpired) {
        toastMesage(translation(response?.data?.message));
        setTimeout(() => {
          dispatch(logout());
          navigate(ROUTES.LOGIN);
        }, 2000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sessionExpire = () => {
    if (tokenData) {
      const isMyTokenExpired = isExpired(tokenData);
      if (isMyTokenExpired) {
        localStorage.clear();
        toastMesage(translation(TokenExpired),"error");
        dispatch({
          type: ActionType.LOGOUT,
        });
        return <Navigate to={ROUTES.LOGIN} />;
      }
    }
  };
  useEffect(() => {
    sessionExpire();
  }, []);

  if (tokenData) {
    authCheck();
    return <RouteComponent />;
  }
  return <Navigate to={ROUTES.HOME} />;
};

export default ProtectedRoutes;
