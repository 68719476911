import * as yup from "yup";
import { TFunction } from "react-i18next";
import {
  optionalEmail,
  firstName,
  lastName,
  propertyType,
  addressLine1,
} from "./commonFieldValidation";

const addLandlordAndTenantValidationSchema = (translation: TFunction) =>
  yup.object().shape({
    firstName: firstName(translation),
    lastName: lastName(translation),
    email: optionalEmail(translation),
    address: addressLine1(translation),
    propertyType: propertyType(translation, "property_type"),
  });

const updatePropertyValidationSchema = (translation: TFunction) =>
  yup.object().shape({
    property: yup.array().of(
      yup.object().shape({
        address: addressLine1(translation),
        propertyType: propertyType(translation, "property_type"),
      })
    ),
  });

const addressValidationSchema = (translation: TFunction) =>
  yup.object().shape({
    address: addressLine1(translation),
  });

const addPropertyValidation = (translation: TFunction) =>
  yup.object().shape({
    address: addressLine1(translation),
    propertyType: propertyType(translation, "property_type"),
  });

export {
  addLandlordAndTenantValidationSchema,
  addressValidationSchema,
  updatePropertyValidationSchema,
  addPropertyValidation,
};
