import { FC, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/reducers/Index";

import { IAuthData } from "../../interfaces/commonInterfaces";
import ActionType from "../../resources/enums";
import CommonPopup from "../commonPopup/CommonPopup";
import ROUTES from "../../constant/routes";
import Logo from "../../assets/images/logo.png";
import "./Header.scss";

const Header: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const dispatch = useDispatch();
  const { t: translation } = useTranslation();

  const [isModal, setIsModal] = useState(false);

  const authData: IAuthData = useSelector(
    (state: RootState) => state?.AuthReducer?.authData
  );

  /**
   * Handle logout
   */
  const handleLogout = async () => {
    setIsModal(true);
  };

  const handleCallbackForCommonPopup = async (buttonType: string) => {
    if (buttonType === "YES") {
      localStorage.removeItem("data");
      localStorage.removeItem("token");
      dispatch({
        type: ActionType.LOGOUT,
      });
      navigate(ROUTES.LOGIN);
    }

    if (buttonType === "NO") {
      setIsModal(false);
    }
  };

  return (
    <>
      <header className="header">
        <nav className="navbar navbar-expand-lg">
          <div className="container">
            <NavLink className="navbar-brand" to="/">
              <img src={Logo} alt="logo" />
            </NavLink>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapsibleNavbar"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div
              className="collapse navbar-collapse justify-content-end"
              id="collapsibleNavbar"
            >
              <ul
                className={`navbar-nav ${
                  pathname === ROUTES.HOME ? "navbar-white" : ""
                }`}
              >
                {authData && authData.token ? (
                  <>
                    <li className="nav-item ">
                      <NavLink className="nav-link" to={ROUTES.ABOUT}>
                        {translation("about")}
                      </NavLink>
                    </li>

                    <li className="nav-item">
                      <NavLink className="nav-link" to={ROUTES.RATE_LANDLORD}>
                        {translation("rate_landlord")}
                      </NavLink>
                    </li>

                    <li className="nav-item">
                      <NavLink className="nav-link" to={ROUTES.RATE_TENANT}>
                        {translation("rate_tenant")}
                      </NavLink>
                    </li>

                    <li className="nav-item">
                      <NavLink className="nav-link" to={ROUTES.FIND_REVIEW}>
                        {translation("find_a_review")}
                      </NavLink>
                    </li>

                    <li className="nav-item ">
                      <NavLink className="nav-link" to={ROUTES.PROFILE}>
                        {translation("profile")}
                      </NavLink>
                    </li>
                    <li className="nav-item ">
                      <NavLink
                        className="nav-link"
                        to={ROUTES.SUSBCRIPTION_DETAILS}
                      >
                        {translation("subscription")}
                      </NavLink>
                    </li>

                    <li className="nav-item">
                      <NavLink className="nav-link" to={ROUTES.CONTACT_US}>
                        {translation("contact_us")}
                      </NavLink>
                    </li>

                    <li className="nav-item" onClick={() => handleLogout()}>
                      <NavLink className="nav-link" to="#">
                        {translation("logout")}
                      </NavLink>
                    </li>
                  </>
                ) : (
                  <>
                    <li className="nav-item ">
                      <NavLink className="nav-link" to={ROUTES.ABOUT}>
                        {translation("about")}
                      </NavLink>
                    </li>

                    <li className="nav-item">
                      <NavLink className="nav-link" to={ROUTES.CONTACT_US}>
                        {translation("contact_us")}
                      </NavLink>
                    </li>

                    <li>
                      <NavLink to={ROUTES.LOGIN}>
                        <button
                          className="main-button primary-btn button-radius button-lg"
                          type="button"
                        >
                          {translation("login")}
                        </button>
                      </NavLink>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>
        </nav>
      </header>
      {isModal && (
        <CommonPopup
          headerMessage={translation("logout_msg")}
          popupType="confirm"
          clickedButton={handleCallbackForCommonPopup}
        />
      )}
    </>
  );
};

export default Header;
