import { FC, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import { ToastContainer, toast } from "react-toastify";
import CommonInput from "../../components/commonFormGroup/CommonInput";

import { restPasswordValidationSchema } from "../../validationSchema/authValidation";
import {
  IResetPassword,
  IResetPasswordProps,
} from "../../interfaces/authInterface";

import Logo from "../../assets/images/logo.png";
import "./UserAuth.scss";
import { resetPasswordService } from "../../service/auth";
import toastMesage from "../../components/commonFormGroup/CommonToastMessage";
import ROUTES from "../../constant/routes";
import AuthBanner from "../../assets/images/auth-bg.png";
import CommonSVGFiles from "../../components/commonFormGroup/CommonSVGFiles";

const ResetPassword: FC = () => {
  const { t: translation } = useTranslation();
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const userInfo = location.state as IResetPasswordProps;
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(restPasswordValidationSchema(translation)),
  });

  const onSubmit = async (data: IResetPassword) => {
    try {
      setLoading(true);
      const response = await resetPasswordService({
        ...data,
        email: userInfo.email,
      });
      toast.dismiss();
      if (response && response?.data?.success) {
        toastMesage(translation(response?.data?.message),"success");
        setTimeout(() => {
          navigate(ROUTES.LOGIN);
        }, 2000);
      } else {
        toastMesage(translation(response?.data?.message));
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <div className="container-fluid p-0">
      <div className="row m-0">
        <div className="col-md-6 p-0">
          <div className="userauth-form ">
            <img src={Logo} alt="logo" className="logo" />
            <form
              onSubmit={handleSubmit((data) =>
                onSubmit(data as IResetPassword)
              )}
            >
              <CommonInput
                control={control}
                label={translation("password")}
                name="password"
                type="password"
                className="form-control"
                placeHolder={translation("password_placeholder")}
                error={errors.password}
                hasIcon
              />

              <CommonInput
                control={control}
                label={translation("confirmPassword")}
                name="confirmPassword"
                type="password"
                className="form-control"
                placeHolder={translation("confirm_password_placeholder")}
                error={errors.confirmPassword}
                hasIcon
              />

              <div className="text-center mt-5 ">
                <button
                  onClick={() => {
                    navigate("/forgot-password");
                  }}
                  className="main-button mainprimary-btn border-radius mx-4"
                  type="button"
                >
                  {translation("back")}
                </button>
                <button
                  className="main-button mainprimary-btn border-radius"
                  type="submit"
                >
                  {translation("submit")}
                  {loading ? (
                    <span className="spinner-border spinner-border-sm me-2 ms-2" />
                  ) : (
                    <CommonSVGFiles svgName="arrowForward" />
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="col-md-6 p-0">
          <img src={AuthBanner} alt="banner" className="banner-auth" />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
export default ResetPassword;
