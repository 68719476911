import React, {  useState } from "react";
import moment from "moment";
import {usePDF} from "react-to-pdf";
import { useTranslation } from "react-i18next";
import { currentTimeStamp, SUBSCRIPTION_STATUS } from "../../constant/commonConstant";
import { ISubscriptionDetails } from "../../interfaces/commonInterfaces";
import SubscriptionIcon from "../../assets/images/subscription.png";
import CommonPopup from "../../components/commonPopup/CommonPopup";

interface ICurrentPlan {
  details: ISubscriptionDetails;
  getInvoice: () => void;
  setShowSubsCancelModal: (arg: boolean) => void;
  loading: boolean;
  invoiceLoading: boolean;
}

const CurrentPlan: React.FC<ICurrentPlan> = ({
  details,
  setShowSubsCancelModal,
  loading,
  getInvoice,
  invoiceLoading
}) => {
  const [printModal,setPrintModal]=useState(false);
  const { t: translation } = useTranslation();
  const { toPDF, targetRef } = usePDF({filename: 'invoice.pdf'});
  return (
    <div className="col-lg-3 col-md-6 mx-auto">
      <div
        className="card text-dark bg-light mb-3 border border-primary plan-card"
        style={{ maxWidth: "18rem" }}
      >
        <div className="card-header bg-primary text-white text-center">
          <h6 className="p-1 mb-0 uppercase">
            { moment.unix(details.end_at).isAfter(currentTimeStamp)
              ? translation("current_subscription")
              : "Plan Expired"}
          </h6>
        </div>
        <div className="card-body text-center p-2">
          <img
            src={SubscriptionIcon}
            alt="icon"
            className="subscription-icon"
          />
          <h6 className="card-title">
            {translation("plan")}: {translation(details.title)}{" "}
          </h6>
          <h6 className="card-title">
            {translation("last_payment")} :{" "}
            {moment.unix(details.last_payment).format("MMM Do YYYY")}
          </h6>
          <h6 className="card-title">
            {translation("next_payment")} :{" "}
            {details && details.next_payment? moment.unix(details.next_payment).format("MMM Do YYYY"):"-"}
          </h6>
          <h6 className="card-title">
            {translation("plan_renewal")} :{" "}
            {translation(details.renewal_status)}
          </h6>
         { details && details.subscription_id.includes("sub") ?
          <button
            type="button"
            disabled={invoiceLoading}
            className="main-button primary-btn button-radius"
            onClick={() => getInvoice()}
          >
            {translation("invoice")}
            {invoiceLoading && (
              <span className="spinner-border spinner-border-sm me-2 ms-2" />
            )}
          </button>
          :
         <button type="button" className="main-button primary-btn button-radius" 
         onClick={() =>{
            setPrintModal(true)
            setTimeout(()=>{toPDF()},100)
           }}
         >
          {translation("invoice")}
         </button>
          
         }
        </div>
        {details.renewal_status === SUBSCRIPTION_STATUS.ACTIVE && (
          <div className="card-footer text-center">
            <button
              type="button"
              className="main-button primary-btn border-radius col-11 my-1"
              disabled={loading}
              onClick={() => setShowSubsCancelModal(true)}
            >
              {translation("cancel_renewal")}
              {loading && (
                <span className="spinner-border spinner-border-sm me-2 ms-2" />
              )}
            </button>
          </div>
        )}
      </div>
      {printModal && (
        <CommonPopup
          from="printPDF"
          headerMessage={"  "}
          clickedButton={() => setPrintModal(false)}
          printRef={targetRef}
          subsDetails={details}
        />
      )}
    </div>
  );
};

export default CurrentPlan;
