import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import OwlCarousel from "react-owl-carousel";
import { RootState } from "../../redux/reducers/Index";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { ICommonPopup, IPlanList } from "../../interfaces/commonInterfaces";
import {
  ADD_PROPERTY,
  FindReviewTermCondtion,
  FIND_REVIEW,
  LOADING_MODAL,
  planPopupType,
  PROFILE_PAGE,
  RATE_LANDLORD,
  REPORTED_REVIEW,
  SHOW_PLAN_MODAL,
  FIND_REVIEW_LOADING_MODAL,
  LANDING_PAGE_MODAL,
  imageType,
  videoType,
} from "../../constant/commonConstant";
import { reportReview } from "../../service/review";
import { getPlanList } from "../../service/user";
import toastMesage from "../commonFormGroup/CommonToastMessage";
import Plan from "../../containers/subscriptionPlan/Plan";
import CommonSVGFiles from "../commonFormGroup/CommonSVGFiles";
import ExistPropertyDetails from "../existPropertyDetails/ExistPropertyDetails";
import PaymentMethod from "../../containers/checkout/PaymentMethod";
import PrintPDF from "../../containers/subscription/PrintPDF";

const CommonPopup = (props: ICommonPopup) => {
  const { t: translation } = useTranslation();
  const [reportedMessage, setReportedMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [planList, setPlanList] = useState<IPlanList[]>();

  const authData = useSelector(
    (state: RootState) => state?.AuthReducer?.authData
  );

  const handleClick = (buttonType: string) => {
    props.clickedButton && props.clickedButton(buttonType);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      if (reportedMessage.trim()?.length === 0) {
        setErrorMessage("Reported Comment is Required");
      } else {
        const reviewData = {
          fromId: authData.id,
          toId: Number(props.toId) ? Number(props.toId) : 0,
          propertyId: props.propertyId ? props.propertyId : 0,
          reviewRatingId: Number(props.reviewRatingId)
            ? Number(props.reviewRatingId)
            : 0,
          comment: reportedMessage,
        };
        const response = await reportReview(reviewData);
        setReportedMessage("");
        toast.dismiss();
        if (response && response.data && response.data.success) {
          toastMesage(translation(response?.data?.message),"success");
          handleClick("OK");
        } else {
          toastMesage(translation(response?.data?.message));
          handleClick("OK");
        }
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const gettingPlanList = async () => {
    try {
      setLoading(true);
      const response = await getPlanList();
      if (response && response.data && response.data.success) {
        setPlanList(response.data.data);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    gettingPlanList();
  }, []);

  return (
    <>
      {props && props.from === FIND_REVIEW_LOADING_MODAL && (
        <div className="position-relative mb-5">
          {props.yesLoader && (
            <div
              className="spinner-border position-absolute top-50 start-50"
              role="status"
            >
              <span className="visually-hidden">{translation("loading")}</span>
            </div>
          )}
        </div>
      )}

      {props && props.from !== FIND_REVIEW_LOADING_MODAL && (
        <div
          className={`modal common-modal modal-show ${
            props?.popupType === planPopupType && "modal-xl"
          }`}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                {props && props.headerMessage && (
                  <div className="heading">
                    <h2 className="modal-title ">{props.headerMessage}</h2>
                  </div>
                )}
                <div className="modal-closer">
                  <button
                    className="closer-btn"
                    type="button"
                    onClick={() => handleClick("OK")}
                  >
                    <CommonSVGFiles svgName="cross" />
                  </button>
                </div>
              </div>

              <div className="modal-body">
                {props.from === RATE_LANDLORD && (
                  <p className="text-center">
                    {translation("rating_added_successfully")}
                  </p>
                )}
                {props.from === FindReviewTermCondtion && (
                  <div className="text-center">
                    <p>
                      {translation(
                        "renters_book_is_not_responsible_for_any_review"
                      )}
                    </p>
                    <button
                      className="main-button mainprimary-btn border-radius me-2"
                      onClick={() => handleClick("YES")}
                      type="button"
                    >
                      {translation("ok")}
                      {props.yesLoader && (
                        <span className="spinner-border spinner-border-sm me-2 ms-2" />
                      )}
                    </button>
                  </div>
                )}

                {props.image && (
                  <div className="img-popup-review">
                    <OwlCarousel
                      className="owl-theme"
                      margin={10}
                      nav
                      dots={false}
                      items={1}
                      startPosition={props?.index}
                    >
                      {props.image &&
                        props.image.length > 0 &&
                        props.image.map((item: string | undefined) => {
                          return (
                            <div className="item" key={props?.index}>
                              <img src={item} alt="img" key={props?.index} />
                            </div>
                          );
                        })}
                    </OwlCarousel>
                  </div>
                )}

                {props.popupType === "confirm" && props.from !== FIND_REVIEW && (
                  <div className="text-center mt-4">
                    {props.from !== RATE_LANDLORD && (
                      <button
                        className="main-button mainprimary-btn border-radius me-2"
                        onClick={() => handleClick("YES")}
                        type="button"
                      >
                        <span>
                          {translation("yes")}
                          {props.yesLoader && (
                            <div className="spinner-border text-light" />
                          )}
                        </span>
                      </button>
                    )}
                    <button
                      className="main-button mainprimary-btn border-radius"
                      type="button"
                      onClick={() => handleClick("NO")}
                    >
                      <span>
                        {translation(
                          `${props.from === RATE_LANDLORD ? "ok" : "no"}`
                        )}
                      </span>
                    </button>
                  </div>
                )}

                {props.popupType === "success" && props?.from !== FIND_REVIEW && (
                  <div>
                    <button
                      className="main-button mainprimary-btn border-radius"
                      onClick={() => handleClick("OK")}
                      type="button"
                    >
                      <span>{translation("ok")}</span>
                    </button>
                  </div>
                )}

                {props?.from === FIND_REVIEW && (
                  <div className="mt-2 text-center">
                    <button
                      className="main-button mainprimary-btn border-radius"
                      onClick={() => handleClick("OK")}
                      type="button"
                    >
                      <span>{translation("close")}</span>
                    </button>
                  </div>
                )}

                {props?.from === REPORTED_REVIEW && (
                  <div>
                    <div className="form-floating">
                      <div className="form-floating mb-3">
                        <textarea
                          rows={3}
                          placeholder={translation("leave_a_comment_here")}
                          className="form-control"
                          value={reportedMessage}
                          onChange={(e) => setReportedMessage(e.target.value)}
                        />
                        <label htmlFor="floatingInput">
                          {translation("leave_a_comment_here")}
                        </label>
                      </div>

                      {errorMessage.trim().length > 0 && (
                        <p className="text-danger mt-2">{errorMessage} </p>
                      )}
                    </div>
                    <br />
                    <button
                      className="main-button mainprimary-btn border-radius"
                      type="button"
                      onClick={handleSubmit}
                    >
                      {translation("submit")}
                      {loading && (
                        <span className="spinner-border spinner-border-sm me-2 ms-2" />
                      )}
                    </button>
                  </div>
                )}
                 {/* CLAIMED PROPERTY SECTION  */}
                {props?.from === ADD_PROPERTY ||
                  (props?.from === PROFILE_PAGE && (
                    <div className="subscription-card">
                      <div className="card-top">
                        <h4 className="text-primary">
                          {" "}
                          {translation("property_details")}{" "}
                        </h4>
                      </div>
                      {props && props.existPropertyData && (
                        <div className="card-mid">
                          <ExistPropertyDetails
                            details={props?.existPropertyData}
                            clickedButton={handleClick}
                            from={props?.from}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                {/* SHOW PLAN MODAL SECTION  */}
                {props && props.from === SHOW_PLAN_MODAL && (
                  <div className="row">
                    {planList && planList.length > 0 ? (
                      planList.map((it, idx: number) => {
                        return (
                          <div className="col-lg-3 col-md-6" key={idx}>
                            <Plan
                              plan={it?.title}
                              recurrence={it?.recurrence}
                              amount={it.amount}
                              prevData={props?.prevData}
                              paypalPlanId={it.paypal_plan_id}
                              planId={it.plan_id}
                              subscription
                            />
                          </div>
                        );
                      })
                    ) : loading ? (
                      <div className="text-center">
                        <span className="spinner-border spinner-border-sm me-2 ms-2 p-4" />
                      </div>
                    ) : (
                      <div className="text-center">
                        <strong> {translation("no_subscription_plan")} </strong>
                      </div>
                    )}
                  </div>
                )}
                {/* LOADING IN PAYMENT SECTION  */}
                {props && props.from === LOADING_MODAL && (
                  <div className="position-relative">
                    <p>
                      {translation(
                        "please_do_not_click_back_button_or_refresh_page_unlit_payment_complete"
                      )}
                    </p>
                    {props.yesLoader && (
                      <div
                        className="spinner-border position-absolute top-50 start-50 mt-1"
                        role="status"
                      >
                        <span className="visually-hidden">
                          {translation("loading")}
                        </span>
                      </div>
                    )}
                  </div>
                )}

                {/* SHOW IMAGE/VIDEO IN LANDING PAGE  */}

                {props && props.from===LANDING_PAGE_MODAL && (
                  <div className="advertisement-info-modal">
                    {props.landingPageData && props.landingPageData.type===imageType &&
                     <img src={props?.landingPageData?.url} alt="Landing Page Advertisement" /> 
                    }
                    {props.landingPageData && props.landingPageData.type===videoType &&
                      <video height={300} width={400} 
                      // muted 
                      loop 
                      controls 
                      autoPlay
                      >
                        <track kind="captions" />
                        <source src={props?.landingPageData?.url}    />
                      </video>
                    }
                    <div className="advertisement-info">
                      <h2>{props.landingPageData?.title}</h2>
                      <p> {props.landingPageData?.description} </p>
                    </div>
                  </div> 
                )}
                {props.from==="paymentMethod" && <PaymentMethod getMethod={props.getMethod} />}
                
                {props.from==="printPDF" && props.subsDetails&&<PrintPDF
                  printRef={props.printRef}
                 details={props.subsDetails}
                />}

              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CommonPopup;
