import React from 'react';

const TermsAndConditions:React.FC = () => {
  return (
    <div className="container">
      <h2>Terms and Conditions</h2>
      <p>Welcome to Renters Book, LLC! These terms and conditions Terms govern your access to and use of our services ("Services"), including but not limited to our website located at <a href="https://www.rentersbook.com">https://www.rentersbook.com</a> and any other website of ours that links to these Terms.</p>
      <p>By accessing or using our Services, you agree to be bound by these Terms. If you do not agree to these Terms, please do not access or use our Services.</p>
      
      <h3>1. Privacy Notice</h3>
      <p>Your use of our Services is also governed by our Privacy Notice, which describes how we collect, store, use, and share your information. By using our Services, you consent to the collection and use of your information as described in our Privacy Notice.</p>
      
      <h3>2. Information Collection</h3>
      <p>When you use our Services, we may collect personal information that you voluntarily provide to us, as well as information collected automatically. This may include names, phone numbers, email addresses, passwords, device information, and usage data.</p>
      
      <h3>3. Use of Information</h3>
      <p>We process your information to provide, improve, and administer our Services, communicate with you, ensure security, and comply with the law. Your information may also be used for targeted advertising and to evaluate and improve our Services.</p>
      
      <h3>4. Sharing of Information</h3>
      <p>We may share your information in specific situations, such as business transfers or with business partners. Information you share publicly on our Services may be viewed by other users.</p>
      
      <h3>5. Cookies and Tracking Technologies</h3>
      <p>We may use cookies and similar tracking technologies to collect and store information about your use of our Services. By using our Services, you consent to the use of cookies as described in our Cookie Notice.</p>
      
      <h3>6. Social Logins</h3>
      <p>You may choose to register or log in to our Services using your social media account details. By doing so, you authorize us to access certain information from your social media provider as described in our Privacy Notice.</p>
      
      <h3>7. Account Information</h3>
      <p>You may review, change, or terminate your account at any time. We will deactivate or delete your account and information upon request, subject to retention requirements under applicable law.</p>
      
      <h3>8. Data Security</h3>
      <p>While we implement security measures to protect your information, we cannot guarantee 100% security against unauthorized access or breaches. Your use of our Services is at your own risk.</p>
      
      <h3>9. Privacy Rights</h3>
      <p>Depending on your location, you may have certain privacy rights regarding your personal information. Please refer to our Privacy Notice for more information on exercising your privacy rights.</p>
      
      <h3>10. California Residents</h3>
      <p>If you are a resident of California, you may have additional rights regarding access to your personal information under the California Consumer Privacy Act (CCPA). Please refer to our CCPA Privacy Notice for more information.</p>
      
      <h3>11. Updates to Terms</h3>
      <p>We may update these Terms from time to time. Any changes will be effective immediately upon posting of the updated Terms on our website. Your continued use of our Services after any such changes constitutes acceptance of the updated Terms.</p>
      
      <h3>12. Contact Us</h3>
      <p>If you have any questions or concerns about these Terms or our Services, please contact us at <a href="mailto:info@rentersbook.com">info@rentersbook.com</a>.</p>
    </div>
  );
}

export default TermsAndConditions;
