import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { RootState } from "../../redux/reducers/Index";
import { claimedPropertyByLandlord } from "../../service/landlord";
import { claimedPropertyComment } from "../../validationSchema/userValidation";
import { PROFILE_PAGE } from "../../constant/commonConstant";
import {
  IClaimedProperty,
  IExistProperty,
} from "../../interfaces/commonInterfaces";
import toastMesage from "../commonFormGroup/CommonToastMessage";
import CommonTextArea from "../commonFormGroup/CommonTextArea";

interface IProps {
  details: IExistProperty;
  clickedButton?: (e: string) => void;
  from?: string;
}

const ExistPropertyDetails: React.FC<IProps> = ({
  details,
  clickedButton,
  from,
}) => {
  const { t: translation } = useTranslation();
  const [loading, setLoading] = useState(false);

  const authData = useSelector(
    (state: RootState) => state?.AuthReducer?.authData
  );

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(claimedPropertyComment(translation)),
  });

  const handleClick = (buttonType: string) => {
    clickedButton && clickedButton(buttonType);
  };

  const onSubmit = async ({ comment }: { comment: string }) => {
    try {
      setLoading(true);
      if (authData.id) {
        const { propertyId, currentLandlordId } = details;
        const payload: IClaimedProperty = {
          fromId: authData.id,
          toId: currentLandlordId,
          propertyId,
          comment,
        };
        const response = await claimedPropertyByLandlord(payload);
        if (response && response.data && response.data.success) {
          toastMesage(translation(response?.data?.message),"success");
          handleClick("OK");
        } else {
          toastMesage(translation(response?.data?.message));
          handleClick("OK");
        }
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };
  const showDetailsHandler = (
    objectKey: string | undefined,
    translationKey: string
  ) => {
    if (objectKey && translationKey) {
      return (
        <div className="card-user-info">
          <h6>{translation(translationKey)} :</h6>
          <p>{objectKey}</p>
        </div>
      );
    }
  };

  if (details) {
    return (
      <>
        <div className="card-mid">
          {showDetailsHandler(details?.currentLandlordName, "current_landlord")}
          {showDetailsHandler(details?.propertyType, "property_type")}
          {showDetailsHandler(details?.address, "address")}
        </div>
        <form
          onSubmit={handleSubmit((data) =>
            onSubmit(data as { comment: string })
          )}
        >
          <div className="form-floating mb-3 mt-3">
            <CommonTextArea
              label={translation("comment")}
              placeHolder={translation("leave_a_comment_here")}
              name="comment"
              type="text"
              control={control}
              rows={3}
              error={errors?.comment}
              maxLength={100}
            />
          </div>

          {from === PROFILE_PAGE && (
            <div className="action-btn">
              <button
                type="submit"
                className="main-button primary-btn"
                disabled={loading}
              >
                {translation("claim_property")}
                {loading && (
                  <span className="spinner-border spinner-border-sm me-2 ms-2" />
                )}
              </button>
            </div>
          )}
        </form>
      </>
    );
  }
  return <p>{translation("unable_to_load_data")}</p>;
};

export default ExistPropertyDetails;
